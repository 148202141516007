

export class FedExRateServiceResponse {  
    Status:string;
    Message:string;
    Currency:string;
    ServiceType:string;
    ShippingPrice:number;
    DeliveryTime:Date;
    isButtonEnable:boolean;

}


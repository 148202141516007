import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { CheckoutModel } from './checkout.model';
import { BillingAddress } from '../Payment/billingaddress.mode';
import { ShippingAddress } from '../Payment/shippingaddress.model';


@Injectable({
  providedIn: 'root'
})

export class CheckoutService {
  formData: CheckoutModel;


  readonly rootURL;

  constructor(private http: HttpClient, private _constant: ConstantsService) {
    this.rootURL = _constant.ConstatntWebAPI;
    this.formData = new CheckoutModel();

    this.formData.billing = new BillingAddress();
    this.formData.shipping = new ShippingAddress();

  }

  postData() {
    return this.http.post(this.rootURL + '/Checkout', this.formData)
  }

  getData(partnerUserID) {
    return this.http.get(this.rootURL + '/Checkout/' + partnerUserID).toPromise()
      .then(res => this.formData = res as CheckoutModel)
  }

  // getCountry()
  // {
  //   return this.http.get(this.rootURL+'/Checkout').toPromise()
  //   .then(res => this.Countrylist = res as Country[])

  // }
  // PostPartnerShipping(FormData:PartnerShipping) {     
  //   //console.log(FormData.Name)
  //   return this.http.post(this.rootURL+'/Checkout',FormData).toPromise();         
  //  }

  //  PostPartnerBilling(FormData:PartnerBilling) {     
  //   //console.log(FormData.Name)
  //   return this.http.post(this.rootURL+'/Checkout/BillingAddress',FormData).toPromise();         
  //  }

}


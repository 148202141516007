import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QrClaimService } from '../services/Claim/qr-claim.service';
import { PartnerUserService } from '../services/PartnerUser/partner-user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from '../common/constants.service';

@Component({
  selector: 'app-qrclaimnew',
  templateUrl: './qrclaimnew.component.html',
  styleUrls: ['./qrclaimnew.component.css']
})
export class QrclaimnewComponent implements OnInit {
  public QrCode: string;
  public LoginUser:string;

  constructor(protected _route: ActivatedRoute,private pservice: PartnerUserService,private service: QrClaimService, protected _router: Router,
    private toastr: ToastrService,
    private _constant: ConstantsService) {
    // if ((sessionStorage.getItem('PartnerUserId') == null)) {
    //   this._router.navigateByUrl('/welcome');
    // }
  }

  /****************** dynamically add class ******************/


  ngOnInit() {
     ///Session expired///
     this._constant.setTimeout();
    //  this._constant.userInactive.subscribe(() => {
    //    this._router.navigateByUrl('/welcome');
    //  }); 
     /// End of Session expired///
    this._constant.loading(true);
    // if ((sessionStorage.getItem('PartnerUserId') == null)) {
    //   this._router.navigateByUrl('/welcome');
    //   this._constant.loading(false);      
    // }
//     this.LoginUser = sessionStorage.getItem('FirstName')

// if (this.LoginUser) {
//   console.log("Session_User",this.LoginUser)     
//         }
//         else
//         {
//           this._router.navigateByUrl('/welcome');
//         }
//     this._constant.loading(false);
    const body = document.getElementsByClassName('super-container')[0];
    body.classList.add('app-qrclaim-page');
    console.log(this._route.snapshot.queryParams);
    this._route.paramMap.subscribe(async params => {
    this.QrCode = params.get('qrcode');
    this.QrCode.replace(/\s/g, "");
    if (this.QrCode.length=== 8||this.QrCode.length=== 9){
      console.log(this.QrCode);
    }
    else{
      this.toastr.error('Please check QR code length.');
      this.resetForm();
    }
    console.log(this.QrCode)
    })
    this._constant.loading(false);
  }



  ngOnDestroy() {

    const body = document.getElementsByClassName('super-container')[0];
    body.classList.remove('app-qrclaim-page');
  }

  resetForm() {
    this.QrCode = '';
  }


  onClaimCode() {
    this._constant.loading(true);
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      sessionStorage.clear();
      localStorage.clear();
      this.service.ValidateQr(this.QrCode).then(
        (res) => {
         
          // console.log("res",res);
          sessionStorage.setItem('QrId', this.QrCode);
          if (res == "InvalidQrCode") {
            this.toastr.error('Invalid QR code.');
          }
          else if (res == "QrCodeExpiryDaysNotFound") {
            this.toastr.error('QR code Expiry date processing failed.');
          }
          else if (res == "QrCodeExpiryDaysError") {
            this.toastr.error('QR code Expiry days processing failed.');
          }
          else if (res == "QrCodeExpired") {
            this.toastr.error('QR code Expired.');
          }
          else if (res == "QrCodeExpiredError") {
            this.toastr.error('QR code Expiry processing error.');
          }
          else if (res == "Exception") {
            this.toastr.error('Processing error.Please check connectivity/Qr Code');
          }
          else if (res == "Valid") {
            this.toastr.success('Valid QR!');
            this._router.navigateByUrl('/welcome', { state: { QrCode: this.QrCode} });
          }
          this._constant.loading(false);
          
        },
        (err: HttpErrorResponse) => {
          this._constant.loading(false);
        })

    }
    else{
      this.LoginUser = sessionStorage.getItem('FirstName')

      if (this.LoginUser) {
        console.log("Session_User",this.LoginUser)
        this.service.getClaimCodePhotos(this.QrCode, localStorage.getItem('PartnerUserId')).subscribe(
          (res) => {
            console.log("res",res)
            this.service.CheckUserImages(localStorage.getItem('PartnerUserId'));        
            this._constant.loading(false);
            
          },
          (err: HttpErrorResponse) => {
            if (err.status == 1) {        
              this.toastr.error('Invalid QR code');        
            }
            else if (err.status == 2) {
              this.toastr.warning('QR Code is expired');          
            }
            else if (err.status == 3) {
              this.toastr.warning('QR Code Claim Expiry Days not found');          
            }
            else
            {          
              this.toastr.error('Invalid QR code');          
            }
            console.log(err);
            this._constant.loading(false);
          })   
              }
          else
              {
                // sessionStorage.clear();
                // localStorage.clear();
                // this.pservice.ValidateQr(this.QrCode).subscribe(
                //   (res) => {
                   
                //     this._router.navigate(['welcome/' + this.QrCode]);
                    
                //   },
                //   (err: HttpErrorResponse) => {
                //     if (err.status == 1) {        
                //       this.toastr.error('Invalid QR code');        
                //     }
                //     else if (err.status == 2) {
                //       this.toastr.warning('QR Code is expired');          
                //     }
                //     else if (err.status == 3) {
                //       this.toastr.warning('QR Code Claim Expiry Days not found');          
                //     }
                //     else
                //     {          
                //       this.toastr.error('Invalid QR code');          
                //     }
                //     console.log(err);
                //     this._constant.loading(false);
                //   })
              }

    }

  }




}

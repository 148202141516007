import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  


}

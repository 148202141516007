import { Component, OnInit } from '@angular/core';
import { PartnerUserService } from '../services/PartnerUser/partner-user.service';
import { ConstantsService } from '../common/constants.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  public UserEmailID: string;

  constructor(private service:PartnerUserService, 
    private _constant: ConstantsService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }
  onSubmit()
  {
    this._constant.loading(true);
    this.service.SetPassword(this.UserEmailID).then(
      (res) => {
        this._constant.loading(false);
        this.toastr.success('Temporary Password has been send to your email ID');  
        
      },
      (err: HttpErrorResponse) => {            
        console.log(err);
        this._constant.loading(false);
        this.toastr.error('You are not registered with us!');                  
      }
    )    
  }

}

import { Component, NgZone, OnDestroy, ViewChild, ViewEncapsulation, OnInit, TemplateRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
// import { NgScrollbar } from 'ngx-scrollbar';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { NonClaimPhotosService } from '../services/NonClaimPhotos/NonClaim-photos.service';
import { PartnerProductModel } from '../services/ClaimPhotos/partner-products.model';
import { PartnerWebPhotos } from '../services/ClaimPhotos/partner-webphotos.model';
import { CartService } from '../services/checkout/cart.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from '../common/constants.service';
import 'fabric';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare const fabric: any;

@Component({
  selector: 'app-nonclaimed-photos',
  templateUrl: './nonclaimed-photos.component.html',
  styleUrls: ['./nonclaimed-photos.component.css']
})
export class NonclaimedPhotosComponent implements OnInit, OnDestroy {  
  listProducts : Promise<PartnerProductModel[]>;
  listNonclaimPhotos: Promise<PartnerWebPhotos[]>;
  @ViewChild('VideoTemplate', { static: true }) templateRefVideo: TemplateRef<any>;
  public ImageBigThumbPath: string;
  public WebPhotoID: number;
  public CreatedDate: Date;
  public ExpiryDate: Date;

  public ProductID: number;
  public Quantity: number;
  public ProductName: string;
  public Description: string;
  public Material: string;
  public UnitPrice: number;
  public ImageView1: string;
  public ImageView2: string;
  public ImageView3: string;
  public isPackage: boolean;
  public isDigital: boolean;

  private canvas: any;  
  public Claim: string;
  public NonClaim: string;
  public videoURL: string;
  productVisible: boolean=false;

  @ViewChild('photoLoadModal', { static: true }) templatePhotoLoad: TemplateRef<any>;
  public scrollbarOptions = { };

  ngOnInit() {
     ///Session expired///
     this._constant.setTimeout();
     this._constant.userInactive.subscribe(() => {
       this._router.navigateByUrl('/welcome');
     }); 

     
    this.canvas = new fabric.Canvas('myCanvas',
    {
      hoverCursor: 'pointer',
      selection: true,
      height: 350,
      width: 530,

    });
      // apply white back color to canvas
      var ctx = this.canvas.getContext("2d");
 
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

     /// End of Session expired///
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this.router.navigateByUrl('/welcome');
    }
    else {      
      if ((sessionStorage.getItem('TabStatus') == "ClaimActiveClaim")) {
        this.NonClaim = "nav-link disabled";
        this.Claim = "nav-link d-inline-block";
      }
      else if ((sessionStorage.getItem('TabStatus') == "NonClaimActiveNonClaim")) {
        this.Claim = "nav-link d-inline-block disabled";
        this.NonClaim = "nav-link";
      }
      else {
        this.NonClaim = "nav-link";
        this.Claim = "nav-link d-inline-block";
      }

      //sessionStorage.setItem("TabStatus", "Visited");
      this.getNonClaimPhotos();
      this.getProducts();
      this.Quantity = 1;
      this.ImageBigThumbPath = "";
      this.WebPhotoID = 0;
      this.ExpiryDate = null;

            //
            this.selectedImages = 0;
    
    
            this.selectedWebPhotos=""; 
            this.selectedQnty=0;
            this.isPrevDisable=true;
            this.isNextDisable=false;
            this.selectedSlide=0;
             this.productCount=0;
             this.cartText="Add To Cart";
             this.cartTextPopup="Product successfully added!";
             this.selectedProductId=0; 
    }
  }

  
  
  public packageName: string;
  public packageDesc: string;
  public productImage: string;
  public cartText: string;
  public cartTextPopup: string;
  public selectedProductId: number;  
  public selectedMaxImageCount: number;

  public selectedImages: number;
  public selectedCartItemIdId: number;
  public selectedWebPhotos: string;

  public productCount:number;
   
  public selectedSlide:number;
  //new
  public selectedQnty : number;
  public isNextDisable: boolean;
  public isPrevDisable: boolean;
  public productList: PartnerProductModel[];

  imageSelectEvent(image: PartnerWebPhotos) {

        
    let element = document.getElementById('wb_' + image.WebPhotoId);
    
    if(this.selectedImages==this.selectedMaxImageCount && element.className != 'block selected' )
    {
      if(this.selectedMaxImageCount==0)
      {
        this.toastr.warning('Online all digital product does not require image selection');      
      return;
      }
      this.toastr.warning('No. of maximum images are already selected. If you want another one, please deselect selected image(s) and proceed.');      
      return;
    }

    if (element.className == 'block selected') {
      
      this.selectedWebPhotos=this.selectedWebPhotos.replace(image.WebPhotoId+',','')
      
      this.selectedImages = this.selectedImages - 1;
      element.className = 'block not-selected';     
      
    }else
    {      
      this.selectedWebPhotos=this.selectedWebPhotos+image.WebPhotoId+',';      
   
      this.selectedImages = this.selectedImages + 1;
      element.className = 'block selected';           
    }

    
    if (this.selectedMaxImageCount == this.selectedImages) {
      if (document.getElementById('applyButton')) {
        document.getElementById('applyButton').removeAttribute('disabled');
      }        

      if(!this.isPackage)
        {
          if (document.getElementById('addCartButton')) {
            document.getElementById('addCartButton').removeAttribute('disabled');
          }    
        }

        if(this.cartText=="Update Cart")
        {
          if (document.getElementById('addCartButton')) {
            document.getElementById('addCartButton').removeAttribute('disabled');
          }    
        }

            
    }
    else {
      if (document.getElementById('applyButton')) {
        document.getElementById('applyButton').setAttribute('disabled', 'true');
      }
      if (document.getElementById('addCartButton')) {
        document.getElementById('addCartButton').setAttribute('disabled', 'true');
      }

      
    }
       
  }
 
  viewCart()
  {
    this._router.navigateByUrl('/cart'); 
  }

  loaderHide()
  {
    this._constant.loading(false);
  }
  
  addTocartFromScreen() {
    this._constant.loading(true);
    this._cartService.postAddCart(sessionStorage.getItem('PartnerUserId'), this.ProductID, this.Quantity).then
      (
        (res) => {
          
          // sessionStorage.setItem("ProductID",this.ProductID.toString());
          // sessionStorage.setItem("CartItemID",res.toString());
          // sessionStorage.setItem("PhotoPage","claim");
          this.selectedCartItemIdId=res as number;
          //this.toastr.success('Product added to cart');
          this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
          //this._router.navigateByUrl('/cart'); 
          this.getAllProductsCart(); 
          //this._constant.loading(false);
        },
        (err: HttpErrorResponse) => {
          if (err.status == 1) {
            this.toastr.warning('Quanity more than 10 not allowed, for single product');
          }
          else if (err.status == 2) {
            this.toastr.warning('Purchase of more than one is not allowed for this product');
          }
          else {
            this.toastr.error('Error! Adding Product');
          }
          this._constant.loading(false);
          console.log(err);

        }
      );
  }

  getAllProductsCart() {
    this._constant.loading(true);
    
    this.cartText="Add To Cart"; 
    this.cartTextPopup="Product successfully added!";
    this._cartService.getPartnerCartProducts(
    sessionStorage.getItem('PartnerUserId'),this.ProductID).then
    (
      (res) => {      
        
        this.productList=res as PartnerProductModel[];
        this.productList.forEach(obj => {
          this.productCount=this.productCount+1;
        });        
        this.selectedProductId=this.productList[0].ProductId;  
        //this.selectedCartItemIdId=+sessionStorage.getItem('CartItemID');
        this.selectedMaxImageCount=this.productList[0].MaxImageCount; 
        this.isDigital= this.productList[0].RemoveWatermark; 

        if(this.productCount>1){
          this.isPackage=true;  
        }
        else
        {
          this.isPackage=false; 
        }
        this.packageName=this.productList[0].PackageName;  
        this.packageDesc=this.productList[0].PackageDescription;  
        this.productImage=this.productList[0].ImageView1;  

        //sessionStorage.setItem("ProductID",null);

        this.getAllPhotosCart();

       
      },
      (err) => {
        this._constant.loading(false);
      }
    );

   
  }

  expPhotos()
  {
    this._constant.loading(false);            
    // if((sessionStorage.getItem('PhotoPage') == 'claim')) {
    //   this._router.navigateByUrl('/claimed-photos');               
    // }
    // else if((sessionStorage.getItem('PhotoPage') == 'non-claim')) {
    //   this._router.navigateByUrl('/nonclaimed-photos');               
    // }
    // else {
    //   this._constant.CheckUserImages(sessionStorage.getItem('PartnerUserId'));
    // }
    
  }
  
  getAllPhotosCart() {
        
    
    this._constant.loading(true);

    this._cartService.getPartnerPhotosforSelection(
    sessionStorage.getItem('PartnerUserId'),this.selectedCartItemIdId,this.selectedProductId).then
    (
      (res) => {    
        
        this._cartService.list=res;       
        this.selectedWebPhotos=this._cartService.list.selectedWebPhotos;
        this.selectedImages=0;
        if(this.selectedWebPhotos)
        {
          var array = this.selectedWebPhotos.split(",");
          this.selectedImages=array.length-1;
        }
        if(!this.isPackage)
        {
          this._cartService.list.isCartDisable=!this._cartService.list.isSaveSelection;
        }

        // if (this._cartService.list.isCartDisable) {
        //   if (document.getElementById('addCartButton')) {
        //     document.getElementById('addCartButton').setAttribute('disabled', 'true');
        //   }
        // }
        // else {
        //   if (document.getElementById('addCartButton')) {
        //     document.getElementById('addCartButton').removeAttribute('disabled');
        //   }
        // }

        if (!this._cartService.list.isSaveSelection) {
          if (document.getElementById('applyButton')) {
            document.getElementById('applyButton').setAttribute('disabled', 'true');
          }
        }
        else {
          if (document.getElementById('applyButton')) {
            document.getElementById('applyButton').removeAttribute('disabled');
          }
        }
        
        
        sessionStorage.setItem("CartItemID",null);
        this.isCollapsedAddPhotos=false;

        this._constant.loading(false);
      },
      (err) => {
        this._constant.loading(false);
      }
    );

   
  }

  clearSelection() {
    if (this.selectedWebPhotos) {
      if (this.selectedWebPhotos != "null") {
        var array = this.selectedWebPhotos.split(",");
          
        array.forEach(function (value) {
          let element = document.getElementById('wb_' + value)
          if (element != null) {
            element.className = 'block not-selected';
          }
        });
      }
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    if (document.getElementById('addCartButton')) {
      document.getElementById('addCartButton').setAttribute('disabled', 'true');
    }

    this.selectedImages = 0;
    this.selectedWebPhotos = "";
    
  }

  
  addToCart() {
    this._constant.loading(true);
    if (this.isPackage) {
      this._cartService.putPackage(sessionStorage.getItem('PartnerUserId'))
        .subscribe(
          (res) => {
            
            this._constant.loading(false);
          },
          (err: HttpErrorResponse) => {

            console.log(err);

          }
        );
    }
    else {
      this._cartService.putWebPhotos(this.selectedCartItemIdId, this.selectedWebPhotos,
        this.selectedProductId, sessionStorage.getItem('PartnerUserId'))
        .subscribe(
          (res) => {
            console.log("done");
          },
          (err: HttpErrorResponse) => {

            console.log(err);

          }
        );
    }
  }
  
  prevSlide() {
    
    this._constant.loading(true);
    if (this.selectedImages != this.selectedMaxImageCount) {
      this.toastr.warning('select ' + (this.selectedMaxImageCount - this.selectedImages) + ' Photos');
      this._constant.loading(false);
      return;
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    this._cartService.putWebPhotos(this.selectedCartItemIdId,this.selectedWebPhotos,
      this.selectedProductId,sessionStorage.getItem('PartnerUserId'))
    .subscribe(
      (res) => {          
        
          if (this.selectedImages == this.selectedMaxImageCount) {
            document.getElementById("dotID_" + this.selectedSlide).classList.remove("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.remove("active");

            if (this.selectedSlide == 0) {
              this.selectedSlide = this.productCount - 1;
              this.selectedCartItemIdId=this.selectedCartItemIdId+ (this.productCount - 1);
            }
            else {
              this.selectedSlide = this.selectedSlide - 1;
              this.selectedCartItemIdId=this.selectedCartItemIdId-1;
            }

            document.getElementById("dotID_" + this.selectedSlide).classList.add("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.add("active");
            
            this.selectedProductId=this.productList[this.selectedSlide].ProductId;  
            this.selectedMaxImageCount=this.productList[this.selectedSlide].MaxImageCount;        
            this.productImage=this.productList[this.selectedSlide].ImageView1;  
            

            this.getAllPhotosCart();
            
          }
         
           
      },
      (err: HttpErrorResponse) => {
    
        console.log(err);
        
      }
    );     
    

  }

  nextSlide()
  {
    
    this._constant.loading(true);
    if (this.selectedImages != this.selectedMaxImageCount) {
      this.toastr.warning('select ' + (this.selectedMaxImageCount - this.selectedImages) + ' Photos');
      this._constant.loading(false);
      return;
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    this._cartService.putWebPhotos(this.selectedCartItemIdId,this.selectedWebPhotos,
      this.selectedProductId,sessionStorage.getItem('PartnerUserId'))
    .subscribe(
      (res) => {          
        
          if (this.selectedImages == this.selectedMaxImageCount) {
            document.getElementById("dotID_" + this.selectedSlide).classList.remove("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.remove("active");
            if (this.selectedSlide == (this.productCount - 1)) {
              this.selectedSlide = 0;
              this.selectedCartItemIdId=this.selectedCartItemIdId- (this.productCount - 1);
            }
            else {
              this.selectedSlide = this.selectedSlide + 1;
              this.selectedCartItemIdId=this.selectedCartItemIdId+1;
            }
            document.getElementById("dotID_" + this.selectedSlide).classList.add("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.add("active");
            
            this.selectedProductId=this.productList[this.selectedSlide].ProductId;  
            this.selectedMaxImageCount=this.productList[this.selectedSlide].MaxImageCount;        
            this.productImage=this.productList[this.selectedSlide].ImageView1;  
            

            this.getAllPhotosCart();
            
          }
         
           
      },
      (err: HttpErrorResponse) => {
    
        console.log(err);
        
      }
    );     
    
  }

/********* ngx collapse *********/
isCollapsedAddPhotos = true;


  /********* ngx scrollbar *********/

  // Unsubscriber for elementScrolled stream.
  private _scrollSubscription = Subscription.EMPTY;

  // Get NgScrollbar reference
  // @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  constructor(public service: NonClaimPhotosService,private ngZone: NgZone,protected router: Router,
    public _cartService: CartService,
    private _router: Router,
    private toastr: ToastrService,
    private _constant: ConstantsService,
    private modalService: BsModalService,
    ) {
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this.router.navigateByUrl('/welcome');   
    }
  }

  getProducts()
  {
    this.service.getPartnerProducts(sessionStorage.getItem('PartnerUserId'))
    .then(res => {
      this.service.listProducts = res as PartnerProductModel[];
      if (this.service.listProducts.length > 0 ) {   
        
        this.ProductID=this.service.listProducts[0].ProductId;
        //this.MaxImageCount=this.service.listProducts[0].MaxImageCount;
        this.ProductName = this.service.listProducts[0].ProductName;
        this.Description = this.service.listProducts[0].Description;
        this.Material = this.service.listProducts[0].Material;
        this.UnitPrice = this.service.listProducts[0].UnitPrice;
        this.ImageView1 = this.service.listProducts[0].ImageView1;
        this.ImageView2 = this.service.listProducts[0].ImageView2;
        this.ImageView3 = this.service.listProducts[0].ImageView3;
        this.isPackage=this.service.listProducts[0].isPackage;
        this.isDigital=this.service.listProducts[0].RemoveWatermark;
      };
      if (!this.ProductID || this.ProductID<=0) {
        this.productVisible=false;
      }
      else
      {
        this.productVisible=true;
      }
    });

  }
  getNonClaimPhotos()
  {
    this._constant.loading(true);
    this.service.getPartnerPhotos(sessionStorage.getItem('PartnerUserId'))
    .then(res => {
      this.service.listNonclaimPhotos = res as PartnerWebPhotos[];

      // this.service.listNonclaimPhotos.forEach(obj => {        
      //   if (obj.Height <= 0) {
      //     this.openModal(this.templatePhotoLoad);
      //   }
      // });    

      if (this.service.listNonclaimPhotos.length > 0 ) {           
        
        this.ImageBigThumbPath = this.service.listNonclaimPhotos[0].NonClaimBigThumbnailFilePath;
        this.WebPhotoID = this.service.listNonclaimPhotos[0].WebPhotoId;
        this.CreatedDate = this.service.listNonclaimPhotos[0].CreatedDate;
        this.ExpiryDate = this.service.listNonclaimPhotos[0].ExpiryDate;
        this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
      };
      this.setCanvasImage();
      
      this._constant.loading(false);
    });       
  }

  populateProduct(pw: PartnerProductModel) {

    this.service.listProducts.forEach(obj => {
      let element = document.getElementById(obj.ProductName);
      element.classList.remove('selected');
    });       

    let element = document.getElementById(pw.ProductName);
    
    element.classList.add('selected');
    
    this.ProductID = pw.ProductId;

    this.ProductName = pw.ProductName;
    this.Description = pw.Description;
    this.Material = pw.Material;
    this.UnitPrice = pw.UnitPrice;
    this.isPackage=pw.isPackage;
    this.isDigital=pw.RemoveWatermark;
    this.ImageView1 = pw.ImageView1;
    this.ImageView2 = pw.ImageView2;
    this.ImageView3 = pw.ImageView3;
  }
  arrayOne(n: number): any[] {
    return Array(n);
  }

  setCanvasImage() {
    
    if (this.ImageBigThumbPath) {
      
      let self = this;
      this.canvas.clear();
      let h = this.canvas.height;
      let w = this.canvas.width;
      var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_fill/';
      newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
        newPath);
      
      if (this.ImageBigThumbPath.includes("www-commdel-net/video")) {
        newPath = 'https://res.cloudinary.com/www-commdel-net/video/upload/w_' + w + ',h_' + h + ',c_scale/';
        newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/video/upload/',
          newPath);
      }
      newPath=newPath.replace('w_1200,h_1200','w_' + w + ',h_' + h);
      // var newPath='https://res.cloudinary.com/www-commdel-net/image/upload/w_'+w+',h_'+h+',c_scale/';
      // newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
      //   newPath);
      this.canvas.setBackgroundColor({ source: newPath, repeat: 'no-repeat', crossOrigin: "Anonymous" }, function () {
        self.canvas.renderAll();
      });

    }
  }

  onCanvasClick() {
   
    if (this.ImageBigThumbPath.includes("www-commdel-net/video")) {      
      this.openModalWithClass2(this.templateRefVideo);
    }
  }
  populateForm(pw: PartnerWebPhotos) {
    
    this._constant.loading(true);
    this.ImageBigThumbPath = pw.NonClaimBigThumbnailFilePath;
    this.WebPhotoID = pw.WebPhotoId;
    this.CreatedDate = pw.CreatedDate;
    this.ExpiryDate = pw.ExpiryDate;
    this.setCanvasImage();
    
    // var vid= 'VideoPlayer' as TemplateRef<any>; 
    if (this.ImageBigThumbPath.includes("www-commdel-net/video")) {      
      this.videoURL = pw.NonClaimBigThumbnailFilePath.replace('.jpg', '.mp4');
      this.videoURL = this.videoURL.replace('l_media_player/', '');
      this.openModalWithClass2(this.templateRefVideo);
    }
    this._constant.loading(false);
  }

  modalRef: BsModalRef;

  openModalWithClass2(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'videomodal' })
    );
  }

  openAddPhotos()
  {             
       
    this.addTocartFromScreen();       
  }

  // addTocartFromScreen() {
  //   this._constant.loading(true);
  //   this._cartService.postAddCart(sessionStorage.getItem('PartnerUserId'), this.ProductID, this.Quantity).then
  //     (
  //       (res) => {
  //         sessionStorage.setItem("ProductID",this.ProductID.toString());
  //         sessionStorage.setItem("CartItemID",res.toString());
  //         sessionStorage.setItem("PhotoPage","non-claim");
  //         //this.toastr.success('Product added to cart');
  //         this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
  //         this._router.navigateByUrl('/cart'); 
  //         this._constant.loading(false);
  //       },
  //       (err: HttpErrorResponse) => {

  //         if (err.status == 1) {
  //           this.toastr.warning('Quanity more than 10 not allowed, for single product');
  //         }
  //         else if (err.status == 2) {
  //           this.toastr.warning('Purchase of more than one is not allowed for this product');
  //         }
  //         else
  //         {
  //           this.toastr.error('Error! Adding Product');
  //         }
  //         console.log(err);
  //         this._constant.loading(false);
  //       }
  //     );
  // }
  addTocartFromList(pw: PartnerProductModel) {
    this._constant.loading(true);
    this._cartService.postAddCart(sessionStorage.getItem('PartnerUserId'), pw.ProductId, 1).then
      (
        (res) => {
          this.toastr.success('Product added to cart');
          this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
          this._constant.loading(false);
        },
        (err: HttpErrorResponse) => {

          if (err.status == 1) {
            this.toastr.warning('Quanity more than 10 not allowed, for single product');
          }
          else
          {
            this.toastr.error('Error! Adding Product');
          }
          console.log(err);
          this._constant.loading(false);
        }
      );

  }

  
  ngOnDestroy() {
    this._scrollSubscription.unsubscribe();
    if (this.modalRef) {
      this.modalRef.hide();
    }    
  }


  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-dialog-centered'});
  }
  
  ClosePhoto(): void {  
    window.close();
    this.modalRef.hide();
  }


  /********* owl carousel *********/
  customOptions: OwlOptions = {
    center: true,
    items: 2,
    loop: false,
    margin: 20,
    responsive: {
      600: {
        items: 4
      }
    }
  }

  productOptions: OwlOptions = {
    loop: true,
    items: 4,
    margin: 10,
    responsive: {
      250: {
        items: 2
      },
      450: {
        items: 3
      },
      500: {
        items: 4
      }
    },
  }

/********* window scoll top *********/
@HostListener('window:scroll')
checkScroll() {
  // window의 scroll top
  // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  console.log('[scroll]', scrollPosition);    
  // if (scrollPosition >= this.topPosToStartShowing) {
  //   this.isShow = true;
  // } else {
  //   this.isShow = false;
  // }
}
// TODO: Cross browsing
gotoTop() {
  window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
  });
}


}

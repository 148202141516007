import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule, CollapseModule, AccordionModule, ModalModule, PopoverModule } from 'ngx-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { CarouselmoduleModule } from './carouselmodule/carouselmodule.module';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar-ex';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FooterComponent } from './footer/footer.component';
import { QRclaimComponent } from './qrclaim/qrclaim.component';
import { ClaimedPhotosComponent } from './claimed-photos/claimed-photos.component';
import { NonclaimedPhotosComponent } from './nonclaimed-photos/nonclaimed-photos.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { AccountComponent } from './account/account.component';
import { EditComponent } from './account/edit/edit.component';
import { OrderhistoryComponent } from './account/orderhistory/orderhistory.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { HelpComponent } from './help/help.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ErrorComponent } from './error/error.component';

import { HttpClientModule} from '@angular/common/http'
import { PartnerUserService } from './services/PartnerUser/partner-user.service';
import { ConstantsService } from './common/constants.service';
import { PaymentService } from './services/Payment/Payment.service';

import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';
import { QrclaimnewComponent } from './qrclaimnew/qrclaimnew.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WelcomeComponent,
    FooterComponent,
    QRclaimComponent,
    ClaimedPhotosComponent,
    NonclaimedPhotosComponent,
    CartComponent,
    CheckoutComponent,
    PaymentComponent,
    AccountComponent,
    EditComponent,
    OrderhistoryComponent,
    ReceiptsComponent,
    CustomerSupportComponent,
    HelpComponent,
    PrivacyPolicyComponent,
    ForgotpasswordComponent,
    TermsConditionsComponent,
    AboutUsComponent,
    ContactusComponent,
    ErrorComponent,
    GalleryComponent,
    QrclaimnewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    CarouselModule,
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    PopoverModule.forRoot(),
    CarouselmoduleModule,
    MalihuScrollbarModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-center-center'      
    }),
  ],
  providers: [{provide: LocationStrategy, useClass:HashLocationStrategy} ,PartnerUserService,ConstantsService,PaymentService],
  bootstrap: [AppComponent]
})
export class AppModule { }

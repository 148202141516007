import { HttpClient} from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
  })

export class QrClaimService {
    readonly rootURL;

    constructor(private http:HttpClient, private _constant: ConstantsService,protected _router: Router) {
        this.rootURL=_constant.ConstatntWebAPI;
       }

       ValidateQr(qrCode) {
        return this.http.get(this.rootURL+'/Claim/NewQrCode/'+qrCode).toPromise();
      //   this.http.get(this.rootURL+'/Claim')
      // .toPromise();
  
       }
       Chkqrlinkuser(qrCode,partnerUserID) {
        return this.http.get(this.rootURL+'/Claim/NewQrCode/'+qrCode+'/'+partnerUserID);
      //   this.http.get(this.rootURL+'/Claim')
      // .toPromise();
  
       }
       getClaimCodePhotos(qrCode,partnerUserID) {
        return this.http.get(this.rootURL+'/Claim/'+qrCode+'/'+partnerUserID);
      //   this.http.get(this.rootURL+'/Claim')
      // .toPromise();

       }

       CheckUserImages(partnerUserID) {
        this._constant.loading(true);
        return this.http.get(this.rootURL+'/Claim/'+partnerUserID).subscribe(
          (res) => {

            if (res == "ClaimActiveBoth") {
              sessionStorage.setItem("TabStatus", "ClaimActiveBoth")
              this._router.navigateByUrl('/gallery');
            }
            else if (res == "ClaimActiveClaim") {
              sessionStorage.setItem("TabStatus", "ClaimActiveClaim")
              this._router.navigateByUrl('/gallery');
            }
            else if (res == "NonClaimActiveNonClaim") {
              sessionStorage.setItem("TabStatus", "NonClaimActiveNonClaim")
              this._router.navigateByUrl('/nonclaimed-photos');
            }
            else {
              this._router.navigateByUrl('/qrclaim');
            }
            this._constant.loading(false);
          });

       }

}

import { PartnerProduct } from './PartnerProduct';
import { ShippingAddress } from './shippingaddress.model';
import { BillingAddress } from './billingaddress.mode';
import { PaymentDetails } from './PaymentDataModel';

export class PaymentModel{
  
    cartPhotos: PartnerProduct[]=[];    
    shipping :ShippingAddress;
    billing: BillingAddress;
    PaymentDetails: PaymentDetails;

    totalPrice:number;
    totalDiscount:number;
    totalShipping :number;
    grandTotal:number;
  onlyBiling: boolean;
  }

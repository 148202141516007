import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CartService } from '../services/checkout/cart.service';
import { promise } from 'protractor';
import { Promise } from 'q';
import { Router, NavigationStart } from '@angular/router';
import { QrClaimService } from '../services/Claim/qr-claim.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  public FirstName: string;
  showUL: boolean = true;

  constructor(public _cartService: CartService,
    protected router: Router,
    private service: QrClaimService) {


    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if ((event['url'] == '/privacy-policy') || (event['url'] == '/help')
          || (event['url'] == '/terms-conditions') || (event['url'] == '/contactus')
          || (event['url'] == '/about-us') || (event['url'] == '/forgotpassword')
          || (event['url'] == '/customer-support')
        ) {
          if ((sessionStorage.getItem('PartnerUserId') != null)) {
            this.showUL = true;
          }
          else
          {
            this.showUL = false;
          }
         
        }
        else {

          this.showUL = true;
        }

      }
    });


    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this.showUL = false;
    }
    //this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));

  }

  iconClick() {
    
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this.router.navigateByUrl('/welcome');
    }
    else {      
      this.service.CheckUserImages(sessionStorage.getItem('PartnerUserId'));
    }

  }
  ngOnInit() {
    
    if ((sessionStorage.getItem('PartnerUserId') != null)) {
      this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
      this._cartService.$isCartCount.subscribe((data) => this._cartService.cartCount = data);
      this.FirstName = sessionStorage.getItem("FirstName");
      this.showUL = true;
    }

  
    // if ((sessionStorage.getItem('PartnerUserId') != null)) {

    // }
    // else {
    //   this._cartService.$isCartCount.subscribe((data) => this._cartService.cartCount = data);
    //   this.FirstName = sessionStorage.getItem("FirstName");
    // }

  }

  openCart()
  {
    sessionStorage.setItem("ProductID",null);  

    if(!window.location.href.includes("/cart"))
    {      
      this.router.navigateByUrl('/cart');
    }
    else
    {
      window.location.reload();
    }
  }


}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { PaymentModel } from './Payment.model';
import { BillingAddress } from './billingaddress.mode';
import { ShippingAddress } from './shippingaddress.model';
import { FedExRateServiceResponse } from './fedex.model';
import { PaymentDetails } from './PaymentDataModel';

@Injectable({
  providedIn: 'root'
})



export class PaymentService {

  formData: PaymentModel;
  fedexData: FedExRateServiceResponse;
  paymentData: PaymentDetails;
  payButtonData: PaymentDetails;
  readonly rootURL;


  constructor(private http: HttpClient, private _constant: ConstantsService) {
    this.rootURL = _constant.ConstatntWebAPI;
    this.formData=new PaymentModel();
    this.fedexData=new FedExRateServiceResponse();
    this.formData.billing=new BillingAddress();
    this.formData.shipping = new ShippingAddress();
    this.paymentData = new PaymentDetails();
    
  }
  
  getFedexData(PartnerUserID){
    return this.http.get(this.rootURL + '/FedExRate/Fedex/' + PartnerUserID).toPromise()
    .then(res=> this.fedexData =res as FedExRateServiceResponse);

  }
  getPaymentPageDetails(PartnerUserID) {

    return this.http.get(this.rootURL + '/Payment/getProducts/' + PartnerUserID).toPromise()
      .then(res => this.formData = res as PaymentModel);
  }

  LoadPayment(PartnerUserID) {
    return this.http.get(this.rootURL + '/Payment/LoadPayment/' + PartnerUserID).toPromise()
    .then(res => this.paymentData = res as PaymentDetails);
    
    
  }

  PaymentRequest(PartnerUserID) {

    return this.http.post(this.rootURL + '/Payment/PaymentDetails/' + PartnerUserID, this.formData);
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ConstantsService } from '../common/constants.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
   
  constructor(private constant: ConstantsService,
    protected router: Router) { }
 
  ngOnInit() {

  }

  iconClick() {
    
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this.router.navigateByUrl('/welcome');
    }
    else {      
      this.constant.CheckUserImages(sessionStorage.getItem('PartnerUserId'));
    }

  }
  FindPhotos()
  {            
    if(!window.location.href.includes("/customer-support"))
    {
      this.router.navigateByUrl('/customer-support');      
    }
    else
    {      
      window.location.reload();
    }
  }
  Help()
  {
    
    if(!window.location.href.includes("/help"))
    {   
      this.router.navigateByUrl('/help');           
    }
    else
    {
      window.location.reload();   
    }
  }
  PrivacyPolicy()
  {
    if(!window.location.href.includes("/privacy-policy"))
    {     
      this.router.navigateByUrl('/privacy-policy');
    }
    else
    {
      window.location.reload();
    }
  }
  Termsconditions()
  {
    if(!window.location.href.includes("/terms-conditions"))
    {      
      this.router.navigateByUrl('/terms-conditions');
    }
    else
    {
      window.location.reload();
    }
  }
  Contactus()
  {
    if(!window.location.href.includes("/contactus"))
    {      
      this.router.navigateByUrl('/contactus');
    }
    else
    {
      window.location.reload();
    }
  }
  Aboutus()
  {
    if(!window.location.href.includes("/about-us"))
    {      
      this.router.navigateByUrl('/about-us');
    }
    else
    {
      window.location.reload();
    }
  }
 

}

import { Component, NgZone, OnDestroy, ViewChild, ViewEncapsulation, OnInit, Output, TemplateRef, HostListener, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
//import { NgScrollbar } from 'ngx-scrollbar';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ClaimPhotosService } from '../services/ClaimPhotos/claim-photos.service';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { PartnerWebPhotos } from '../services/ClaimPhotos/partner-webphotos.model';
import { PartnerProductModel } from '../services/ClaimPhotos/partner-products.model';
import { CartService } from '../services/checkout/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PartnerBorders } from '../services/ClaimPhotos/partner-border.model';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerSupportService } from '../services/CustomerSupport/CustomerSupport.service';
import { ConstantsService } from '../common/constants.service';
import * as JSZip from 'jszip';
import 'fabric';
import { CloudinaryData } from '../services/ClaimPhotos/cloudinary-data.model';
import { PartnerGraphics } from '../services/ClaimPhotos/partner-graphics.model';

import { EmailShare } from '../services/ClaimPhotos/EmailShare.model';
import { NgForm } from '@angular/forms';
import { debug } from 'util';
import { saveAs } from 'file-saver';

declare const fabric: any;


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  fileNames: object = {};
  borderApplied: string = ''
  duplicate_count: number = 0;
  constructor(
    private modalService: BsModalService,
    private ngZone: NgZone, public service: ClaimPhotosService,
    public _cartService: CartService,
    private _router: Router,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    public fileService: CustomerSupportService,
    private _constant: ConstantsService,
    protected _route: ActivatedRoute
  ) {

    if ((localStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
    }


  }
  ngOnInit() {
    this.getPartnerClaimedPhotos()
  }

  downloadCloud() {
    this._constant.loading(true);

    this.service.download(localStorage.getItem('PartnerUserId')).
      then((res) => {


        var dwnld = res as string;
        var spltdwnold = dwnld.split("###");
        // console.log("got resp");
        // console.log("res=" + dwnld);


        if (spltdwnold[0]) {
          // console.log("res0=" + spltdwnold[0]);


          var newWin = window.open(spltdwnold[0] as string, "_blank");

          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            this.toastr.info('Allow pop-ups to download all images', 'Pop-ups',
              {
                timeOut: 20000
              });
          }
        }

        if (spltdwnold[1]) {
          // console.log("res1=" + spltdwnold[1]);

          var newWin = window.open(spltdwnold[1] as string, "_blank");

          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            this.toastr.info('Allow pop-ups to download all videos', 'Pop-ups',
              {
                timeOut: 20000
              });
          }
        }
        this._constant.loading(false);
      },
        (err: HttpErrorResponse) => {
          this._constant.loading(false);
          console.log(err);
        }
      );
  }

  getFileName(name, filenames = []) {
    let name_arr = name.split(".");
    let extension = name_arr[1];
    //  console.log('file names and name ', name, filenames, filenames.includes(name));
    if (filenames.includes(name)) {
      this.fileNames[name_arr[0]] = this.fileNames[name_arr[0]] + 1;
      let count = this.fileNames[name_arr[0]];
      return name_arr[0] + " (" + count + ")." + extension;
    } else {
      return name;
    }
  }


  async downloadAllImages() {
    if (this.service.list && this.service.list.length > 0) {
      this._constant.loading(true);
      //OriginalFilePath
      let zip = new JSZip();
      let folder = zip.folder('collection');
      let file_names = [];
      for (let i = 0; i < this.service.list.length; i++) {
        // let url = filterData[i]['Original'].replace('api/OrderImages', 'api/fl_attachment/OrderImages/');
        let url = this.service.list[i]['OriginalFilePath'];
        const imageBlob = await fetch(url).then(response => response.blob());
        // filterData[i]['NewImg'] = imageBlob;
        // folder.file(this.service.list[i]['isVideo']|| url.includes(".mov") || url.includes(".mp4") ? `${i + 1}.mp4` : `${i + 1}.jpg`, imageBlob);
        let extension = url.split('/').pop().split('#')[0].split('?')[0];
        // console.log('extension us ', extension);
        let name = this.getFileName(extension, file_names);
        // console.log('name is ', name);
        folder.file(name, imageBlob);
        file_names.push(extension);
      }
      this.duplicate_count = 0;
      folder.generateAsync({ type: "blob" }).then(async content => {
        await saveAs(content, new Date().getTime());
        this._constant.loading(false);
      });
      for (const key in this.fileNames) {
        if (Object.prototype.hasOwnProperty.call(this.fileNames, key)) {
          this.fileNames[key] = 0;
        }
      }

    } else {
      alert("error")

    }
  }


  getPartnerClaimedPhotos() {
    this._constant.loading(true);

    this.service.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
      .then(res => {
        this.service.list = res as PartnerWebPhotos[];
        this._constant.loading(false);
      });
      this._constant.loading(false);
  }
  


}

export class PartnerWebPhotos {    

    WebPhotoId :number;
    PhotoId :number;
    OrderNumber : string; 
    IdentificationCode : string;
    LayeringObjects : string;
    Dimention :string;
    EditedOnMobile : Date; 
    LayeringObjectsEditedOn : Date;
    CloudImageUrl : string;
    Height :number;
    Width : number; 
    ThumbnailDimension : string;
    IsPaidImage : boolean;
    isSelected : boolean;
    CloudSourceImageUrl :string;
    ThumbnailFilePath: string;
    MinifiedFilePath: string;
    isVideo : boolean;
    BigThumbnailFilePath: string;
    CreatedDate:Date;
    ExpiryDate:Date;
    TwitterComment:string;
    TwitterAuthToken:string;
    NonClaimThumbnailFilePath:string;
    NonClaimBigThumbnailFilePath:string;
    OriginalFilePath: string;
    IsCartItem :boolean;
}


import { OrderModel } from './order.model';
import { ShippingAddress } from '../Payment/shippingaddress.model';
import { BillingAddress } from '../Payment/billingaddress.mode';


export class OrderHistoryModel {

    orderItems: OrderModel[]=[];    
    shipping :ShippingAddress;
    billing :BillingAddress;
}



import { PartnerProductModel } from '../ClaimPhotos/partner-products.model';

export class CartProductsModel {

    isSelection: boolean;
    totalProducts: number;
    totalPrice: number;
    cartItems: PartnerProductModel[]=[];
    
}


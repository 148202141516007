import { HttpClient, HttpEventType} from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { DigiMasterLocation } from './DigimasterLocation.Model';
import { CustomerSupport } from './CustomerSupport.model';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })

export class CustomerSupportService {    
    formData:DigiMasterLocation;
    formDataCS:CustomerSupport;
    readonly rootURL;   
    VenueList:DigiMasterLocation[];
    SiteList:DigiMasterLocation[];
    TicketId:string;

    constructor(private http:HttpClient, private _constant: ConstantsService) {
      this.rootURL=_constant.ConstatntWebAPI;
     }

    getVenueList()
    {
      return this.http.get(this.rootURL+'/CustomerSupport').toPromise()
      .then(res => this.VenueList = res as DigiMasterLocation[])
    }
    getSiteList(value)
    {
      return this.http.get(this.rootURL+'/CustomerSupport/Substore/'+value).toPromise()
      .then(res => this.SiteList = res as DigiMasterLocation[])
    }
    PostCustomerSupport(formDataCS:CustomerSupport)
    {
          
      return this.http.post(this.rootURL+'/CustomerSupport',formDataCS).toPromise()
      .then(res => this.formDataCS = res as CustomerSupport)
    }    
    postFile(fileToUpload: File){
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      //formData.append('ComponentId', componentId);
      this.http.post(this.rootURL+'/CustomerSupport/Upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
        console.log("dfgdfgdfg")
          //this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          console.log('Upload success.');
          //this.onUploadFinished.emit(event.body);
        }
      });
  }

  postFile1(fileToUpload){
    const formData = new FormData();
    
    formData.append("image_data", fileToUpload);
    //formData.append('ComponentId', componentId);
    this.http.post(this.rootURL+'/CustomerSupport/Upload', formData, {reportProgress: true, observe: 'events'})
    .subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
      console.log("dfgdfgdfg")
        //this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        console.log('Upload success.');
        //this.onUploadFinished.emit(event.body);
      }
    });
}
}
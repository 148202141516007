export class BillingAddress{

    BillingAddressId: number;
    PartnerUserId: number;
    BillingFirstName: string;
    BillingLastName: string;
    BillingEmail: string;
    BillingCompany: string;
    BillingAddress1: string;
    BillingAddress2: string;
    BillingCity: string;
    BillingState: string;
    BillingZip: string;
    BillingCountry: string;
    BillingPhone: string;
    BillingFax :string
    UseBillingAsShipping:boolean;
    CountryPhoneCode: string;
  }

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QrClaimService } from '../services/Claim/qr-claim.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from '../common/constants.service';

@Component({
  selector: 'app-qrclaim',
  templateUrl: './qrclaim.component.html',
  styleUrls: ['./qrclaim.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QRclaimComponent implements OnInit {
  public QrCode: string;
  public LoginUser:string;

  constructor(private service: QrClaimService, protected _router: Router,
    private toastr: ToastrService,
    private _constant: ConstantsService) {
    // if ((sessionStorage.getItem('PartnerUserId') == null)) {
    //   this._router.navigateByUrl('/welcome');
    // }
  }

  /****************** dynamically add class ******************/


  ngOnInit() {
     ///Session expired///
     this._constant.setTimeout();
    //  this._constant.userInactive.subscribe(() => {
    //    this._router.navigateByUrl('/welcome');
    //  }); 
     /// End of Session expired///
    this._constant.loading(true);
    // if ((sessionStorage.getItem('PartnerUserId') == null)) {
    //   this._router.navigateByUrl('/welcome');
    //   this._constant.loading(false);      
    // }
    this.LoginUser = sessionStorage.getItem('FirstName')

if (this.LoginUser) {
  console.log("Session_User",this.LoginUser)     
        }
        else
        {
          this._router.navigateByUrl('/welcome');
        }
    this._constant.loading(false);
    const body = document.getElementsByClassName('super-container')[0];
    body.classList.add('app-qrclaim-page');
    this.resetForm();
  }



  ngOnDestroy() {

    const body = document.getElementsByClassName('super-container')[0];
    body.classList.remove('app-qrclaim-page');
  }

  resetForm() {
    this.QrCode = '';
  }


  onClaimCode() {
    this._constant.loading(true);
    this.service.ValidateQr(this.QrCode).then(
      (res) => {
       
        // console.log("res",res);
        sessionStorage.setItem('QrId', this.QrCode);
        if (res == "InvalidQrCode") {
          this.toastr.error('Invalid QR code.');
        }
        else if (res == "QrCodeExpiryDaysNotFound") {
          this.toastr.error('QR code Expiry date processing failed.');
        }
        else if (res == "QrCodeExpiryDaysError") {
          this.toastr.error('QR code Expiry days processing failed.');
        }
        else if (res == "QrCodeExpired") {
          this.toastr.error('QR code Expired.');
        }
        else if (res == "QrCodeExpiredError") {
          this.toastr.error('QR code Expiry processing error.');
        }
        else if (res == "Exception") {
          this.toastr.error('Processing error.Please check connectivity/Qr Code');
        }
        else if (res == "Valid") {
          this.toastr.success('Valid QR!');
          this.service.getClaimCodePhotos(this.QrCode, localStorage.getItem('PartnerUserId')).subscribe(
            (res) => {
             
              this.service.CheckUserImages(localStorage.getItem('PartnerUserId'));        
              this._constant.loading(false);
              
            },
            (err: HttpErrorResponse) => {
              // this._constant.loading(false);
            })
        }
        this._constant.loading(false);
        
      },
      (err: HttpErrorResponse) => {
        this._constant.loading(false);
      })

  }




}

import { OrderProductModel } from './Order-Product.model';

export class OrderModel {

    OrderId: number;      
    OrderNumber : string;
    PartnerUserId:number;
    OrderDate :Date;
    ShippedDate:Date;
    ShippingMethod : string;
    PaymentMethod : string;
    Status:string;
    OrderStatus:string;
    Cancelled:boolean;
    Subtotal:number;    
    Tax : number;
    ShippingCost :number;
    Decision  : string;      
    DecisionShow:string;   
    OrderMsgShow: string;
    DiscountedAmount : number;
    TotalUnitPrice :number;
    EstimateDeliveryDate: string;
   
    orderProdItems: OrderProductModel[]=[];   
}



import { OrderModel } from './order.model';
import { ShippingAddress } from '../Payment/shippingaddress.model';
import { BillingAddress } from '../Payment/billingaddress.mode';


export class OrderSingleModel {

    orderItem: OrderModel;    
    shipping :ShippingAddress;
    billing :BillingAddress;

    totalPrice:number;
    totalDiscount:number;
    totalShipping :number;
    grandTotal:number;
  onlyBiling: boolean;
}



import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../common/constants.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  public WebSiteUrl:string;

  constructor(private _Constant:ConstantsService) { 
    // this.WebSiteUrl=_Constant.ConstatntWebSiteUrl;
    this.WebSiteUrl='https://mandaimoment.mandai.com/';
  }

  ngOnInit() {
  }

}

import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { PartnerUserService } from '../services/PartnerUser/partner-user.service';
import { NgForm } from '@angular/forms';
import { ConstantsService } from '../common/constants.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { QrClaimService } from '../services/Claim/qr-claim.service';
import { ClaimPhotosService } from '../services/ClaimPhotos/claim-photos.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeComponent implements OnInit {

  readonly _constantPartnerID: number;
  public UserEmailID: string;
  public UserPassword: string;
  public isValidCaptcha: boolean;
  public isValidPswd: boolean;
  public isValidLenS: boolean;
  public isValidLenF: boolean;
  showHead: boolean = false;
  chkemail:any;
  firstNum: number;
  secondNum: number;
  mathSing: string;
  resNum: string;
  datePickerConfig = { isAnimated: true, dateInputFormat: 'DD-MM-YYYY',showWeekNumbers:false , containerClass:'theme-default', maxDate: new Date() };

  @ViewChild('IEAlertTemplate', { static: true }) IEAlertTemplate: TemplateRef<any>;

  public regPswd: string;
  public regCnfPswd: string;
  /********* ngx modal *********/
  modalRef: BsModalRef;

  isAccepted: boolean;
  messages = ['+', '-'];

  qrId: string= '';
  nqrId: string = 'NotNewQR';
  public countryList:any = [];
  countryCode: string = '';
  birthDate: any ;
  isSelected: boolean = true;
  newuser: boolean = true;


  constructor(
    public service: PartnerUserService,
    public photoservice : ClaimPhotosService,
    private _constant: ConstantsService,
    protected _router: Router,
    private qrservice: QrClaimService,
    private modalService: BsModalService,
    // ngx toaster
    private toastr: ToastrService,
    protected _route: ActivatedRoute,
    private router: Router

  ) {
    this._constantPartnerID = _constant.ConstatntPartnerID;
    this._constant.loading(false);
    // this.qrId = sessionStorage.getItem('QrId');
    // this.nqrId = this._route.snapshot.queryParams.QrCode
    // console.log("routeparam",this.nqrId)
    if (this.router.getCurrentNavigation().extras.state){
    this.nqrId = this.router.getCurrentNavigation().extras.state.QrCode;
    // console.log(this.nqrId);
  }
    // this._route.paramMap.subscribe(async params => {

    //   this.nqrId = params.get('qrcode');
    //   console.log(this.nqrId)
    // })
    sessionStorage.clear();
    localStorage.clear();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  /****************** dynamically add class ******************/
  ngOnInit() {
    //  this._constant.getCountryCodes().then((res) => {
    //   this.countryList = res;
    // }, (err) => {
    //   console.log('err is ', err);
    // })


    const isIEOrEdge = /Trident\/|MSIE/i.test(window.navigator.userAgent)
    {
      if (isIEOrEdge == true) {
        this.openModal(this.IEAlertTemplate)
      }
    }

    // Detect Internet Explorer
    // let IExplorerAgent = userAgentString.indexOf("MSIE") > -1 ||
    //                  userAgentString.indexOf("rv:") > -1;

    this.isAccepted = true;
    const body = document.getElementsByClassName('super-container')[0];
    body.classList.add('app-welcome-page');
    this.resetForm();

    sessionStorage.clear();


    // this.firstNum = Math.floor(Math.random() * 100 + 11);
    // this.secondNum = Math.floor(Math.random() * 10) + 1;
    this.firstNum = Math.floor(Math.random() * 100 +11);
    this.firstNum=this.firstNum>=100? this.firstNum-20:this.firstNum;
    this.secondNum = Math.floor(Math.random() * 10) ;
    this.secondNum=this.secondNum<=0? this.secondNum+1:this.secondNum;
    this.mathSing = this.messages[0];

    // this.mathSing = this.messages[Math.floor(Math.random() * 2 + 0)];
    this.isValidCaptcha = true;
    this.isValidPswd = true;
    this.isValidLenF = true;
    this.isValidLenS = true;
    this.regPswd = '';
    this.regCnfPswd = '';

  }

  ngOnDestroy() {

    const body = document.getElementsByClassName('super-container')[0];
    body.classList.remove('app-welcome-page');
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    this.isValidCaptcha = true;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  lettersOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if ((charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32) {
      return true;
    }
    return false;
  }
  generateCaptcha() {
    // this.firstNum = Math.floor(Math.random() * 100 + 11);
    // this.secondNum = Math.floor(Math.random() * 10) + 1;
    // this.mathSing = this.messages[Math.floor(Math.random() * 2 + 0)];
    this.firstNum = Math.floor(Math.random() * 100 +11);
    this.firstNum=this.firstNum>=100? this.firstNum-20:this.firstNum;
    this.secondNum = Math.floor(Math.random() * 10) ;
    this.secondNum=this.secondNum<=0? this.secondNum+1:this.secondNum;
  }
  checkCaptcha() {
    if (eval(this.firstNum + this.mathSing + this.secondNum) == this.resNum) {

      this.isValidCaptcha = true;
    }
    else {

      this.isValidCaptcha = false;
    }
    this.resNum = '';
  }
  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this.service.formData = {
      PartnerUserId: 0,
      PartnerId: this._constantPartnerID,
      UserName: '',
      Email: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordSalt: '',
      UserType: 0,
      Company: '',
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Zip: '',
      Country: '',
      Phone: '',
      Fax: '',
      UseBillingAsShipping: false,
      Gender: '',
      Nationality: '',
      FacebookId: '',
      Birthday: null,
      GooglePlusId: '',
      NewsletterSubscription: false
    }

    this.UserEmailID = '';
    this.UserPassword = '';
    this.regPswd = '';
    this.regCnfPswd = '';
    this.generateCaptcha();
    this.isAccepted = true;
    this.birthDate = null;
    this.countryCode = '';
  }

  onSignIn() {
    //console.log(this.UserEmailID);
    //console.log(this.UserPassword);
    this._constant.loading(true);

    this.service.CheckUser(this.UserEmailID, this.UserPassword).then(
      async (res) => {
        // console.log('parent user id is ', this.service.formData, this.service.formData.PartnerUserId);
        sessionStorage.setItem("FirstName", this.service.formData.FirstName)
        localStorage.setItem("PartnerUserId", this.service.formData.PartnerUserId.toFixed());
        // console.log("PartnerUserId", this.service.formData.PartnerUserId.toFixed())
        // console.log("PartnerUserId", this.service.formData.PartnerUserId)
        if (this.qrId) {
          // sessionStorage.setItem('QrId', this.qrId);
          // // this._router.navigateByUrl('/qrclaim');

          // try {
          //   const claimCodeRequest = await this.qrservice.getClaimCodePhotos(this.qrId, localStorage.getItem('PartnerUserId'));
          //   this.qrservice.CheckUserImages(localStorage.getItem('PartnerUserId'));
          //   sessionStorage.removeItem('QrId');
          //   this._constant.loading(false);
          // } catch (err) {
          //   sessionStorage.removeItem('QrId');
          //   this.qrservice.CheckUserImages(localStorage.getItem('PartnerUserId'));
          //   if (err.status == 1) {
          //     this.toastr.error('Invalid QR code');
          //   }
          //   else if (err.status == 2) {
          //     this.toastr.warning('QR Code is expired');
          //   }
          //   else if (err.status == 3) {
          //     this.toastr.warning('QR Code Claim Expiry Days not found');
          //   }
          //   else {
          //     this.toastr.error('Invalid QR code');
          //   }
          //   console.log(err);
          //   this._constant.loading(false);
          // }


        } else {
          this.modalRef.hide();
          this.toastr.success('User Login successfully!');
          // console.log("this.nqrId",this.nqrId)
          if(this.nqrId!='NotNewQR'){
            // console.log("insidernqrId",this.nqrId)
            // this.service.Chkqrlinkuser(this.nqrId, localStorage.getItem('PartnerUserId'));
            this.qrservice.getClaimCodePhotos(this.nqrId, localStorage.getItem('PartnerUserId')).subscribe(
              (res) => {
               
                this.qrservice.CheckUserImages(localStorage.getItem('PartnerUserId'));        
                this._constant.loading(false);
                
              },
              (err: HttpErrorResponse) => {
              })
          }
          else{
            this.photoservice.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
            .then(res => {
              var usercheck = res;
              // console.log("usercheck",usercheck)
              if (usercheck.length > 0){
                this.newuser = false;
                this._router.navigateByUrl('/gallery');
              }
              else{
                // console.log("imagecount",this.newuser)
                this._router.navigateByUrl('/qrclaim');
              }
            });
          }
          
        }
        this._constant.loading(false);

      },
      (err: HttpErrorResponse) => {
        this._constant.loading(false);

        if (err.status == 1) {
          this.UserEmailID = "";
          this.UserPassword = "";
          this.toastr.error('Invalid Username and Password');
          //alert('Invalid Username and Password');
        }
        else {
          this.toastr.warning('Login failed.', '', {
            positionClass: 'toast-top-center'
          });
          // this._router.navigateByUrl('/error');
        }


        console.log(err);

      }
    )
  }

  focusOutFunction() {
    if (this.regPswd == this.regCnfPswd) {

      this.isValidPswd = true;
    }
    else {

      this.isValidPswd = false;
    }
    if (this.regPswd.length >= 8) {

      this.isValidLenF = true;
    }
    else {

      this.isValidLenF = false;
    }
    if (this.regCnfPswd.length >= 8) {

      this.isValidLenS = true;
    }
    else {

      this.isValidLenS = false;
    }
  }
  onSubmit(form: NgForm) {
    this.checkCaptcha();
  
    if (!this.isValidCaptcha) {
      this.generateCaptcha();
      this.regPswd = '';
      this.regCnfPswd = '';
      return;
    }
    this._constant.loading(true);
    if (this.service.formData.PartnerUserId == 0) {
      // this.service.formData = {

      //   PartnerUserId: 0,
      //   PartnerId: this._constantPartnerID,
      //   UserName: this.service.formData.Email,
      //   Email: this.service.formData.Email,
      //   FirstName: this.service.formData.FirstName,
      //   LastName: '',
      //   Password: this.regPswd,
      //   PasswordSalt: '',
      //   UserType: 0,
      //   Company: '',
      //   Address1: '',
      //   Address2: '',
      //   City: '',
      //   State: '',
      //   Zip: '',
      //   Country: '',
      //   Phone: '',
      //   Fax: '',
      //   UseBillingAsShipping: false,
      //   Gender: '',
      //   Nationality: this.service.formData.Nationality,
      //   FacebookId: '',
      //   Birthday: this.service.formData.Birthday,
      //   GooglePlusId: '',
      //   NewsletterSubscription: false
      // }

      this.service.formData.PartnerId = this._constantPartnerID;
      this.service.formData.UserName = this.service.formData.Email;
      this.service.formData.Password = this.regPswd;

      this.insertRecord(form);
    }

  }

  insertRecord(form: NgForm) {
    console.log("g")
    if (this.validateEmail(this.service.formData.Email) == false) {
      this.toastr.warning('Invalid Email Id!', '', {
        positionClass: 'toast-top-center'
      });
      this._constant.loading(false);
    }
    else {
      // this.duplicateemailchk(this.service.formData.Email)
      // this.service.chkemailUser(this.service.formData.Email)
      console.log("ewrr")
      this.service.formData.Birthday = form.value.BirthDate;
      this.service.formData.Nationality = form.value.Country;
      this.service.postPartnerUser().then(
        async (res) => {

          this.toastr.success('User registered successefully!');

          sessionStorage.setItem("FirstName", this.service.formData.FirstName)
          localStorage.setItem("PartnerUserId", this.service.formData.PartnerUserId.toFixed());
          // console.log("this.nqrId",this.nqrId)
          if(this.nqrId!='NotNewQR'){
            // console.log("insidernqrId",this.nqrId)
            // this.service.Chkqrlinkuser(this.nqrId, localStorage.getItem('PartnerUserId'));
            this.qrservice.getClaimCodePhotos(this.nqrId, localStorage.getItem('PartnerUserId')).subscribe(
              (res) => {
               
                this.qrservice.CheckUserImages(localStorage.getItem('PartnerUserId'));        
                this._constant.loading(false);
                
              },
              (err: HttpErrorResponse) => {
              })
          }
          this.modalRef.hide();
          this.photoservice.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
          .then(res => {
            var usercheck = res;
            console.log("usercheck",usercheck)
            if (usercheck.length > 0){
              this.newuser = false;
              this._router.navigateByUrl('/gallery');
            }
            else{
              console.log("imagecount",this.newuser)
              this._router.navigateByUrl('/qrclaim');
            }
          });
          this._constant.loading(false);

        },
        (err: HttpErrorResponse) => {
          this._constant.loading(false);
          if (err.status == 1) {
            this.toastr.warning('Email ID already exist', '', {
              positionClass: 'toast-top-center'
            });
            this.resetForm(form);
          }
          else {
            this.toastr.warning('Registration failed.', '', {
              positionClass: 'toast-top-center'
            });
            // this._router.navigateByUrl('/error');
          }
          console.log(err);
        }
      )
    }
  }


  validateEmail(email) {
    var re = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
    return re.test(email);
  }
  duplicateemailchk(email){
    // console.log("g")
    this.service.chkemailUser(email).then(
      async (data) => {
        // console.log("res")
        alert(data)
      },
      (err: HttpErrorResponse) => {
        console.log(err)
      })
  }

  decline(): void {
    this.modalRef.hide();
  }
}

import { Component, NgZone, OnDestroy, ViewChild, OnInit, ViewEncapsulation, TemplateRef, HostListener, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
// import { NgScrollbar } from 'ngx-scrollbar';
import { CartService } from '../services/checkout/cart.service';
import { PartnerWebPhotos } from '../services/ClaimPhotos/partner-webphotos.model';
import { PartnerProductModel } from '../services/ClaimPhotos/partner-products.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConstantsService } from '../common/constants.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CartComponent implements OnInit {

  public selectedImages: number;
  public selectedCartItemIdId: number;
  public selectedWebPhotos: string;


  public slideIndex: number;

  formData: PartnerProductModel;
  public selectedQnty: number;
  public isNextDisable: boolean;
  public isPrevDisable: boolean;

  public selectedSlide: number;

  //new
  public productList: PartnerProductModel[];

  public isPackage: boolean;
  public packageName: string;
  public packageDesc: string;
  public productImage: string;
  public cartText: string;
  public cartTextPopup: string;
  public selectedProductId: number;
  public selectedMaxImageCount: number;
  public productCount: number;
  public scrollbarOptions = {};

  public isCartEmpty : boolean;
  public emptyCartText: string;  
  //constructor() { }

  ngOnInit() {
    ///Session expired///

    this._constant.setTimeout();
    this._constant.userInactive.subscribe(() => {
      this._router.navigateByUrl('/welcome');
    });
    /// End of Session expired///
    this._constant.loading(true);
    this.selectedImages = 0;
    
    this.isCartEmpty=true;
    this.selectedWebPhotos=""; 
    this.selectedQnty=0;
    this.isPrevDisable=true;
    this.isNextDisable=false;
    this.selectedSlide=0;
     this.productCount=0;
     this.cartText="Add To Cart";
     this.cartTextPopup="Product successfully added!";
     this.selectedProductId=0; 
     this.emptyCartText="";    
     
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
      this._constant.loading(false);
    }
    else {
      this.getCartItems();
      // if((sessionStorage.getItem('ProductID') == 'null')) {
      //   this.getCartItems();                
      // }
      // else
      // {


      //   this.getAllProductsCart();                      

      // }
    }
  }



  getAllProductsCart() {
    this._constant.loading(true);

    this.cartText = "Add To Cart";
    this.cartTextPopup = "Product successfully added!";
    this._cartService.getPartnerCartProducts(
      sessionStorage.getItem('PartnerUserId'), sessionStorage.getItem('ProductID')).then
      (
      (res) => {

        this.productList = res as PartnerProductModel[];
        this.productList.forEach(obj => {
          this.productCount = this.productCount + 1;
        });
        this.selectedProductId = this.productList[0].ProductId;
        this.selectedCartItemIdId = +sessionStorage.getItem('CartItemID');
        this.selectedMaxImageCount = this.productList[0].MaxImageCount;
        if (this.productCount > 1) {
          this.isPackage = true;
        }
        else
        {
          this.isPackage=false; 
        }
        this.packageName = this.productList[0].PackageName;
        this.packageDesc = this.productList[0].PackageDescription;
        this.productImage = this.productList[0].ImageView1;

        sessionStorage.setItem("ProductID", null);

        this.getAllPhotosCart();


      },
      (err) => {
        this._constant.loading(false);
      }
      );


  }



  getAllPhotosCart() {

    document.getElementById('ClearCart').style.display = "none";
    this._constant.loading(true);

    this._cartService.getPartnerPhotosforSelection(
      sessionStorage.getItem('PartnerUserId'), this.selectedCartItemIdId, this.selectedProductId).then
      (
      (res) => {

        this._cartService.list = res;
        this.selectedWebPhotos = this._cartService.list.selectedWebPhotos;
        this.selectedImages = 0;
        if (this.selectedWebPhotos) {
          var array = this.selectedWebPhotos.split(",");
          this.selectedImages = array.length - 1;
        }
        if (!this.isPackage) {
          this._cartService.list.isCartDisable = !this._cartService.list.isSaveSelection;
        }

        if (this._cartService.list.isCartDisable) {
          if (document.getElementById('addCartButton')) {
            document.getElementById('addCartButton').setAttribute('disabled', 'true');
          }
        }
        else {
          if (document.getElementById('addCartButton')) {
            document.getElementById('addCartButton').removeAttribute('disabled');
          }
        }

        if (!this._cartService.list.isSaveSelection) {
          if (document.getElementById('applyButton')) {
            document.getElementById('applyButton').setAttribute('disabled', 'true');
          }
        }
        else {
          if (document.getElementById('applyButton')) {
            document.getElementById('applyButton').removeAttribute('disabled');
          }
        }

        this.isCollapsed = false;
        sessionStorage.setItem("CartItemID", null);
        this._constant.loading(false);
      },
      (err) => {
        this._constant.loading(false);
      }
      );


  }


  getAllPhotosSelect(prdcts: PartnerProductModel) {

    this._constant.loading(true);

    this.productCount = 0;
    this.cartText = "Update Cart";
    this.cartTextPopup="Updated successfully";
    this._cartService.getPartnerCartProducts(
      sessionStorage.getItem('PartnerUserId'), prdcts.ProductId).then
      (
      (res) => {


        this.productList = res as PartnerProductModel[];
        this.productList.forEach(obj => {
          this.productCount = this.productCount + 1;
        });
        this.selectedProductId = this.productList[0].ProductId;
        if (prdcts.isPackage) {
          this.selectedCartItemIdId = prdcts.CartItemId + 1;
        }
        else {
          this.selectedCartItemIdId = prdcts.CartItemId;
        }
        this.selectedMaxImageCount = this.productList[0].MaxImageCount;
        if (this.productCount > 1) {
          this.isPackage = true;
        }
        else {
          this.isPackage = false;
        }
        this.packageName = this.productList[0].PackageName;
        this.packageDesc = this.productList[0].PackageDescription;
        this.productImage = this.productList[0].ImageView1;

        sessionStorage.setItem("ProductID", null);

        this.getAllPhotosCart();


      },
      (err) => {
        this._constant.loading(false);
      }
      );

  }

  addToCart() {
    this._constant.loading(true);
    if (this.isPackage) {
      this._cartService.putPackage(sessionStorage.getItem('PartnerUserId'))
        .subscribe(
          (res) => {

            this._constant.loading(false);
          },
          (err: HttpErrorResponse) => {

            console.log(err);

          }
        );
    }
    else {
      this._cartService.putWebPhotos(this.selectedCartItemIdId, this.selectedWebPhotos,
        this.selectedProductId, sessionStorage.getItem('PartnerUserId'))
        .subscribe(
          (res) => {
            console.log("done");
          },
          (err: HttpErrorResponse) => {

            console.log(err);

          }
        );
    }
  }


  prevSlide() {

    this._constant.loading(true);
    if (this.selectedImages != this.selectedMaxImageCount) {
      this.toastr.warning('select ' + (this.selectedMaxImageCount - this.selectedImages) + ' Photos');
      this._constant.loading(false);
      return;
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    this._cartService.putWebPhotos(this.selectedCartItemIdId, this.selectedWebPhotos,
      this.selectedProductId, sessionStorage.getItem('PartnerUserId'))
      .subscribe(
        (res) => {

          if (this.selectedImages == this.selectedMaxImageCount) {
            document.getElementById("dotID_" + this.selectedSlide).classList.remove("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.remove("active");

            if (this.selectedSlide == 0) {
              this.selectedSlide = this.productCount - 1;
              this.selectedCartItemIdId = this.selectedCartItemIdId + (this.productCount - 1);
            }
            else {
              this.selectedSlide = this.selectedSlide - 1;
              this.selectedCartItemIdId = this.selectedCartItemIdId - 1;
            }

            document.getElementById("dotID_" + this.selectedSlide).classList.add("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.add("active");

            this.selectedProductId = this.productList[this.selectedSlide].ProductId;
            this.selectedMaxImageCount = this.productList[this.selectedSlide].MaxImageCount;
            this.productImage = this.productList[this.selectedSlide].ImageView1;


            this.getAllPhotosCart();

          }


        },
        (err: HttpErrorResponse) => {

          console.log(err);

        }
      );


  }

  nextSlide() {

    this._constant.loading(true);
    if (this.selectedImages != this.selectedMaxImageCount) {
      this.toastr.warning('select ' + (this.selectedMaxImageCount - this.selectedImages) + ' Photos');
      this._constant.loading(false);
      return;
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    this._cartService.putWebPhotos(this.selectedCartItemIdId, this.selectedWebPhotos,
      this.selectedProductId, sessionStorage.getItem('PartnerUserId'))
      .subscribe(
        (res) => {

          if (this.selectedImages == this.selectedMaxImageCount) {
            document.getElementById("dotID_" + this.selectedSlide).classList.remove("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.remove("active");
            if (this.selectedSlide == (this.productCount - 1)) {
              this.selectedSlide = 0;
              this.selectedCartItemIdId = this.selectedCartItemIdId - (this.productCount - 1);
            }
            else {
              this.selectedSlide = this.selectedSlide + 1;
              this.selectedCartItemIdId = this.selectedCartItemIdId + 1;
            }
            document.getElementById("dotID_" + this.selectedSlide).classList.add("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.add("active");

            this.selectedProductId = this.productList[this.selectedSlide].ProductId;
            this.selectedMaxImageCount = this.productList[this.selectedSlide].MaxImageCount;
            this.productImage = this.productList[this.selectedSlide].ImageView1;


            this.getAllPhotosCart();

          }


        },
        (err: HttpErrorResponse) => {

          console.log(err);

        }
      );

  }

  getCartItems() {

    this.selectedSlide = 0;

    this._cartService.getUserCartProducts(sessionStorage.getItem('PartnerUserId')).then
      (
      (res) => {

        this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));

          if (res.totalProducts == 0)
          {
            this.isCartEmpty=true;
            this.emptyCartText="<h4>Your Shopping Cart Is <b>Empty</b><br />please add products</h4>";    
            document.getElementById('ClearCart').style.display = "none"
            if (document.getElementById('enterAddressBtn')) {
              document.getElementById('enterAddressBtn').setAttribute('disabled', 'true');
            }
          }
          else
          {
            this.emptyCartText="";    
            this.isCartEmpty=false;
            document.getElementById('ClearCart').style.display = "";
            if (document.getElementById('enterAddressBtn')) {
              document.getElementById('enterAddressBtn').removeAttribute('disabled');
            }
          }
          this._constant.loading(false);
        },
        (err) => {
          this._constant.loading(false);
        }
      );
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  /********* ngx scrollbar *********/

  // Unsubscriber for elementScrolled stream.
  private _scrollSubscription = Subscription.EMPTY;

  // Get NgScrollbar reference
  // @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @ViewChild('RemoveCartItemTemplate', { static: true }) templateAlert: TemplateRef<any>;
  @ViewChild('RemoveAllCartItemTemplate', { static: true }) RemoveAlltemplateAlert: TemplateRef<any>;
  @ViewChild('QuantityTemplate', { static: true }) QuantityTemplate: TemplateRef<any>;
  constructor(private ngZone: NgZone,
    public _cartService: CartService,
    private toastr: ToastrService, protected _router: Router
    , private _constant: ConstantsService,
    private modalService: BsModalService) {

    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
    }
  }

  ngOnDestroy() {

    this._scrollSubscription.unsubscribe();
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
  onqntClick(formData: PartnerProductModel) {
    this.selectedQnty = formData.Quantity;
    console.log('click=' + this.selectedQnty);
  }
  onQntChange(formData: PartnerProductModel) {
    console.log('change=' + this.selectedQnty);
    this.formData = formData;
    this.SameImages();
    // if(this.selectedQnty>formData.Quantity)
    // {
    //   console.log('call fun');
    //   this.SameImages();
    // }
    // else
    // {
    //   let value = (<HTMLSelectElement>document.getElementsByClassName('custom-select')).value;     
    //   this.openModal(this.QuantityTemplate)
    // }
  }


  CloseModel() {
    document.getElementById('ClearCart').style.display = "";
  }


  clearSelectionAuto() {

    var array = this.selectedWebPhotos.split(",");
    //array.reduceRight   
    array.forEach(function (value) {

      let element = document.getElementById('wb_' + value)
      if (element != null) {
        element.className = 'block not-selected';
      }
    });
  }

  clearSelection() {
    if (this.selectedWebPhotos) {
      if (this.selectedWebPhotos != "null") {
        var array = this.selectedWebPhotos.split(",");

        array.forEach(function (value) {
          let element = document.getElementById('wb_' + value)
          if (element != null) {
            element.className = 'block not-selected';
          }
        });
      }
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    if (document.getElementById('addCartButton')) {
      document.getElementById('addCartButton').setAttribute('disabled', 'true');
    }

    this.selectedImages = 0;
    this.selectedWebPhotos = "";

  }






  imageSelectEvent(image: PartnerWebPhotos) {


    let element = document.getElementById('wb_' + image.WebPhotoId);

    if (this.selectedImages == this.selectedMaxImageCount && element.className != 'block selected') {
      if (this.selectedMaxImageCount == 0) {
        this.toastr.warning('Online all digital product does not require image selection');
        return;
      }
      this.toastr.warning('No. of maximum images are already selected. If you want another one, please deselect selected image(s) and proceed.');
      return;
    }

    if (element.className == 'block selected') {

      this.selectedWebPhotos = this.selectedWebPhotos.replace(image.WebPhotoId + ',', '')

      this.selectedImages = this.selectedImages - 1;
      element.className = 'block not-selected';

    } else {
      this.selectedWebPhotos = this.selectedWebPhotos + image.WebPhotoId + ',';

      this.selectedImages = this.selectedImages + 1;
      element.className = 'block selected';
    }


    if (this.selectedMaxImageCount == this.selectedImages) {
      if (document.getElementById('applyButton')) {
        document.getElementById('applyButton').removeAttribute('disabled');
      }

      if (!this.isPackage) {
        if (document.getElementById('addCartButton')) {
          document.getElementById('addCartButton').removeAttribute('disabled');
        }
      }

      if (this.cartText == "Update Cart") {
        if (document.getElementById('addCartButton')) {
          document.getElementById('addCartButton').removeAttribute('disabled');
        }
      }


    }
    else {
      if (document.getElementById('applyButton')) {
        document.getElementById('applyButton').setAttribute('disabled', 'true');
      }
      if (document.getElementById('addCartButton')) {
        document.getElementById('addCartButton').setAttribute('disabled', 'true');
      }


    }

  }


  onCartItemDelete(CartItemId) {
    this.selectedCartItemIdId = CartItemId
    this.openModal(this.templateAlert)

  }
  Delete(CartItemId)
  {    
      this._constant.loading(true);
      this._cartService.deleteCartItem(CartItemId)
        .subscribe(res => {
          this.toastr.info('Removed Successfully'); 
          this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));         
          this.getCartItems();
          this._constant.loading(false);
        },
          err => {
            this.toastr.error('Error');
            console.log(err);
            this._constant.loading(false);
          }
        );
  }

  expPhotos() {
    this._router.navigateByUrl('/cart');
    // if((sessionStorage.getItem('PhotoPage') == 'claim')) {
    //   this._router.navigateByUrl('/claimed-photos');               
    // }
    // else if((sessionStorage.getItem('PhotoPage') == 'non-claim')) {
    //   this._router.navigateByUrl('/nonclaimed-photos');               
    // }
    // else {
    //   this._constant.CheckUserImages(sessionStorage.getItem('PartnerUserId'));
    // }

  }

  loaderHide() {
    this._constant.loading(false);
  }

  /********* gallery images dynamic *********/
  userimages = [
    { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" },
    { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" },
    { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" },
    { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" },
    { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" },
    { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" },
    { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }, { image: "assets/images/change-photo.jpg" }
  ]


  /********* ngx collapse *********/
  isCollapsed = true;
  isCollapsedStickers = true;
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  confirm(): void {
    this.Delete(this.selectedCartItemIdId)
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
  ClearCart() {
    this.openModal(this.RemoveAlltemplateAlert)

  }
  confirmAll() {
    this._constant.loading(true);
    this._cartService.ClearCart(sessionStorage.getItem('PartnerUserId'))
      .subscribe(res => {
        this.toastr.info('Removed Successfully');
        this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
        this.getCartItems();
        this._constant.loading(false);
      },
        err => {
          this.toastr.error('Error');
          console.log(err);
          this._constant.loading(false);
        }
      );
    this.modalRef.hide();
  }

  SameImages() {
    // this.toastr.info(''+this.formData.ProductId+'   '+this.formData.Quantity);              
    this._constant.loading(true);
    this._cartService.postChangeQuantity(sessionStorage.getItem('PartnerUserId'), this.formData.ProductId, this.formData.Quantity, this.formData.CartItemId).then
      (
      (res) => {
        this.toastr.success('Product quantity changed');
        this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
        this._cartService.getUserCartProducts(sessionStorage.getItem('PartnerUserId'));
        this._constant.loading(false);
      },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error! Adding Product');
        console.log(err);
        this._constant.loading(false);

      }
      );
    this.modalRef.hide();
  }

  NewImages() {
    //this.toastr.info('NewImage');
    this.isCollapsed = false;

    this.getAllPhotosSelect(this.formData);
    this.modalRef.hide();
    this._constant.loading(true);
    this._cartService.postChangeQuantityWithNewImg(sessionStorage.getItem('PartnerUserId'), this.formData.ProductId, this.formData.Quantity, this.formData.CartItemId).then
      (
      (res) => {
        this.toastr.success('Product Added Succesfully');
        this._cartService.CheckCartCount(sessionStorage.getItem('PartnerUserId'));
        this._cartService.getUserCartProducts(sessionStorage.getItem('PartnerUserId'));
        this._constant.loading(false);
      },
      (err: HttpErrorResponse) => {
        this.toastr.error('Error! Adding Product');
        console.log(err);
        this._constant.loading(false);

      }
      );
  }



   /********* window scoll top *********/
   @HostListener('window:scroll')
   checkScroll() {
     // window의 scroll top
     // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
     const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
     console.log('[scroll]', scrollPosition);    
     // if (scrollPosition >= this.topPosToStartShowing) {
     //   this.isShow = true;
     // } else {
     //   this.isShow = false;
     // }
   }
   // TODO: Cross browsing
   gotoTop() {
     window.scroll({ 
       top: 0, 
       left: 0, 
       behavior: 'smooth' 
     });
   }

}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FooterComponent } from './footer/footer.component';
import { QRclaimComponent } from './qrclaim/qrclaim.component';
import { QrclaimnewComponent } from './qrclaimnew/qrclaimnew.component';
import { ClaimedPhotosComponent } from './claimed-photos/claimed-photos.component';
import { NonclaimedPhotosComponent } from './nonclaimed-photos/nonclaimed-photos.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { AccountComponent } from './account/account.component';
import { EditComponent } from './account/edit/edit.component';
import { OrderhistoryComponent } from './account/orderhistory/orderhistory.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { HelpComponent } from './help/help.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ErrorComponent } from './error/error.component';

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', component: WelcomeComponent },
  { path: 'welcome', component: WelcomeComponent},
  { path: "header", component: HeaderComponent },
  { path: "footer", component: FooterComponent },
  { path: "qrclaim", component: QRclaimComponent },
  { path: "new-qrclaim/:qrcode", component: QrclaimnewComponent },
  { path: "claimed-photos", component: ClaimedPhotosComponent },
  { path: "claimed-photos/:twit", component: ClaimedPhotosComponent },
  { path: "nonclaimed-photos", component: NonclaimedPhotosComponent },
  { path: "cart", component: CartComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "payment", component: PaymentComponent },
  { path: "account/edit", component: EditComponent },
  { path: "account/orderhistory", component: OrderhistoryComponent },
  { path: "receipts/:uid/:rid", component: ReceiptsComponent },
  { path: "customer-support", component: CustomerSupportComponent },
  { path: "help", component: HelpComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "forgotpassword", component: ForgotpasswordComponent },
  { path: "terms-conditions", component: TermsConditionsComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "contactus", component: ContactusComponent },
  { path: "error", component: ErrorComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

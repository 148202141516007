import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { OrderModel } from '../services/order/order.model';
import { OrderSingleModel } from '../services/order/Order-Single.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantsService } from '../common/constants.service';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReceiptsComponent implements OnInit {
  private canvas: any;
  public selectOrder: number;
  private sub: any;
  public id: string;
  onlyBilling: boolean;

  capturedImage;
  constructor(public service: OrderService, protected router: Router
    , protected _route: ActivatedRoute
    , private _constant: ConstantsService
    , private toastr: ToastrService) {
    this.selectedOrder = new OrderModel();
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this.router.navigateByUrl('/welcome');
    }
  }

  public selectedOrder: OrderModel;
  public selectedOrder1: OrderModel;


  ngOnInit() {


    this.id = "";

    if ((sessionStorage.getItem('PartnerUserId') != null)) {
      this.sub = this._route.paramMap.subscribe(async params => {
        console.log(params);
        this.id = params.get('rid');
        console.log("rid=" + this.id);
      });
      if (this.id.length > 0) {

        this.getData(this.id);
      }
    }
    else {
      this.router.navigateByUrl('/welcome');
      this._constant.loading(false);
    }


  }


  PrintElem() {

    var mywindow = window.open('', '', 'height=' + screen.height + ',width=' + screen.width + ',resizable=yes');
    mywindow.document.write('<html><head><title></title>');
    mywindow.document.write('</head><body >');

    var data = document.getElementById("print-receipt").outerHTML;
    mywindow.document.write(data);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    mywindow.print();
  }

  getData(orderid) {

    this.service.getSingleOrderDetails(sessionStorage.getItem('PartnerUserId'), orderid).then
      (
      (res) => {

        this.service.formDataSingle = res as OrderSingleModel;

        if(this.service.formDataSingle.onlyBiling)
        {
          
          this.onlyBilling=true;
        }
        else {

          this.onlyBilling = false;
        }

        this._constant.loading(false);
      },
      (err) => {
        this.toastr.error('Invalid Order Data');
        this.router.navigateByUrl('/account/orderhistory');

        this._constant.loading(false);
      }
      );
  }


  expPhotos() {
    this._constant.CheckUserImages(sessionStorage.getItem('PartnerUserId'));
  }

}


export class PaymentDetails {
  isPayEnable:boolean;
  access_key:string;
  profile_id:String;
  transaction_uuid:string;
  signed_field_names:string;
  unsigned_field_names :string;
  signed_date_time :string;
  locale :string;
  payment_method :string;
  bill_to_address_country :string;
  bill_to_address_line1 :string;
  bill_to_address_city :string;
  bill_to_email :string;
  bill_to_forename :string;
  bill_to_surname :string;
  bill_to_address_state :string;
  bill_to_address_postal_code :string;
  transaction_type :string;
  reference_number :string;
  amount :string;
  currency :string;
  signature :string;
  merchant_defined_data1 :string;
  merchant_defined_data2 :string;
  //Device type(browser or mobile)
  merchant_defined_data3 :string;
  auth_avs_code :string;
  req_card_number :string;
  req_card_expiry_date :string;
  bill_trans_ref_no :string;
  decision :string;
  payer_authentication_proof_xml :string;
  auth_code :string;
  reason_code :Number;
  req_card_type :Number;
  auth_amount :number;
  auth_cv_result_raw :string;
  auth_cv_result :string;
  auth_avs_code_raw :string;
  transaction_id :string;
  auth_time :string;
  message :string;
  auth_response :string;
  auth_trans_ref_no :string;
  SignatureVerified : number;
  customer_ip_address : string;
  GatewayURL : string;

}


import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../common/constants.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  public WebSiteUrl:string

  constructor(private _constant: ConstantsService) { 
    // this.WebSiteUrl=_constant.ConstatntWebSiteUrl;
    this.WebSiteUrl="https://mandaimoment.mandai.com/";
  }

  ngOnInit() {
    
  }

}

import { PartnerWebPhotos } from './partner-webphotos.model';
import { HttpClient, HttpParams} from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { PartnerProductModel } from './partner-products.model';
import { PartnerBorders } from './partner-border.model';
import { CloudinaryData } from './cloudinary-data.model';
import { PartnerGraphics } from './partner-graphics.model';
import { EmailShare } from './EmailShare.model';


@Injectable({
    providedIn: 'root'
  })

export class ClaimPhotosService {
    formData: PartnerWebPhotos;
    formBorders:PartnerBorders[];
    formGraphics:PartnerGraphics[];
    
    formDataEmail:EmailShare;
    
    readonly rootURL;
    list : PartnerWebPhotos[];
    listProducts : PartnerProductModel[];

    constructor(private http:HttpClient, private _constant: ConstantsService) {
      this.rootURL=_constant.ConstatntWebAPI;
     }
  
     getPartnerPhotos(patnerUserID) {
      // console.log("g")
      return this.http.get(this.rootURL+'/ClaimPhotos/'+patnerUserID).toPromise()
      .then(res => this.list = res as PartnerWebPhotos[]);;
     }
    
     getPartnerProducts(patnerUserID) {
      return this.http.get(this.rootURL+'/ClaimPhotos/getProducts/'+patnerUserID).toPromise()
      .then(res => this.listProducts = res as PartnerProductModel[]);;
     }

     getBorders(patnerUserID)
     {
      // console.log("d")
      return this.http.get(this.rootURL+'/ClaimPhotos/getBorders/'+patnerUserID).toPromise()
      .then(res => this.formBorders = res as PartnerBorders[]);;
     }

     getGraphics(patnerUserID)
     {
      return this.http.get(this.rootURL+'/ClaimPhotos/getGraphics/'+patnerUserID).toPromise()
      .then(res => this.formGraphics = res as PartnerGraphics[]);
     }

     Twitter(formData:PartnerWebPhotos )
     {           
        return this.http.post(this.rootURL+'/ClaimPhotos/twitter',formData).toPromise()
        .then(res => this.formData = res as PartnerWebPhotos);;
     }
     twitterPost()
     {   
      // console.log("d")        
        return this.http.get(this.rootURL+'/ClaimPhotos/twitterPost')
     }
     ShareMail(data:EmailShare)
     {
      return this.http.post(this.rootURL+'/ClaimPhotos/Mail',data).toPromise()
     }
     saveBorder(data: CloudinaryData)
     {           
        return this.http.post(this.rootURL+'/ClaimPhotos/SaveImageToCloud',data );
     }

     restoreImage(WebPhotoId)
     {           
        return this.http.get(this.rootURL+'/ClaimPhotos/RestoreImage/'+ WebPhotoId);
     }

     download(patnerUserID)
     {
      return this.http.get(this.rootURL+'/ClaimPhotos/Download/'+patnerUserID).toPromise();
     }
  }
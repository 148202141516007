import { HttpClient, HttpParams} from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { PartnerWebPhotos } from '../ClaimPhotos/partner-webphotos.model';
import { PartnerProductModel } from '../ClaimPhotos/partner-products.model';

@Injectable({
    providedIn: 'root'
  })

export class NonClaimPhotosService {    
    readonly rootURL;
    listNonclaimPhotos : PartnerWebPhotos[];
     listProducts : PartnerProductModel[];
    constructor(private http:HttpClient, private _constant: ConstantsService) {
      this.rootURL=_constant.ConstatntWebAPI;
     }
  
     getPartnerPhotos(patnerUserID) {
      return this.http.get(this.rootURL+'/NonClaimPhotos/'+patnerUserID).toPromise();
      //.then(res => this.listNonclaimPhotos = res as PartnerWebPhotos[]);;
     }
    
     getPartnerProducts(patnerUserID) {
      return this.http.get(this.rootURL+'/NonClaimPhotos/getProducts/'+patnerUserID).toPromise();
      //.then(res => this.listProducts = res as PartnerProductModel[]);;
     }     
  }
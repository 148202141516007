import { Component, ViewChildren, AfterViewInit, QueryList , NgZone, OnDestroy, ViewChild, ViewEncapsulation, OnInit, Output, TemplateRef, HostListener, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
//import { NgScrollbar } from 'ngx-scrollbar';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ClaimPhotosService } from '../services/ClaimPhotos/claim-photos.service';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { PartnerWebPhotos } from '../services/ClaimPhotos/partner-webphotos.model';
import { PartnerProductModel } from '../services/ClaimPhotos/partner-products.model';
import { CartService } from '../services/checkout/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PartnerBorders } from '../services/ClaimPhotos/partner-border.model';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerSupportService } from '../services/CustomerSupport/CustomerSupport.service';
import { ConstantsService } from '../common/constants.service';
import { saveAs } from 'file-saver';
import 'fabric';
import { CloudinaryData } from '../services/ClaimPhotos/cloudinary-data.model';
import { PartnerGraphics } from '../services/ClaimPhotos/partner-graphics.model';

import { EmailShare } from '../services/ClaimPhotos/EmailShare.model';
import { NgForm } from '@angular/forms';
import { debug } from 'util';

declare const fabric: any;

@Component({
  selector: 'app-claimed-photos',
  templateUrl: './claimed-photos.component.html',
  styleUrls: ['./claimed-photos.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class ClaimedPhotosComponent implements OnInit, OnDestroy {

  private canvas: any;
  public test: any;
  public fbkurl: any;
  public saveData: Array<object>;

  public ImageBigThumbPath: string;
  public ThumbnailPath: string;
  public IsVideo: boolean = false;
  public CloudImagePath: string;
  public WebPhotoID: number;
  public CreatedDate: Date;
  public ExpiryDate: Date;
  formDataEmail: EmailShare;
  public ProductID: number;
  public Quantity: number;
  public MaxImageCount: number;
  public isPackage: boolean;
  public isDigital: boolean;
  public qpm:boolean = false;

  public ProductName: string;
  public Description: string;
  public Material: string;
  public UnitPrice: number;
  public ImageView1: string;
  public ImageView2: string;
  public ImageView3: string;

  public Claim: string;
  public NonClaim: string;
  Iwidth:any;
  Iheight:any;

  fileToUpload: File = null;
  fileObj: any;

  productVisible: boolean = false;
  //graphics validations
  public saveGrapchisData: Array<object>;
  isDisableUndoGraphic: boolean = true;
  isDisableSaveGraphic: boolean = true;
  isDisableRemoveGraphic: boolean = true;


  //Borders validations
  public saveDataBorder: Array<object>;
  isDisableUndoBorder: boolean = true;
  isDisableSaveBorder: boolean = true;

  public videoURL: string;
  public HideVideoClassvar: string;
  private sub: any;
  public twit: string;
  public ModalErrorMag:any;
  // @ViewChildren("galleryimg", { read: ElementRef }) galleryimgs: QueryList<ElementRef>;
  @ViewChildren('galleryimg') galleryimgs: QueryList<any>;
  @ViewChild('VideoTemplate', { static: true }) templateRefVideo: TemplateRef<any>;
  @ViewChild('borderRestoreTemplate', { static: true }) templateAlert: TemplateRef<any>;
  @ViewChild('TwitterAlertTemplate', { static: true }) templateTwitter: TemplateRef<any>;
  @ViewChild('photoLoadModal', { static: true }) templatePhotoLoad: TemplateRef<any>;
 
  
 
  public scrollbarOptions = {};

  /********* ngx collapse *********/
  isCollapsedAddPhotos = true;
  defaultbrdload:boolean;
  allfilteredphoto: PartnerWebPhotos[];
  filteredphoto: PartnerWebPhotos[];
  downloadImageUrl: string = '';
  clddownloadImageUrl: string = '';
  selectedIndex: number = 0;
  duplicate_count: number = 0;
  dynamicImg: string = '';
  selectedWebPhotoId: any;
  // canvas,
  canvasOriginalWidth = 800;
  canvasOriginalHeight = 533.19
  canvasWidth = 800;
  canvasHeight = 533.19;
  imgWidth;
  imgHeight;
  bgImage;
  canvasScale = 1;
  public IDimension:string;
  OriginalUrl:any;
  fileNames: object = {};
  borderApplied: string = ''
  listNonclaimPhotos : PartnerWebPhotos[];
  showproductImageCarousel : boolean = true;

  constructor(
    private modalService: BsModalService,
    private ngZone: NgZone, public service: ClaimPhotosService,
    public _cartService: CartService,
    private _router: Router,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    public fileService: CustomerSupportService,
    private _constant: ConstantsService,
    protected _route: ActivatedRoute
  ) {

    if ((localStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
    }


  }

// add by pradeep border and sticker 
  BorderVisible:boolean = false;
  StickerVisible:boolean = false;

  onclickBorder()
  {
  
    // if (this.saveDataBorder.length > 0 || this.saveGrapchisData.length > 0) {
    //   //this.openModal(this.templateAlertSave);
    //   document.getElementById("btnModelOpen").click();
    // }
    this.defaultbrdload = false;
   this.BorderVisible = !this.BorderVisible;
    this.StickerVisible = false;
 
	 let stickereditContent = document.getElementById("stickerEditBtns");
	 let bordereditContent = document.getElementById("borderEditBtnsWRa");

      bordereditContent.style.display = "block";
	  stickereditContent.style.display = "none";
  }
  onclickSticker()
  {
    this.defaultbrdload = false;
  //  if (this.saveDataBorder.length > 0 || this.saveGrapchisData.length > 0) {
  //     //this.openModal(this.templateAlertSave);
  //     document.getElementById("btnModelOpen").click();
  //   }
  
    this.StickerVisible = !this.StickerVisible;
    this.BorderVisible = false; 
	
	 let stickereditContent = document.getElementById("stickerEditBtns");
	 let bordereditContent = document.getElementById("borderEditBtnsWRa");
     stickereditContent.style.display = "block";
	 bordereditContent.style.display = "none";
  }

// end by pradeep


  ngOnInit() {

    this._constant.setTimeout();
    this.defaultbrdload = true;
    this._constant.userInactive.subscribe(() => {
      this._router.navigateByUrl('/welcome');
    });

    // this.sub = this._route.paramMap.subscribe(async params => {

    //   this.twit = params.get('twit');

    //   if (this.twit) {
    //     if (this.twit.includes('success')) {
    //       this.openModal(this.templateTwitter)
    //     }
    //     else {
    //       alert('Error! Twitter post');
    //     }
    //   }
    // });



    ///Session expired///

    /// End of Session expired///
    this.HideVideoClassvar = "";
    this._constant.loading(true);
    this.resetForm();

    this.canvas = new fabric.Canvas('myCanvas',
      {
        hoverCursor: 'pointer',
        // selection: true,
        height: this.canvasHeight,
        width: this.canvasWidth,

      });
       // apply white back color to canvas
    var ctx = this.canvas.getContext("2d");
 
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);


    this.saveDataBorder = new Array<object>();
    this.saveGrapchisData = new Array<object>();

    this.Quantity = 1;
    this.ImageBigThumbPath = "";
    this.OriginalUrl="";
    this.WebPhotoID = 0;
    this.ExpiryDate = null;


    //
    this.selectedImages = 0;


    this.selectedWebPhotos = "";
    this.selectedQnty = 0;
    this.isPrevDisable = true;
    this.isNextDisable = false;
    this.selectedSlide = 0;
    this.productCount = 0;
    this.cartText = "Add To Cart";
    this.cartTextPopup = "Product successfully added!";
    this.selectedProductId = 0;

    if ((localStorage.getItem('PartnerUserId') != null)) {
      if ((localStorage.getItem('TabStatus') == "ClaimActiveClaim")) {
        this.NonClaim = "nav-link disabled";
        this.Claim = "nav-link d-inline-block";
      }
      else if ((localStorage.getItem('TabStatus') == "NonClaimActiveNonClaim")) {
        this.Claim = "nav-link d-inline-block disabled";
        this.NonClaim = "nav-link";
      }
      else {
        this.NonClaim = "nav-link";
        this.Claim = "nav-link d-inline-block";
      }
      this.ModalErrorMag='';
      this.getPartnerClaimedPhotos();
      // this.getNonClaimPhotos();
      this.service.getBorders(localStorage.getItem('PartnerUserId'));
      this.service.getGraphics(localStorage.getItem('PartnerUserId'));
    }
    else {
      this._router.navigateByUrl('/welcome');
    }
    this.setCanvasZoom();

    this.setCanvasSize({ height: this.canvasHeight, width: this.canvasWidth });

      this.sub = this._route.paramMap.subscribe(async params => {

      this.twit = params.get('twit');

      if (this.twit) {
        if (this.twit.includes('success')) {
          this.openModal(this.templateTwitter)
        }
        else {
          if(this.twit){
            this.service.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
            .then(res => {
              // console.log(this.galleryimgs.toArray());
              // this.galleryimgs.forEach(e => console.log(e.nativeElement.id));
              this.galleryimgs.forEach(e => 
                {
                  if(e.nativeElement.firstElementChild.id == this.twit){
                    e.nativeElement.click()
                    // console.log("Clicked")
                    this.qpm = true
                    // alert("box")
                  }
                }
                );
              // this.galleryimgs.forEach((div: ElementRef) => console.log(div.nativeElement));
              // this.allfilteredphoto = res as PartnerWebPhotos[];
              // this.filteredphoto = this.allfilteredphoto.filter((obj) => {
              //   // console.log("obj",obj)
              //   return obj.WebPhotoId === parseInt(this.twit);
              // });
              // var indexe = this.allfilteredphoto.findIndex(x => x.WebPhotoId === parseInt(this.twit));
              // console.log("beforeresult",this.filteredphoto)    
              // this.populateForm(this.allfilteredphoto[indexe])
              // console.log("result",this.filteredphoto)
              // this.qpm = true;
              // this.resizeCanvas_org()
              
            })

          }
          else{
          alert('Error! Twitter post');
        }
        }
      }
    });
    this._constant.loading(false);
  }
  ngAfterViewInit(){
    
  }

  setCanvasSize(canvasSizeObject) {
    this.canvas.setWidth(canvasSizeObject.width);
    this.canvas.setHeight(canvasSizeObject.height);
  }

  setCanvasZoom() {
    this.canvasWidth = this.canvasOriginalWidth * this.canvasScale;
    this.canvasHeight = this.canvasOriginalHeight * this.canvasScale;

    this.canvas.setWidth(this.canvasWidth);
    this.canvas.setHeight(this.canvasHeight);
  }

  setCanvasBackgroundImageUrl(url) {
    // console.log('setCanvasBackgroundImageUrl url ', url);
    if (url && url.length > 0) {
      fabric.Image.fromURL(this.ImageBigThumbPath, (img) => {
        this.bgImage = img,
        this.scaleAndPositionImage();
      }, { crossOrigin: 'Anonymous' });
    } else {
      this.canvas.backgroundImage = 0;
      this.canvas.setBackgroundImage('', this.canvas.renderAll.bind(this.canvas));

      this.canvas.renderAll();
    }
  }

  dimensions = {
    top: 0,
    left: 0,
    scaleFactor: 0,
    width: 0,
    height: 0
  };

  scaleAndPositionImage() {
    // console.log('scale and pos');
    this.setCanvasZoom();
    var canvasAspect = this.canvasWidth / this.canvasHeight;
    var imgAspect = this.bgImage.width / this.bgImage.height;
    var left = 0, top = 0, scaleFactor = 1;

    if (this.bgImage.width > this.bgImage.height) {
      scaleFactor = (this.canvasWidth / this.bgImage.width);
      left = 0;
      top = -((this.bgImage.height * scaleFactor) - this.canvasHeight) / 2;
    } else {
      scaleFactor = this.canvasHeight / this.bgImage.height;
      top = 0;
      left = -((this.bgImage.width * scaleFactor) - this.canvasWidth) / 2;

    }
    this.dimensions = {
      top,
      left,
      scaleFactor,
      width: this.bgImage.width,
      height: this.bgImage.height
    }
    // console.log("setting bg", scaleFactor);
    // console.log("this.bgImage ", this.bgImage);
    this.canvas.setBackgroundImage(this.bgImage, this.canvas.renderAll.bind(this.canvas), {
      top: top,
      left: left,
      originX: 'left',
      originY: 'top',
      scaleX: scaleFactor,
      scaleY: scaleFactor
    }, { crossOrigin: 'Anonymous' });
    this.canvas.renderAll();

  }
  public packageName: string;
  public packageDesc: string;
  public productImage: string;
  public cartText: string;
  public cartTextPopup: string;
  public selectedProductId: number;
  public selectedMaxImageCount: number;

  public selectedImages: number;
  public selectedCartItemIdId: number;
  public selectedWebPhotos: string;

  public productCount: number;

  public selectedSlide: number;
  public selectedQnty: number;
  public isNextDisable: boolean;
  public isPrevDisable: boolean;
  //new
  public productList: PartnerProductModel[];

  // imageSelectEvent(image: PartnerWebPhotos) {


  //   let element = document.getElementById('wb_' + image.WebPhotoId);

  //   if (this.selectedImages == this.selectedMaxImageCount && element.className != 'block selected') {
  //     if (this.selectedMaxImageCount == 0) {
  //       this.toastr.warning('Online all digital product does not require image selection');
  //       return;
  //     }
  //     this.toastr.warning('No. of maximum images are already selected. If you want another one, please deselect selected image(s) and proceed.');
  //     return;
  //   }

  //   if (element.className == 'block selected') {

  //     this.selectedWebPhotos = this.selectedWebPhotos.replace(image.WebPhotoId + ',', '')

  //     this.selectedImages = this.selectedImages - 1;
  //     element.className = 'block not-selected';

  //   } else {
  //     this.selectedWebPhotos = this.selectedWebPhotos + image.WebPhotoId + ',';

  //     this.selectedImages = this.selectedImages + 1;
  //     element.className = 'block selected';
  //   }


  //   if (this.selectedMaxImageCount == this.selectedImages) {
  //     if (document.getElementById('applyButton')) {
  //       document.getElementById('applyButton').removeAttribute('disabled');
  //     }

  //     if (!this.isPackage) {
  //       if (document.getElementById('addCartButton')) {
  //         document.getElementById('addCartButton').removeAttribute('disabled');
  //       }
  //     }

  //     if (this.cartText == "Update Cart") {
  //       if (document.getElementById('addCartButton')) {
  //         document.getElementById('addCartButton').removeAttribute('disabled');
  //       }
  //     }


  //   }
  //   else {
  //     if (document.getElementById('applyButton')) {
  //       document.getElementById('applyButton').setAttribute('disabled', 'true');
  //     }
  //     if (document.getElementById('addCartButton')) {
  //       document.getElementById('addCartButton').setAttribute('disabled', 'true');
  //     }


  //   }

  // }
  imageSelectEvent(image: PartnerWebPhotos) {


    let element = document.getElementById('wb_' + image.WebPhotoId);

    if (this.selectedImages == this.selectedMaxImageCount && element.className != 'block selected') {
      if (this.selectedMaxImageCount == 0) {
        this.toastr.warning('Online all digital product does not require image selection');
        return;
      }
      this.toastr.warning('No. of maximum images are already selected. If you want another one, please deselect selected image(s) and proceed.', '', {
      });
      return;
    }

    if (element.className == 'block selected') {

      this.selectedWebPhotos = this.selectedWebPhotos.replace(image.WebPhotoId + ',', '')

      this.selectedImages = this.selectedImages - 1;
      element.className = 'block not-selected';

    } else {
      this.selectedWebPhotos = this.selectedWebPhotos + image.WebPhotoId + ',';

      this.selectedImages = this.selectedImages + 1;
      element.className = 'block selected';
    }
    if (this.selectedMaxImageCount == 9999 && this.selectedImages!=0) {
      if (document.getElementById('applyButton')) {
        document.getElementById('applyButton').removeAttribute('disabled');
        return;
      }
      if (document.getElementById('addCartButton')) {
        document.getElementById('addCartButton').removeAttribute('disabled');
        return;
      }
    }

    if (this.selectedMaxImageCount == this.selectedImages) {
      if (document.getElementById('applyButton')) {
        document.getElementById('applyButton').removeAttribute('disabled');
      }

      if (!this.isPackage) {
        if (document.getElementById('addCartButton')) {
          document.getElementById('addCartButton').removeAttribute('disabled');
        }
      }

      if (this.cartText == "Update Cart") {
        if (document.getElementById('addCartButton')) {
          document.getElementById('addCartButton').removeAttribute('disabled');
        }
      }


    }
    else {
      if (document.getElementById('applyButton')) {
        document.getElementById('applyButton').setAttribute('disabled', 'true');
      }
      if (document.getElementById('addCartButton')) {
        document.getElementById('addCartButton').setAttribute('disabled', 'true');
      }


    }

  }

  viewCart() {
    this._router.navigateByUrl('/cart');
  }

  loaderHide() {
    this._constant.loading(false);
  }
  colseModal(){
    document.getElementById("myModal").style.display = "none";
  }

  // addTocartFromScreen() {
  //   if(this.selectedProduct['CategoryId'] == 3 || this.selectedProduct['CategoryId'] == 5){
  //     this.ModalErrorMag = "Digital products can only be purchased from Unpurchased Photos & Videos tab"
  //     document.getElementById("myModal").style.display = "block";
  //     // this.toastr.warning('Digital products can only be purchased from Unpurchased Photos & Videos tab');
  //       return;
  //     }else if(this.selectedProduct['CategoryId'] == 6){
  //       this.ModalErrorMag = "Digital products can only be purchased from Unpurchased Photos & Videos tab"
  //       document.getElementById("myModal").style.display = "block";
  //       // this.toastr.warning('Digital products can only be purchased from Unpurchased Photos & Videos tab');
  //         return;
  //       }
  //       else if(this.selectedProduct['CategoryId'] == 1 || this.selectedProduct['CategoryId'] == 7){
  //         let listNonclaimPhoto = [],listclaimPhoto = [];
  //         listNonclaimPhoto = this.listNonclaimPhotos.filter(element =>{
  //           if(!element.isVideo){
  //             return true;
  //           }
  //         })
  //         listclaimPhoto = this.service.list.filter(element =>{
  //           if(!element.isVideo){
  //             return true;
  //           }
  //         })
  //         if(listNonclaimPhoto.length == 0 &&  listclaimPhoto.length == 0){
  //           this.ModalErrorMag = "You don't have photo for this product"
  //           document.getElementById("myModal").style.display = "block";
  //             return;
  //         }
  //       }
  //   this._constant.loading(true);
  //   this._cartService.postAddCart(localStorage.getItem('PartnerUserId'), this.ProductID, this.Quantity).then
  //     (
  //       (res) => {

  //         // sessionStorage.setItem("ProductID",this.ProductID.toString());
  //         // sessionStorage.setItem("CartItemID",res.toString());
  //         // sessionStorage.setItem("PhotoPage","claim");
  //         this.selectedCartItemIdId = res as number;
  //         //this.toastr.success('Product added to cart');
  //         this._cartService.CheckCartCount(localStorage.getItem('PartnerUserId'));
  //         //this._router.navigateByUrl('/cart');
  //         this.getAllProductsCart();
  //         //this._constant.loading(false);
  //       },
  //       (err: HttpErrorResponse) => {
  //         if (err.status == 1) {
  //           this.toastr.warning('Quanity more than 10 not allowed, for single product');
  //         }
  //         else if (err.status == 2) {
  //           this.toastr.warning('Purchase of more than one is not allowed for this product');
  //         }
  //         else {
  //           this.toastr.error('Error! Adding Product');
  //         }
  //         this._constant.loading(false);
  //         console.log(err);

  //       }
  //     );
  // }

  getAllProductsCart() {
    this._constant.loading(true);

    this.cartText = "Add To Cart";
    this.cartTextPopup = "Product successfully added!";
    this._cartService.getPartnerCartProducts(
      localStorage.getItem('PartnerUserId'), this.ProductID).then
      (
        (res) => {

          this.productList = res as PartnerProductModel[];
          this.productCount = 0;

          this.productList.forEach(obj => {
            this.productCount = this.productCount + 1;
          });

          this.selectedProductId = this.productList[0].ProductId;
          //this.selectedCartItemIdId=+sessionStorage.getItem('CartItemID');
          this.selectedMaxImageCount = this.productList[0].MaxImageCount;
          this.isDigital = this.productList[0].RemoveWatermark;

          if (this.productCount > 1) {
            this.isPackage = true;
          }
          else {
            this.isPackage = false;
          }
          this.packageName = this.productList[0].PackageName;
          this.packageDesc = this.productList[0].PackageDescription;
          this.productImage = this.productList[0].ImageView1;

          //sessionStorage.setItem("ProductID",null);

          // this.getAllPhotosCart();


        },
        (err) => {
          this._constant.loading(false);
        }
      );


  }

  expPhotos() {
    this._constant.loading(false);
    // if((sessionStorage.getItem('PhotoPage') == 'claim')) {
    //   this._router.navigateByUrl('/claimed-photos');
    // }
    // else if((sessionStorage.getItem('PhotoPage') == 'non-claim')) {
    //   this._router.navigateByUrl('/nonclaimed-photos');
    // }
    // else {
    //   this._constant.CheckUserImages(localStorage.getItem('PartnerUserId'));
    // }

  }

  // getAllPhotosCart() {


  //   this._constant.loading(true);

  //   this._cartService.getPartnerPhotosforSelection(
  //     localStorage.getItem('PartnerUserId'), this.selectedCartItemIdId, this.selectedProductId).then
  //     (
  //       (res: any) => {
  //         console.log('before selectedProduct ', this.selectedProduct);
  //         let category_id = this.selectedProduct['CategoryId'];
  //         res.cartPhotos = res.cartPhotos.filter(element => {
  //           if (category_id == 3) {
  //             // return element.IsPaidImage && !element.isVideo;
  //             return  !element.isVideo;
  //           }else
  //           if (category_id == 1 || category_id == 7) {
  //             // return element.IsPaidImage && !element.isVideo;
  //             return  !element.isVideo;
  //           } else if (category_id == 6 ) {
  //             // return element.IsPaidImage && element.isVideo;
  //             // return element.isVideo;
  //             return !element.IsPaidImage && element.isVideo;
  //           } else {
  //             return true;
  //           }
  //         });
  //         console.log('after res ', res.cartPhotos);
  //         if(res.cartPhotos.length == 0){
  //           this.ModalErrorMag = "You don't have photo for this product"
  //           document.getElementById("myModal").style.display = "block";
  //         }
  //         // this._cartService.list = res;
  //         this.selectedWebPhotos = this._cartService.list.selectedWebPhotos;
  //         this.selectedImages = 0;
  //         if (this.selectedWebPhotos) {
  //           var array = this.selectedWebPhotos.split(",");
  //           this.selectedImages = array.length - 1;
  //         }
  //         if (!this.isPackage) {
  //           this._cartService.list.isCartDisable = !this._cartService.list.isSaveSelection;
  //         }

  //         // if (this._cartService.list.isCartDisable) {
  //         //   if (document.getElementById('addCartButton')) {
  //         //     document.getElementById('addCartButton').setAttribute('disabled', 'true');
  //         //   }
  //         // }
  //         // else {
  //         //   if (document.getElementById('addCartButton')) {
  //         //     document.getElementById('addCartButton').removeAttribute('disabled');
  //         //   }
  //         // }

  //         if (!this._cartService.list.isSaveSelection) {
  //           if (document.getElementById('applyButton')) {
  //             document.getElementById('applyButton').setAttribute('disabled', 'true');
  //           }
  //         }
  //         else {
  //           if (document.getElementById('applyButton')) {
  //             document.getElementById('applyButton').removeAttribute('disabled');
  //           }
  //         }

  //         this.isCollapsed = false;
  //         sessionStorage.setItem("CartItemID", null);
  //         this.isCollapsedAddPhotos = false;
  //       //   if(this.selectedMaxImageCount==9999 ||this.selectedMaxImageCount== 0 ){
  //       //   let $this=this;
  //       //   setTimeout(function(){

  //       //     res.cartPhotos.forEach(element => {
  //       //       $this.SelectAllVideoPhoto(element);
  //       //     })
  //       //   }, 300);
  //       // }
  //       if(category_id == 1 || category_id == 7){
  //                     if(res.cartPhotos.length>0){
  //                       if(this.selectedMaxImageCount==9999 || this.selectedMaxImageCount == 0){
  //                         let $this=this;
  //                         setTimeout(function(){
  //                           res.cartPhotos.forEach(element => {
  //                             $this.SelectAllVideoPhoto(element);
  //                           });
  //                           $this.addToCart();
  //                         }, 300);
  //                       }else{
  //                       let imageCount = this.selectedMaxImageCount;
  //                       for(let i=0;i<imageCount;i++){
  //                         let $this=this;
  //                         setTimeout(function(){
  //                             $this.SelectAllVideoPhoto(res.cartPhotos[i]);
  //                         }, 300);
  //                     }
  //                   }
  //                     }
  //                   }
  //                 else{
  //                   let $this=this;
  //                   setTimeout(function(){
  //                     $this.disableButton();
  //                 }, 300);
  //                 }
  //         this._constant.loading(false);
  //       },
  //       (err) => {
  //         this._constant.loading(false);
  //       }
  //     );


  // }
  disableButton(){

        if (document.getElementById('applyButton')) {
          document.getElementById('applyButton').setAttribute('disabled', 'true');
        }
        if (document.getElementById('addCartButton')) {
          document.getElementById('addCartButton').setAttribute('disabled', 'true');
        }
      }

  SelectAllVideoPhoto(image: PartnerWebPhotos){
    let element = document.getElementById('wb_' + image.WebPhotoId);
    this.selectedWebPhotos = this.selectedWebPhotos + image.WebPhotoId + ',';
    this.selectedImages = this.selectedImages + 1;
    element.className = 'block selected';
    if ( (this.selectedMaxImageCount == this.selectedImages) || this.selectedMaxImageCount == 9999) {
              if (document.getElementById('applyButton')) {
                document.getElementById('applyButton').removeAttribute('disabled');
              }

              if(!this.isPackage)
                {
                  if (document.getElementById('addCartButton')) {
                    document.getElementById('addCartButton').removeAttribute('disabled');
                  }
                }

                if(this.cartText=="Update Cart")
                {
                  if (document.getElementById('addCartButton')) {
                    document.getElementById('addCartButton').removeAttribute('disabled');
                  }
                }


            } else {
              if (document.getElementById('applyButton')) {
                document.getElementById('applyButton').setAttribute('disabled', 'true');
              }
              if (document.getElementById('addCartButton')) {
                document.getElementById('addCartButton').setAttribute('disabled', 'true');
              }


            }
    // if (element.className == 'block selected') {

    //   this.selectedWebPhotos = this.selectedWebPhotos.replace(image.WebPhotoId + ',', '')

    //   this.selectedImages = this.selectedImages - 1;
    //   element.className = 'block not-selected';

    // } else {
    //   this.selectedWebPhotos = this.selectedWebPhotos + image.WebPhotoId + ',';

    //   this.selectedImages = this.selectedImages + 1;
    //   element.className = 'block selected';
    // }

    // if (document.getElementById('applyButton')) {
    //   document.getElementById('applyButton').removeAttribute('disabled');
    // }

    // if (!this.isPackage) {
    //   if (document.getElementById('addCartButton')) {
    //     document.getElementById('addCartButton').removeAttribute('disabled');
    //   }
    // }
  }

  clearSelection() {
    if (this.selectedWebPhotos) {
      if (this.selectedWebPhotos != "null") {
        var array = this.selectedWebPhotos.split(",");

        array.forEach(function (value) {
          let element = document.getElementById('wb_' + value)
          if (element != null) {
            element.className = 'block not-selected';
          }
        });
      }
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    if (document.getElementById('addCartButton')) {
      document.getElementById('addCartButton').setAttribute('disabled', 'true');
    }

    this.selectedImages = 0;
    this.selectedWebPhotos = "";

  }


  // addToCart(cartConfirmModal?) {
  //   this._constant.loading(true);
  //   if (this.isPackage) {
  //     this._cartService.putPackage(localStorage.getItem('PartnerUserId'))
  //       .subscribe(
  //        async (res) => {


  //         await  this._cartService.CheckCartCount(localStorage.getItem('PartnerUserId'));
  //         this._constant.loading(false);
  //           if(cartConfirmModal){
  //             cartConfirmModal.show();
  //           }
  //         },
  //         (err: HttpErrorResponse) => {
  //           this._constant.loading(false);
  //           console.log(err);

  //         }
  //       );
  //   }
  //   else {
  //     this._cartService.putWebPhotos(this.selectedCartItemIdId, this.selectedWebPhotos,
  //       this.selectedProductId, localStorage.getItem('PartnerUserId'))
  //       .subscribe(
  //        async (res) => {
  //           console.log("done");
  //          await this._cartService.CheckCartCount(localStorage.getItem('PartnerUserId'));
  //           this._constant.loading(false);
  //           // this._constant.loading(false);
  //           // let category_id = this.selectedProduct['CategoryId'];
  //           let category_id = ''
  //           if(category_id == 7 || category_id == 1){
  //             if(this.selectedMaxImageCount==0 || this.selectedMaxImageCount==9999){
  //               this.viewCart();
  //               return;
  //             }}
  //           if(cartConfirmModal){
  //             cartConfirmModal.show();
  //           }
  //         },
  //         (err: HttpErrorResponse) => {
  //           this._constant.loading(false);
  //           console.log(err);

  //         }
  //       );
  //   }
  // }

  prevSlide() {

    this._constant.loading(true);
    if (this.selectedImages != this.selectedMaxImageCount) {
      this.toastr.warning('select ' + (this.selectedMaxImageCount - this.selectedImages) + ' Photos');
      this._constant.loading(false);
      return;
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    this._cartService.putWebPhotos(this.selectedCartItemIdId, this.selectedWebPhotos,
      this.selectedProductId, localStorage.getItem('PartnerUserId'))
      .subscribe(
        (res) => {

          if (this.selectedImages == this.selectedMaxImageCount) {
            document.getElementById("dotID_" + this.selectedSlide).classList.remove("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.remove("active");

            if (this.selectedSlide == 0) {
              this.selectedSlide = this.productCount - 1;
              this.selectedCartItemIdId = this.selectedCartItemIdId + (this.productCount - 1);
            }
            else {
              this.selectedSlide = this.selectedSlide - 1;
              this.selectedCartItemIdId = this.selectedCartItemIdId - 1;
            }

            document.getElementById("dotID_" + this.selectedSlide).classList.add("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.add("active");

            this.selectedProductId = this.productList[this.selectedSlide].ProductId;
            this.selectedMaxImageCount = this.productList[this.selectedSlide].MaxImageCount;
            this.productImage = this.productList[this.selectedSlide].ImageView1;


            // this.getAllPhotosCart();

          }


        },
        (err: HttpErrorResponse) => {

          console.log(err);

        }
      );


  }

  nextSlide() {

    this._constant.loading(true);
    if (this.selectedImages != this.selectedMaxImageCount) {
      this.toastr.warning('select ' + (this.selectedMaxImageCount - this.selectedImages) + ' Photos');
      this._constant.loading(false);
      return;
    }

    if (document.getElementById('applyButton')) {
      document.getElementById('applyButton').setAttribute('disabled', 'true');
    }
    this._cartService.putWebPhotos(this.selectedCartItemIdId, this.selectedWebPhotos,
      this.selectedProductId, localStorage.getItem('PartnerUserId'))
      .subscribe(
        (res) => {

          if (this.selectedImages == this.selectedMaxImageCount) {
            document.getElementById("dotID_" + this.selectedSlide).classList.remove("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.remove("active");
            if (this.selectedSlide == (this.productCount - 1)) {
              this.selectedSlide = 0;
              this.selectedCartItemIdId = this.selectedCartItemIdId - (this.productCount - 1);
            }
            else {
              this.selectedSlide = this.selectedSlide + 1;
              this.selectedCartItemIdId = this.selectedCartItemIdId + 1;
            }
            document.getElementById("dotID_" + this.selectedSlide).classList.add("active");
            document.getElementById("dotdID_" + this.selectedSlide).classList.add("active");

            this.selectedProductId = this.productList[this.selectedSlide].ProductId;
            this.selectedMaxImageCount = this.productList[this.selectedSlide].MaxImageCount;
            this.productImage = this.productList[this.selectedSlide].ImageView1;


            // this.getAllPhotosCart();

          }


        },
        (err: HttpErrorResponse) => {

          console.log(err);

        }
      );

  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.resetForm();
    this.service.formDataEmail = {
      Email: '',
      Message: '',
      Subject: '',
      CloudImageUrl: '',
      // CloudImageUrl: '',
      // OriginalUrl:''
    }
  }

  //to save Border
  saveCanvasImageBorder() {
    this._constant.loading(true);

    this.isDisableUndoGraphic = true;
    this.isDisableSaveGraphic = true;
    this.isDisableRemoveGraphic = true;

    this.isDisableSaveBorder = true;
    this.isDisableUndoBorder = true;


    while (this.saveGrapchisData.length > 0) {
      this.saveGrapchisData.pop();
    }

    while (this.saveDataBorder.length > 0) {
      this.saveDataBorder.pop();
    }
    this.buildDynamicURLborder();
    //

    var jsonCanvas = JSON.stringify(this.canvas);
    var nnwew = JSON.parse(jsonCanvas);
    // console.info(JSON.stringify(this.canvas));

  }

  //to save Border-
  buildDynamicURLborder() {

    var CloudUrlBuilder;
    var multiplier = 3;
    var serverImagePath;


    // serverImagePath = "https://res.cloudinary.com/www-commdel-net/image/upload/";


    // var frame_image_height = (this.canvas.height * multiplier);
    // var frame_image_width = (this.canvas.width * multiplier);
    // var _borderFlag = true;

    // CloudUrlBuilder = serverImagePath + "c_scale,h_" + frame_image_height + ",w_" + frame_image_width;
    // var Order_src = this.ImageBigThumbPath;
    // var OrderImage_Url = Order_src.replace(serverImagePath, "");

    // //var OrderImage_publicId = OrderImage_Url.replace(/\//g, ":");
    // var OrderImage_publicId_cloud = OrderImage_Url.substring(0, OrderImage_Url.length - 4).replace(/\//g, ":");
    // OrderImage_publicId_cloud = OrderImage_publicId_cloud.substring(OrderImage_publicId_cloud.indexOf("OrderImages:"), (OrderImage_publicId_cloud.length));

    // if (OrderImage_publicId_cloud.includes('EditedImage:')) {
    //   OrderImage_publicId_cloud = OrderImage_publicId_cloud.replace('EditedImage:', '');
    // }
    // OrderImage_publicId_cloud = OrderImage_publicId_cloud.replace(/:/g, "/");

    // var OrderImage_publicId = OrderImage_Url.substring(0, OrderImage_Url.length - 4).replace(/\//g, ":");
    // // OrderImage_publicId=OrderImage_publicId.substring(OrderImage_publicId.indexOf("OrderImages:"),(OrderImage_publicId.length));

    // // if (OrderImage_publicId.includes('EditedImage:')) {
    // //   OrderImage_publicId = OrderImage_publicId.replace('EditedImage:', '');
    // // }
    // //var OrderImage_publicId_cloud=OrderImage_publicId.replace(/:/g, "/");
    // console.info("order public id=" + OrderImage_publicId);



    // var OrderImage_x = parseInt('0') * multiplier;
    // var OrderImage_y = parseInt('0') * multiplier;

    // var minusOrderImage_x = - parseInt('0') * multiplier;
    // var minusOrderImage_y = - parseInt('0') * multiplier;

    // var jsonCanvas = JSON.stringify(this.canvas);
    // var newCanvas = JSON.parse(jsonCanvas);



    // var frame_src = newCanvas.objects[0].src;
    // var frame_RawimageUrl = frame_src.replace(serverImagePath, "");
    // var frame_imageUrl = frame_RawimageUrl.replace(frame_RawimageUrl.split('/')[0], "");
    // var frame_imagepublicId = frame_imageUrl.substring(0, frame_imageUrl.length - 4);

    // console.info("frame public id=" + frame_imagepublicId);

    // if (_borderFlag) {
    //   CloudUrlBuilder = CloudUrlBuilder + "/c_scale,g_north_west,h_" + frame_image_height + ",u_" + OrderImage_publicId + ",w_" + frame_image_width + ",x_" + OrderImage_x + ",y_" + OrderImage_y;
    // }

    // CloudUrlBuilder = CloudUrlBuilder + "/c_crop,h_" + frame_image_height + ",w_" + frame_image_width + ",x_" + minusOrderImage_x + ",y_" + minusOrderImage_y;
    // CloudUrlBuilder = CloudUrlBuilder + "/" + frame_imagepublicId;
    // CloudUrlBuilder = CloudUrlBuilder + ".jpg";



    // console.info(CloudUrlBuilder);

    // console.log(this.dimensions.top,'Hellodidd')

    var data = new CloudinaryData();
    // data.DynamicURL = CloudUrlBuilder;
    // data.DynamicURL = this.dynamicImg;
    // console.log('build dynamic url ', this.canvas.toDataURL())
    data.DynamicURL = this.canvas.toDataURL({
      format: "jpeg",
      multiplier: 3,
      top: this.dimensions.top = 0,
      left: this.dimensions.left,
      width: this.dimensions.width * this.dimensions.scaleFactor
    }).replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    data.WebPhotoId = this.WebPhotoID;
    data.PublicID = this.downloadImageUrl;

    // console.log('build dynamic url ', this.canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ""));
    // this.loaderHide();
    // return false;

    this.service.saveBorder(data).subscribe(
      (resData: any) => {
        setTimeout(() => {
          this.service.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
            .then(res => {
              // console.log('resData ', resData);
              this.service.list = [];
              this.service.list = res as PartnerWebPhotos[]

              if (this.service.list && !this.service.list.length) {
                this._router.navigateByUrl('/nonclaimed-photos');
              }

              this.ImageBigThumbPath = this.service.list[this.selectedIndex].MinifiedFilePath;
              this.OriginalUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.ThumbnailPath = this.service.list[this.selectedIndex].ThumbnailFilePath;
              this.Iwidth=this.service.list[this.selectedIndex].Width;
              this.Iheight=this.service.list[this.selectedIndex].Height;
              this.IDimension=this.service.list[this.selectedIndex].ThumbnailDimension;
              this.CloudImagePath = this.service.list[this.selectedIndex].CloudImageUrl;
              this.IsVideo = this.service.list[this.selectedIndex].isVideo;
              this.downloadImageUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.clddownloadImageUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.WebPhotoID = this.service.list[this.selectedIndex].WebPhotoId;
              this.CreatedDate = this.service.list[this.selectedIndex].CreatedDate;
              this.ExpiryDate = this.service.list[this.selectedIndex].ExpiryDate;
              // this.setCanvasImage();
              this.toastr.success('Photo saved successfully!');
              this._constant.loading(false);

            }, () => {
              location.reload();
            })
        }, 5000);

      },
      (err: HttpErrorResponse) => {
        this._constant.loading(false);
        this.toastr.error('error=border saving');

        console.log(err);
      }
    );


  }

  //to save Graphic
  saveCanvasImageGraphic() {



    this._constant.loading(true);
    this.isDisableUndoGraphic = true;
    this.isDisableSaveGraphic = true;
    this.isDisableRemoveGraphic = true;

    this.isDisableSaveBorder = true;
    this.isDisableUndoBorder = true;


    while (this.saveGrapchisData.length > 0) {
      this.saveGrapchisData.pop();
    }

    while (this.saveDataBorder.length > 0) {
      this.saveDataBorder.pop();
    }
    this.buildDynamicURLGraphic();
    //

    var jsonCanvas = JSON.stringify(this.canvas);
    var nnwew = JSON.parse(jsonCanvas);
    // console.info(JSON.stringify(this.canvas));

  }

  //to save Graphic-
  buildDynamicURLGraphic() {



    var CloudUrlBuilder;
    var multiplier = 3;
    var serverImagePath;


    serverImagePath = "https://res.cloudinary.com/www-commdel-net/image/upload/";


    var frame_image_height = (this.canvas.height * multiplier);
    var frame_image_width = (this.canvas.width * multiplier);
    var _borderFlag = false;

    CloudUrlBuilder = serverImagePath + "c_scale,h_" + frame_image_height + ",w_" + frame_image_width;
    var Order_src = this.ImageBigThumbPath;
    var OrderImage_Url = Order_src.replace(serverImagePath, "");

    // //var OrderImage_publicId = OrderImage_Url.replace(/\//g, ":");
    // // var OrderImage_publicId = OrderImage_Url.substring(0, OrderImage_Url.length - 4).replace(/\//g, ":");
    // var OrderImage_publicId = OrderImage_Url.substring(0, OrderImage_Url.length - 4);

    //var OrderImage_publicId = OrderImage_Url.replace(/\//g, ":");
    var OrderImage_publicId_cloud = OrderImage_Url.substring(0, OrderImage_Url.length - 4);
    OrderImage_publicId_cloud = OrderImage_publicId_cloud.substring(OrderImage_publicId_cloud.indexOf("OrderImages/"), (OrderImage_publicId_cloud.length));

    if (OrderImage_publicId_cloud.includes('EditedImage/')) {
      OrderImage_publicId_cloud = OrderImage_publicId_cloud.replace('EditedImage/', '');
    }
    //var OrderImage_publicId_cloud=OrderImage_publicId.replace(/:/g, "/");
    var OrderImage_publicId = Order_src.replace(serverImagePath, "");
    OrderImage_publicId = OrderImage_Url.substring(0, OrderImage_Url.length - 4);
    // if (OrderImage_publicId_cloud.includes('EditedImage/')) {
    //   OrderImage_publicId_cloud = OrderImage_publicId_cloud.replace('EditedImage/', '');
    // }
    OrderImage_publicId = OrderImage_publicId.replace(/:/g, "/");

    // console.info("order public id=" + OrderImage_publicId);
    // console.info("order public id cloud=" + OrderImage_publicId_cloud);

    var OrderImage_x = parseInt('0') * multiplier;
    var OrderImage_y = parseInt('0') * multiplier;

    var minusOrderImage_x = - parseInt('0') * multiplier;
    var minusOrderImage_y = - parseInt('0') * multiplier;

    var jsonCanvas = JSON.stringify(this.canvas);
    var newCanvas = JSON.parse(jsonCanvas);



    // var frame_src = newCanvas.objects[0].src;
    // var frame_RawimageUrl = frame_src.replace(serverImagePath, "");
    // var frame_imageUrl = frame_RawimageUrl.replace(frame_RawimageUrl.split('/')[0], "");
    // var frame_imagepublicId = frame_imageUrl.substring(0, frame_imageUrl.length - 4);
    for (let i = 0; i < newCanvas.objects.length; i++) {
      var Sticker_src = newCanvas.objects[i].src;

      var Sticker_imageUrl = Sticker_src.replace(serverImagePath, "");
      //var Sticker_imageUrl = Sticker_src.replace(serverImagePath + "w_100,h_102,c_scale/", "");
      var Sticker_publicId = Sticker_imageUrl.substring(0, Sticker_imageUrl.length - 4).replace(/\//g, ":");
      var Sticker_height = parseInt(newCanvas.objects[i].height) * parseFloat(newCanvas.objects[i].scaleX) * multiplier;
      var Sticker_width = parseInt(newCanvas.objects[i].width) * parseFloat(newCanvas.objects[i].scaleY) * multiplier;

      //var Sticker_height = parseInt(newCanvas.objects[i].height) * parseFloat(newCanvas.objects[i].scaleX) ;
      //var Sticker_width = parseInt(newCanvas.objects[i].width) * parseFloat(newCanvas.objects[i].scaleY) ;

      var Sticker_x = parseInt(newCanvas.objects[i].left) * multiplier;
      var Sticker_y = parseInt(newCanvas.objects[i].top) * multiplier;

      //var Sticker_x = parseInt(newCanvas.objects[i].left) ;
      //var Sticker_y = parseInt(newCanvas.objects[i].top) ;

      Sticker_height = parseInt('' + Sticker_height);
      Sticker_width = parseInt('' + Sticker_width);

      CloudUrlBuilder = CloudUrlBuilder + "/c_scale,g_north_west,h_" + Sticker_height + ",l_" + Sticker_publicId + ",w_" + Sticker_width + ",x_" + (Sticker_x + minusOrderImage_x) + ",y_" + (Sticker_y + minusOrderImage_y);
    }
    if (_borderFlag) {
      CloudUrlBuilder = CloudUrlBuilder + "/c_scale,g_north_west,h_" + frame_image_height + ",u_" + OrderImage_publicId + ",w_" + frame_image_width + ",x_" + OrderImage_x + ",y_" + OrderImage_y;
    }

    CloudUrlBuilder = CloudUrlBuilder + "/c_crop,h_" + frame_image_height + ",w_" + frame_image_width + ",x_" + minusOrderImage_x + ",y_" + minusOrderImage_y;
    CloudUrlBuilder = CloudUrlBuilder + "/" + OrderImage_publicId;
    CloudUrlBuilder = CloudUrlBuilder + ".jpg";



    // console.info(CloudUrlBuilder);


    var data = new CloudinaryData();
    data.DynamicURL = CloudUrlBuilder;
    data.DynamicURL = this.canvas.toDataURL({
      multiplier: 3,
    }).replace(/^data:image\/(png|jpg);base64,/, "");
    data.WebPhotoId = this.WebPhotoID;
    data.PublicID = OrderImage_publicId_cloud;
    data.PublicID = this.downloadImageUrl;




    this.service.saveBorder(data).subscribe(
      (res) => {
        setTimeout(() => {
          this.service.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
            .then(res => {
              this.service.list = [];
              this.service.list = res as PartnerWebPhotos[]

              if (this.service.list && !this.service.list.length) {
                this._router.navigateByUrl('/nonclaimed-photos');
              }
              this.ImageBigThumbPath = this.service.list[this.selectedIndex].MinifiedFilePath;
              this.OriginalUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.ThumbnailPath = this.service.list[this.selectedIndex].ThumbnailFilePath;
              this.Iwidth=this.service.list[this.selectedIndex].Width;
              this.Iheight=this.service.list[this.selectedIndex].Height;
              this.IDimension=this.service.list[this.selectedIndex].ThumbnailDimension;
              this.CloudImagePath = this.service.list[this.selectedIndex].CloudImageUrl;
              this.IsVideo = this.service.list[this.selectedIndex].isVideo;
              // this.downloadImageUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.WebPhotoID = this.service.list[this.selectedIndex].WebPhotoId;
              this.CreatedDate = this.service.list[this.selectedIndex].CreatedDate;
              this.ExpiryDate = this.service.list[this.selectedIndex].ExpiryDate;
              // this.setCanvasImage();
              this.toastr.success('Photo saved successfully!');
              this._constant.loading(false);
            })
        }, 5000);

      },
      (err: HttpErrorResponse) => {
        this._constant.loading(false);
        this.toastr.error('error=sticker saving');

        console.log(err);
      }
    );


  }

  //undo border
  undoBorder() {


    this.saveDataBorder.pop();

    // console.info(this.saveDataBorder.length);

    let activeObject = this.canvas.getActiveObject();

    if (activeObject) {
      this.canvas.remove(activeObject);

    }

    var old = this.saveDataBorder[this.saveDataBorder.length - 1];
    if (old) {
      this.canvas.add(old);
      this.setActive(old);
      this.isDisableUndoBorder = false;
      this.isDisableSaveBorder = false;
    }
    else {
      this.isDisableUndoBorder = true;
      this.isDisableSaveBorder = true;
    }


  }


  setActive(obj) {
    this.canvas.setActiveObject(obj);
  }


  applyBorder(bords: PartnerBorders) {
    // console.log('borders ', bords);
    this.BorderVisible = true;
    if (this.defaultbrdload){
    let bordereditContent = document.getElementById("borderEditBtnsWRa");
    bordereditContent.style.display = "block";
  }
    this.setImageOnCanvas(bords.BorderImageThumbnailsFullPath, true);
    // this.setBorder(bords.BorderImageThumbnailsFullPath);
    // this.setCanvasImageBorder(bords.BorderImageThumbnailsFullPath);

  }

  applyGraphics(grpchs: PartnerGraphics) {
    this.setGraphics(grpchs.GraphicImageFullPath);
  }

  selectItemAfterAdded(obj) {
    //this.canvas.deactivateAllWithDispatch().renderAll();
    this.canvas.setActiveObject(obj);
    // let CObject = this.canvas.getActiveObject();
    // this.saveDataBorder.push(CObject);
    // console.info('arrays');
    // console.info(this.saveData[this.saveData.length - 1]);
  }

  onCanvasClick(ev) {
    if (this.ImageBigThumbPath.includes("www-commdel-net/video") || this.ImageBigThumbPath.includes(".mov") || this.ImageBigThumbPath.includes(".mp4")) {
      // this.HideVideoClassvar="HideVideoClass";
      // this.videoURL=pw.BigThumbnailFilePath.replace('.jpg','.mp4');
      // this.videoURL=this.videoURL.replace('l_media_player,e_brightness:500/','');
      this.openModalWithClass2(this.templateRefVideo);
    }
  }

  lsetBorder(borderPath: string) {


    let h = this.canvas.height;
    let w = this.canvas.width;
    this.canvas.clear();
    var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
    borderPath = borderPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/w_200,h_200,c_fit/',
      newPath);

    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.remove(activeObject);
    }

    this.setImageOnCanvas(borderPath, true);

    this.setCanvasBackgroundImageUrl(this.ImageBigThumbPath);

    // fabric.Image.fromURL(borderPath, (image) => {
    //   image.set({
    //     left: 0,
    //     top: 0,
    //     lockMovementX: true,
    //     lockMovementY: true,
    //     lockScalingX: true,
    //     lockScalingY: true
    //   });
    //   image.crossOrigin = 'Anonymous';
    //   this.canvas.add(image);
    //   this.selectBorderAfterAdded(image);

    // });


    this.isDisableUndoBorder = false;
    this.isDisableSaveBorder = false;
  }
  setBorder(borderPath: string) {
    this._constant.loading(true);
     let activeObject = this.canvas.getActiveObject();
     if (activeObject) {
       this.canvas.remove(activeObject);
     }
     let borderWidth=this.Iwidth,borderHeigth=this.Iheight,scaleWidth=575,left=0;
     if(this.Iheight>this.Iwidth){
      // scaleWidth = this.dimensions.width * 1.1;
      // borderHeigth =
      // this.canvasHeight / this.bgImage.height;
      // borderWidth = 1650;
      scaleWidth = 290;
      borderHeigth = this.Iheight * 5;
      borderWidth = this.Iwidth * 5;
      left=this.dimensions.left
     }
     fabric.Image.fromURL(borderPath, (image) => {
      // this.canvas.setHeight((this.Iheight));
      //       this.canvas.setWidth((this.Iwidth));
      // debugger;
       image.set({
         left: left,
         top: 0,
        //  width: borderWidth,
        //  height:  borderHeigth ,
         lockMovementX: true,
         lockMovementY: true,
         lockScalingX: true,
         lockScalingY: true

       });

       image.scaleToWidth(this.dimensions.width)
       image.crossOrigin = 'Anonymous';
       this.canvas.add(image);
       this.canvas.renderAll();
       this.selectBorderAfterAdded(image);
       this._constant.loading(false);

     }, { crossOrigin: "Anonymous" });
     this.isDisableUndoBorder = false;
     this.isDisableSaveBorder = false;
   }

  selectBorderAfterAdded(obj) {

    this.canvas.setActiveObject(obj);
    let CObject = this.canvas.getActiveObject();
    // console.log('borderscanvas ', this.canvas);
    this.saveDataBorder.push(CObject);

  }

  setGraphics(graphicPath: string) {

    // let h = this.canvas.height / 2.5;
    // let w = this.canvas.width / 2.5;

    // let h = 102;
    // let w = 100;

    // var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';

    // graphicPath = graphicPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/w_200,h_200,c_fit/',
    //   newPath);

    // console.log('graphics path ', graphicPath);
    fabric.Image.fromURL(graphicPath, (image) => {
      image.scaleToWidth(120)
      image.set({
        left: 10,
        top: 20,
        objectCaching: false
      });
      image.crossOrigin = 'Anonymous';
      this.canvas.add(image);
      this.selectGraphicAfterAdded(image);

    }, { crossOrigin: "Anonymous" });


    this.isDisableUndoGraphic = false;
    this.isDisableSaveGraphic = false;
    this.isDisableRemoveGraphic = false;
  }

  selectGraphicAfterAdded(obj) {

    this.canvas.setActiveObject(obj);
    let CObject = this.canvas.getActiveObject();
    this.saveGrapchisData.push(CObject);

    // console.info('garay poped=' + this.saveGrapchisData.length);

  }

  undoGrapchis() {


    this.saveGrapchisData.pop();
    // console.info('garay poped=' + this.saveGrapchisData.length);

    let activeObject = this.canvas.getActiveObject();

    if (activeObject) {
      this.canvas.remove(activeObject);
    }

    var old = this.saveGrapchisData[this.saveGrapchisData.length - 1];
    if (old) {
      this.isDisableUndoGraphic = false;
      this.isDisableSaveGraphic = false;
      this.isDisableRemoveGraphic = false;
      this.setActive(old);
    }
    else {
      this.isDisableUndoGraphic = true;
      this.isDisableSaveGraphic = true;
      this.isDisableRemoveGraphic = true;
    }

  }

  restoreImageDefault_og() {

    if (this.saveDataBorder.length > 0 || this.saveGrapchisData.length > 0) {
      if (confirm(' Would you like to save the changes??')) {

        if (this.saveDataBorder.length > 0) {
          this.saveCanvasImageBorder();
        }
        if (this.saveGrapchisData.length > 0) {
          this.saveCanvasImageGraphic();
        }

      }
      else {

        if (this.ImageBigThumbPath) {


          let self = this;
          this.canvas.clear();
          let h = this.canvas.height;
          let w = this.canvas.width;

          var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
          newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
            newPath);

          // this.canvas.setBackgroundColor({ source: newPath, repeat: 'no-repeat', crossOrigin: "Anonymous" }, function () {
          //   self.canvas.renderAll();

          // });

          fabric.Image.fromURL(newPath, (img) => {
            this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
              scaleX: this.canvas.width / img.width,
              scaleY: this.canvas.height / img.height
            });
          });


          this.isDisableUndoGraphic = true;
          this.isDisableSaveGraphic = true;
          this.isDisableRemoveGraphic = true;

          this.isDisableSaveBorder = true;
          this.isDisableUndoBorder = true;

          //this.ImageBigThumbPath = newPath;

          while (this.saveGrapchisData.length > 0) {
            this.saveGrapchisData.pop();
          }

          while (this.saveDataBorder.length > 0) {
            this.saveDataBorder.pop();
          }


        }
      }
    }

  }
  

  restoreImageDefault() {
    if (this.saveDataBorder.length > 0 || this.saveGrapchisData.length > 0) {
      //this.openModal(this.templateAlertSave);
      document.getElementById("btnModelOpen").click();
    }
  }
  // borderRestoreImageDefault(){
  //   this.restoreImageDefault()

  // }
  // stickerRestoreImageDefault(){
  //   this.restoreImageDefault()
  // }
  
  
  borderRestoreImageDefault() {
    if (this.saveDataBorder.length > 0 || this.saveGrapchisData.length > 0) {
      //this.openModal(this.templateAlertSave);
      document.getElementById("btnModelOpen").click();
    }
	//alert("Button is clicked");
	// $owl.trigger('refresh.owl.carousel');
	//this.owlElement.refresh();
	 let sticker = document.getElementById("collapseStickers");
	 let border = document.getElementById("collapseBorders");
	 let stickereditContent = document.getElementById("stickerEditBtns");
	 let bordereditContent = document.getElementById("borderEditBtnsWRa");

      sticker.style.visibility = "visible";
	  border.style.visibility = "visible";
	  
	  bordereditContent.style.display = "block";
	  stickereditContent.style.display = "none";
	 
	
  }
  
    stickerRestoreImageDefault() {
    if (this.saveDataBorder.length > 0 || this.saveGrapchisData.length > 0) {
      //this.openModal(this.templateAlertSave);
      document.getElementById("btnModelOpen").click();
    }
	//alert("Button is clicked");
	// $owl.trigger('refresh.owl.carousel');
	//this.owlElement.refresh();
	 let sticker = document.getElementById("collapseStickers");
	 let border = document.getElementById("collapseBorders");
	 let stickereditContent = document.getElementById("stickerEditBtns");
	 let bordereditContent = document.getElementById("borderEditBtnsWRa");

      sticker.style.visibility = "visible";
	  border.style.visibility = "visible";
	  
	  stickereditContent.style.display = "block";
	  
	  
	  bordereditContent.style.display = "none";
	 
	
  }
  
  confirmSave() {
    if (this.saveDataBorder.length > 0) {
      this.saveCanvasImageBorder();
    }
    if (this.saveGrapchisData.length > 0) {
      this.saveCanvasImageGraphic();
    }

    document.getElementById("btnModelClose").click();
  }

  declineSave() {


    if (this.ImageBigThumbPath) {


      // let self = this;
      // this.canvas.clear();
      // let h = this.canvas.height;
      // let w = this.canvas.width;

      // var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
      // newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
      //   newPath);

      // // this.canvas.setBackgroundColor({ source: newPath, repeat: 'no-repeat', crossOrigin: "Anonymous" }, function () {
      // //   self.canvas.renderAll();

      // // });

      // fabric.Image.fromURL(newPath, (img) => {
      //   this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
      //     scaleX: this.canvas.width / img.width,
      //     scaleY: this.canvas.height / img.height
      //   });
      // });


      // this.isDisableUndoGraphic = true;
      // this.isDisableSaveGraphic = true;
      // this.isDisableRemoveGraphic = true;

      // this.isDisableSaveBorder = true;
      // this.isDisableUndoBorder = true;

      // //this.ImageBigThumbPath = newPath;

      while (this.saveGrapchisData.length > 0) {
        this.saveGrapchisData.pop();
      }

      while (this.saveDataBorder.length > 0) {
        this.saveDataBorder.pop();
      }
      this.setCanvasImage();


    }

    document.getElementById("btnModelClose").click();

  }

  restoreImage() {
    this.openModal(this.templateAlert)
  }

  removeGraphics() {
    let activeObject = this.canvas.getActiveObject();
    //let activeGroup = this.canvas.getActiveGroup();


    if (activeObject) {
      this.canvas.remove(activeObject);
      // this.textString = '';
    }
  }

  addImageOnCanvas(url) {

    if (url) {
      fabric.Image.fromURL(url, (image) => {
        image.set({
          left: 10,
          top: 10,
          angle: 0,
          padding: 10,
          cornersize: 10,
          hasRotatingPoint: true
        });
        image.height = 350;
        image.width = 530;
        this.extend(image, this.randomId());
        this.canvas.add(image);
        //this.selectItemAfterAdded(image);
      });
    }
  }

  setCanvasImage() {
    // console.log('image thumb is ', this.ImageBigThumbPath);
    if (this.ImageBigThumbPath) {
      // this.ImageBigThumbPath = "https://res.cloudinary.com/www-commdel-net/image/upload/w_250,h_150,c_fit/OrderImages/DUBAI/ATT/LG-Main Counter/20211130/DG-1931340970/FWUNVPRE/3011303966381_1_2_3.jpg";
      let self = this;
      this.canvas.clear();
      let h = this.canvas.height;
      let w = this.canvas.width;
      var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_fill/';
      newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
        newPath);
      // console.log('new path is ', newPath);
      if (this.ImageBigThumbPath.includes("www-commdel-net/video") || this.ImageBigThumbPath.includes(".mov") || this.ImageBigThumbPath.includes(".mp4")) {
        // console.log('video is ');
        this.HideVideoClassvar = "HideVideoClass";
        newPath = 'https://res.cloudinary.com/www-commdel-net/video/upload/w_' + w + ',h_' + h + ',c_scale/';
        newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/video/upload/',
          newPath);
        this.videoURL = newPath;
        // newPath = "assets/images/play-button.png";
      }

      // newPath = "https://res.cloudinary.com/www-commdel-net/image/upload/w_250,h_150,c_fit/OrderImages/DUBAI/ATT/LG-Main Counter/20211130/DG-1931340970/FWUNVPRE/3011303966381_1_2_3.jpg";
      this.setImageOnCanvas(newPath);
      // this.setCanvasBackgroundImageUrl(newPath);

    }
  }

  setCanvasImageAfterEdit(newPathh) {

    if (newPathh) {
      let self = this;
      this.canvas.clear();
      let h = this.canvas.height;
      let w = this.canvas.width;
      var wn = 'w_' + w;
      var hn = 'h_' + h;
      var wo = 'w_' + (w * 3);
      var ho = 'h_' + (h * 3);
      var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
      newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
        newPath);

      // console.info('newPath ', newPath);
      // var newPath = newPathh.replace(wo,wn);
      // var newPath = newPathh.replace(ho,hn);

      // this.canvas.setBackgroundColor({ source: newPath, repeat: 'no-repeat', crossOrigin: "Anonymous" }, function () {
      //   self.canvas.renderAll();
      // });

      fabric.Image.fromURL(newPath, (img) => {
        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height
        });
      });

    }
  }

  sendToBack() {

    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.sendToBack();
    }
  }

  setImageOnCanvas(path, isOverlay?) {

    // console.log('path ', path, isOverlay);

    if(isOverlay) {
      let activeObject = this.canvas.getActiveObject();
     if (activeObject) {
       this.canvas.remove(activeObject);
     }
    }

    fabric.Image.fromURL(path, (oImg) => {
      // if (!isOverlay) {
      //   if (oImg.width < oImg.height) {
      //     this.canvas.setDimensions({ width: this.canvas.width, height: ((oImg.height * this.canvas.width) / oImg.width) / 2 });
      //   } else {
      //     this.canvas.setDimensions({ height: ((oImg.height * 594) / oImg.width), width: 594 });
      //     this.canvas.requestRenderAll();
      //   }
      // }


      let imgWidth = oImg.width;
      let imgHeight = oImg.height;
      let canvasWidth = this.canvas.getWidth();
      let canvasHeight = this.canvas.getHeight();

      if (this.canvas.width > this.canvas.height) {
        // oImg.scaleToHeight(this.dimensions.height * this.dimensions.scaleFactor);
        oImg.scaleToWidth(this.dimensions.width*this.dimensions.scaleFactor);
        // oImg.scaleToHeight(533.19);
      } else {
        oImg.scaleToHeight(this.dimensions.height * this.dimensions.scaleFactor);
      }
      let aspect = oImg.width / oImg.height;

      let scaleX, scaleY;


      let imgOpts: any = {
        left: this.dimensions.left,
        top: this.dimensions.top,
        // width: oImg.width,
        // scaleX: this.dimensions.scaleFactor/1.33,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        selectable: false,
        evented: false,
        objectCaching: false,
        // scaleX: scaleX,
        // scaleY: scaleY
      };


      if (this.dimensions.width > this.dimensions.height) {
        scaleX = this.canvas.width / oImg.width;
        scaleY = this.canvas.height / (oImg.height);
        imgOpts.top = 0;
        imgOpts.scaleY = scaleY;
      } else {
        scaleX = (this.dimensions.width / oImg.width) * .325;
        scaleY = 0;
        // imgOpts.scaleX = scaleX;
        imgOpts.scaleY = this.canvas.height / (oImg.height);
      }

      oImg.set(imgOpts);

      oImg.objectCaching = false;

      if (isOverlay) {
        this.canvas.add(oImg);
        // this.canvas.centerObject(oImg);
        this.canvas.setActiveObject(oImg);
        let CObject = this.canvas.getActiveObject();
        // console.log('borderscanvas ', this.canvas);
        this.saveDataBorder.push(CObject);
        // this.selectBorderAfterAdded(oImg);
        this.isDisableUndoBorder = false;

      this.isDisableSaveBorder = false;
        // this.canvas.deactivateAll().renderAll();
      } else {
        this.setCanvasBackgroundImageUrl(path);
        // this.canvas.discardActiveObject();
      }
    }, { crossOrigin: 'Anonymous' });

  }

  setCanvasImageBorder(bordr) {

    if (bordr) {

      let self = this;
      // this.canvas.clear();
      let h = this.canvas.height;
      let w = this.canvas.width;


      var newPath1 = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
      newPath1 = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
        newPath1);

      // this.setImageOnCanvas(newPath1);

      // fabric.Image.fromURL(newPath1, (image) => {
      //   image.set({
      //     left: 0,
      //     top: 0,

      //     // lockMovementX: true,
      //     // lockMovementY: true,
      //     // lockScalingX: true,
      //     // lockScalingY: true
      //   });
      //   image.crossOrigin = 'Anonymous';

      //   this.canvas.add(image);
      //   //this.selectItemAfterAdded(image);

      // });


      var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
      newPath = bordr.replace('https://res.cloudinary.com/www-commdel-net/image/upload/w_200,h_200,c_fit/',
        newPath);

      this.setImageOnCanvas(newPath, true);
      // fabric.Image.fromURL(newPath, (image) => {
      //   image.set({
      //     left: 0,
      //     top: 0,

      //     lockMovementX: true,
      //     lockMovementY: true,
      //     // lockScalingX: true,
      //     // lockScalingY: true
      //   });
      //   image.crossOrigin = 'Anonymous';

      //   this.canvas.add(image);
      //   this.selectItemAfterAdded(image);

      // });

      //this.sendToBack();

      // this.canvas.setBackgroundColor({ source: newPath, repeat: 'no-repeat', crossOrigin: "Anonymous" }, function () {
      //   self.canvas.renderAll();
      // });

      // var ctx=this.canvas.getContext('2d');
      // var img=new Image();
      // img.src=newPath;

      // img.onload= this.drawImageScaled.bind(img, ctx);


    }
  }
  drawImageScaled(ctx, img) {

    var canvas = ctx.canvas;
    // console.log('img and ctx is ', ctx, canvas, img);
    var hRatio = canvas.width / img.width;
    var vRatio = canvas.height / img.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (canvas.width - img.width * ratio) / 2;
    var centerShift_y = (canvas.height - img.height * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, img.width, img.height,
      centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
  }

  extend(obj, id) {
    obj.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id: id
        });
      };
    })(obj.toObject);
  }

  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }

  /********* ngx scrollbar *********/

  // Unsubscriber for elementScrolled stream.
  private _scrollSubscription = Subscription.EMPTY;

  // Get NgScrollbar reference
  // @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;


  arrayOne(n: number): any[] {
    return Array(n);
  }

  ngOnDestroy() {
    this._scrollSubscription.unsubscribe();
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (this.modalRefVid) {
      this.modalRefVid.hide();
    }
    const body = document.getElementsByClassName('super-container')[0];
    body.classList.remove('no-bg');
    body.classList.remove('app-welcome-page');
  }

  getPartnerClaimedPhotos() {
    this._constant.loading(true);

    this.service.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
      .then(res => {
        this.service.list = res as PartnerWebPhotos[];


        if (this.service.list && !this.service.list.length) {
          this._router.navigateByUrl('/nonclaimed-photos');
        }
        // this.claimImgResp =true;
        // this.productFilter();
        this.service.list.forEach(obj => {
          let extension = obj.OriginalFilePath.split('/').pop().split('#')[0].split('?')[0];
          let name_arr = extension.split(".");
          let filename = name_arr[0];
          this.fileNames[filename] = 0;
        });

        // this.service.list.forEach(obj => {
        //   if (obj.Height <= 0) {
        //     this.openModal(this.templatePhotoLoad);
        //   }
        // });

        if (this.service.list.length > 0) {
          // this.ImageBigThumbPath = this.service.list[0].BigThumbnailFilePath;
          // this.ImageBigThumbPath = this.service.list[0].ThumbnailFilePath;
          this.selectedIndex = 0;
          this.ImageBigThumbPath = this.service.list[0].MinifiedFilePath;
          this.OriginalUrl = this.service.list[0].OriginalFilePath;
          this.selectedWebPhotoId = this.service.list[0].WebPhotoId;
          this.ThumbnailPath = this.service.list[0].ThumbnailFilePath;
          this.Iwidth=this.service.list[0].Width;
          this.Iheight=this.service.list[0].Height;
          this.IDimension=this.service.list[0].ThumbnailDimension;
          this.CloudImagePath = this.service.list[0].CloudImageUrl;
          this.IsVideo = this.service.list[0].isVideo;
          this.downloadImageUrl = this.service.list[0].OriginalFilePath;
          this.WebPhotoID = this.service.list[0].WebPhotoId;
          this.CreatedDate = this.service.list[0].CreatedDate;
          this.ExpiryDate = this.service.list[0].ExpiryDate;
          this._cartService.CheckCartCount(localStorage.getItem('PartnerUserId'));

        }
        this.setCanvasImage();

        this._constant.loading(false);
      }, (err) => {
        this._constant.loading(false);
      });

    this._constant.loading(true);

    this.service.getPartnerProducts(localStorage.getItem('PartnerUserId'))
      .then(res => {
        this.service.listProducts = res as PartnerProductModel[];
        // this.prodResp = true;
        // this.productFilter();
        // console.log('service list ', this.service.listProducts);
        if (this.service.listProducts.length > 2) {
          this.productOptions.rtl = false;
          this.productOptions.loop = false;

        } else {
          this.productOptions.rtl = false;
          this.productOptions.loop = false;
        }
        if (this.service.listProducts.length > 0) {
          // this.service.listProducts.forEach((item) => {
          //   item.ImageView1 = "https://proddeidubai.blob.core.windows.net/dubai-expo-2020/thumbs/EXPO%202020/20211111/t_1111100988133.jpg?sv=2020-08-04&se=2021-11-12T05%3A16%3A07Z&sr=c&sp=r&sig=zyY7kSV3Jvhr8olle896Cma24gtQfV94kjbk3mEDQko%3D";
          // })
          this.ProductID = this.service.listProducts[0].ProductId;
          this.MaxImageCount = this.service.listProducts[0].MaxImageCount;
          this.ProductName = this.service.listProducts[0].ProductName;
          this.Description = this.service.listProducts[0].Description;
          this.Material = this.service.listProducts[0].Material;
          this.UnitPrice = this.service.listProducts[0].UnitPrice;
          this.ImageView1 = this.service.listProducts[0].ImageView1;
          this.ImageView2 = this.service.listProducts[0].ImageView2;
          this.ImageView3 = this.service.listProducts[0].ImageView3;
          this.isPackage = this.service.listProducts[0].isPackage;
          this.isDigital = this.service.listProducts[0].RemoveWatermark;
          // this.selectedProduct = this.service.listProducts[0];
        }

        if (this.ImageBigThumbPath != "") {
          this._constant.loading(false);
        }
        if (!this.ProductID || this.ProductID <= 0) {
          this.productVisible = false;
        }
        else {
          this.productVisible = true;
        }
      });;

  }

  getPartnerClaimedPhotosAfterEdit() {
    this._constant.loading(true);
    this.service.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
      .then(res => {
        this.service.list = res as PartnerWebPhotos[];

        if (this.service.list && !this.service.list.length) {
          this._router.navigateByUrl('/nonclaimed-photos');
        }
        if (this.service.list.length > 0) {
          // this.ImageBigThumbPath = this.service.list[0].BigThumbnailFilePath;
          // this.ImageBigThumbPath = this.service.list[0].ThumbnailFilePath;
          this.downloadImageUrl = this.service.list[0].OriginalFilePath;
          this.ImageBigThumbPath = this.service.list[0].MinifiedFilePath;
          this.OriginalUrl = this.service.list[0].OriginalFilePath;
          this.ThumbnailPath = this.service.list[0].ThumbnailFilePath;
          this.Iwidth=this.service.list[0].Width;
    this.Iheight=this.service.list[0].Height;
    this.IDimension=this.service.list[0].ThumbnailDimension;
          this.IsVideo = this.service.list[0].isVideo;
          this.CloudImagePath = this.service.list[0].CloudImageUrl;
          this.WebPhotoID = this.service.list[0].WebPhotoId;
          this.CreatedDate = this.service.list[0].CreatedDate;
          this.ExpiryDate = this.service.list[0].ExpiryDate;
        }
        this._constant.loading(false);
      });

  }



  downloadSingleCloud(ImageBigThumbPath) {
    this._constant.loading(true);
    // console.log('thumb image is ', ImageBigThumbPath);
    var dwnld = "";
    if (ImageBigThumbPath.includes("www-commdel-net/video") || this.ImageBigThumbPath.includes(".mov") || ImageBigThumbPath.includes(".mp4")) {
      dwnld = this.downloadImageUrl.replace("https://res.cloudinary.com/www-commdel-net/video/upload/", "https://res.cloudinary.com/www-commdel-net/video/upload/fl_attachment/");
      // window.open(dwnld, "_blank");

      let extension = this.downloadImageUrl.split('/').pop().split('#')[0].split('?')[0];
      // console.log('extension us ', extension);

      saveAs(dwnld, extension);
    }
    else {
      dwnld = ImageBigThumbPath.replace("https://res.cloudinary.com/www-commdel-net/image/upload/", "https://res.cloudinary.com/www-commdel-net/image/upload/fl_attachment/");
      window.open(dwnld, "_blank");
    }
    this._constant.loading(false);
  }

  getFileName(name, filenames = []) {
    let name_arr = name.split(".");
    let extension = name_arr[1];
    //  console.log('file names and name ', name, filenames, filenames.includes(name));
    if (filenames.includes(name)) {
      this.fileNames[name_arr[0]] = this.fileNames[name_arr[0]] + 1;
      let count = this.fileNames[name_arr[0]];
      return name_arr[0] + " (" + count + ")." + extension;
    } else {
      return name;
    }
  }


  downloadCloud() {
    this._constant.loading(true);

    this.service.download(localStorage.getItem('PartnerUserId')).
      then((res) => {

        // console.log('res ', res);
        var dwnld = res as string;
        var spltdwnold = dwnld.split("###");
        // console.log("got resp");
        // console.log("res=" + dwnld);


        if (spltdwnold[0]) {
          // console.log("res0=" + spltdwnold[0]);


          var newWin = window.open(spltdwnold[0] as string, "_blank");

          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            this.toastr.info('Allow pop-ups to download all images', 'Pop-ups',
              {
                timeOut: 20000
              });
          }
        }

        if (spltdwnold[1]) {
          // console.log("res1=" + spltdwnold[1]);

          var newWin = window.open(spltdwnold[1] as string, "_blank");

          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            this.toastr.info('Allow pop-ups to download all videos', 'Pop-ups',
              {
                timeOut: 20000
              });
          }
        }
        this._constant.loading(false);
      },
        (err: HttpErrorResponse) => {
          this._constant.loading(false);
          console.log(err);
        }
      );
  }

  addTocartFromList(pw: PartnerProductModel) {
    this._constant.loading(true);
    this._cartService.postAddCart(localStorage.getItem('PartnerUserId'), pw.ProductId, 1).then
      (
        (res) => {
          this.toastr.success('Product added to cart');
          this._cartService.CheckCartCount(localStorage.getItem('PartnerUserId'));
          this._constant.loading(false);
        },
        (err: HttpErrorResponse) => {

          if (err.status == 1) {
            this.toastr.warning('Quanity more than 10 not allowed, for single product');
          }
          else {
            this.toastr.error('Error! Adding Product');
          }
          console.log(err);
          this._constant.loading(false);
        }
      );

  }


  populateForm(pw: PartnerWebPhotos) {
    this._constant.loading(true);
    this.service.list
    this.selectedIndex = this.service.list.findIndex(x => x.WebPhotoId === pw.WebPhotoId);
    // console.log("indexe",this.selectedIndex)
    // this.selectedIndex = i;
    // console.log('pw is ', this.selectedIndex);

    this.HideVideoClassvar = "";
    this._constant.loading(true);
    // this.ImageBigThumbPath = pw.BigThumbnailFilePath;
    // this.ImageBigThumbPath = pw.ThumbnailFilePath;
    this.downloadImageUrl = pw.OriginalFilePath;
    this.clddownloadImageUrl = pw.OriginalFilePath;
    this.ImageBigThumbPath = pw.MinifiedFilePath
    this.OriginalUrl = pw.OriginalFilePath;
    this.ThumbnailPath = pw.ThumbnailFilePath;
    this.Iwidth=pw.Width;
    this.Iheight=pw.Height;
    this.IDimension=pw.ThumbnailDimension;
    this.selectedWebPhotoId = pw.WebPhotoId;
    this.IsVideo = pw.isVideo;
    this.CloudImagePath = pw.CloudImageUrl;
    this.WebPhotoID = pw.WebPhotoId;
    this.CreatedDate = pw.CreatedDate;
    this.ExpiryDate = pw.ExpiryDate;
    this.setCanvasImage();

    // var vid= 'VideoPlayer' as TemplateRef<any>;
    if (this.ImageBigThumbPath.includes("www-commdel-net/video") || this.ImageBigThumbPath.includes(".mov") || this.ImageBigThumbPath.includes(".mp4")) {
      // this.HideVideoClassvar = "HideVideoClass";
      // this.videoURL = pw.BigThumbnailFilePath.replace('.jpg', '.mp4');
      // this.videoURL = this.videoURL.replace('l_media_player,e_brightness:500/', '');
      this.openModalWithClass2(this.templateRefVideo);
    }
    this._constant.loading(false);
  }

  // populateForm(pw: PartnerWebPhotos) {
  //   this._constant.loading(true);

  //   this.HideVideoClassvar = "";
  //   this._constant.loading(true);
  //   this.ImageBigThumbPath = pw.BigThumbnailFilePath;
  //   this.WebPhotoID = pw.WebPhotoId;
  //   this.CreatedDate = pw.CreatedDate;
  //   this.ExpiryDate = pw.ExpiryDate;
  //   this.setCanvasImage();

  //   // var vid= 'VideoPlayer' as TemplateRef<any>;
  //   if (this.ImageBigThumbPath.includes("www-commdel-net/video")) {
  //     this.HideVideoClassvar = "HideVideoClass";
  //     this.videoURL = pw.BigThumbnailFilePath.replace('.jpg', '.mp4');
  //     this.videoURL = this.videoURL.replace('l_media_player,e_brightness:500/', '');
  //     this.openModalWithClass2(this.templateRefVideo);
  //   }
  //   this._constant.loading(false);
  // }

  populateProduct(pw: PartnerProductModel) {

    this.showproductImageCarousel= false;
    this.service.listProducts.forEach(obj => {
      let element = document.getElementById(obj.ProductName);
      element.classList.remove('selected');
    });
    let element = document.getElementById(pw.ProductName);

    element.classList.add('selected');

    this.ProductID = pw.ProductId;
    this.MaxImageCount = pw.MaxImageCount;
    this.isPackage = pw.isPackage;
    this.isDigital = pw.RemoveWatermark;
    this.ProductName = pw.ProductName;
    this.Description = pw.Description;
    this.UnitPrice = pw.UnitPrice;
    this.Material = pw.Material;
    this.ImageView1 = pw.ImageView1;
    this.ImageView2 = pw.ImageView2;
    this.ImageView3 = pw.ImageView3;
    // this.selectedProduct = pw;
    setTimeout(() => {
      this.showproductImageCarousel= true;
    });
    
  }


  onTwitter(TwitterComment) {
    console.log("Validating token")
    var CloudUrl = this.ImageBigThumbPath;
    this.service.formData = new PartnerWebPhotos;
    // this.service.formData.CloudImageUrl = this.ImageBigThumbPath;
    this.service.formData.CloudImageUrl = this.CloudImagePath;
    this.service.formData.TwitterComment = TwitterComment;
    // console.log('form twitter cloud ', this.service.formDataEmail);
    this.service.Twitter(this.service.formData).then(
      (res) => {
        // console.log('api res ', this.service.formData.CloudImageUrl);
        window.open(this.service.formData.CloudImageUrl, 'popup_window', 'left=50,top=50,width=400,height=400,titlebar=0,toolbar=0,resizable=false');
        this.service.twitterPost();
        this.modalRef.hide();
      },
      (err: HttpErrorResponse) => {
        console.log(err);

      })
  }

  ShareMail() {
// console.log("d")
    if (this.validateEmail(this.service.formDataEmail.Email)) {
      //this.service.formDataEmail=new EmailShare;
      if (this.ImageBigThumbPath.includes("www-commdel-net/video")) {
        this.service.formDataEmail.CloudImageUrl = this.videoURL;
      }
      else {
        // this.service.formDataEmail.CloudImageUrl = this.ImageBigThumbPath;
        this.service.formDataEmail.CloudImageUrl = this.CloudImagePath
        console.log("Validating token...")
      }
      this.service.ShareMail(this.service.formDataEmail).then(
        (res) => {
          this.modalRef.hide();
          this.service.formDataEmail.CloudImageUrl = '';
          this.service.formDataEmail = {
            Email: '',
            Message: '',
            Subject: '',
            CloudImageUrl: ''
          };
          this.toastr.success('Email has been sent successfully');
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.toastr.error("" + err);
        }
      )
    }
    else {
      this.toastr.error("Email Id is not valid");
    }
  }
  validateEmail(email) {
    var re = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
    return re.test(email);
  }




  /********* owl carousel *********/
  /********* owl carousel *********/
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
	margin: 15,
    navText: ['&#8249', '&#8250;'],

    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }
  
  stickerborder: OwlOptions = {
    loop: false,
    dots: false,
    navSpeed: 600,
	margin: 15,
    navText: ['&#8249', '&#8250;'],

    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      760: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    nav: true
  }
  


  productOptions: OwlOptions = {
    loop: true,
    items: 4,
    margin: 10,
    responsive: {
      250: {
        items: 2
      },
      450: {
        items: 3
      },
      500: {
        items: 4
      }
    },
  }
  /********* borer & stickers collapse *********/
  isCollapsedBorders = false;
  isCollapsedStickers = false;


  /********* ngx modal *********/
  modalRef: BsModalRef;
  modalRefVid: BsModalRef;
  //constructor(private modalService: BsModalService) {}

  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'socialShareModal' })
    );
  }

  openModalWithClass2(template: TemplateRef<any>) {
    this.modalRefVid = this.modalService.show(
      template,
      Object.assign({}, { class: 'videomodal' })
    );
  }



  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }



  openModalVid(template: TemplateRef<any>) {
    this.modalRefVid = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  confirm(): void {
    if (this.ImageBigThumbPath) {
      // console.log("d")

      this.service.restoreImage(this.WebPhotoID).subscribe(
        (res) => {
          // this.resetForm(form);
          this.restoreImageDefault();
          this.toastr.success('Image restored successfully!');


          let self = this;
          this.canvas.clear();
          let h = this.canvas.height;
          let w = this.canvas.width;



          // var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
          // // newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
          // //   newPath);

          // newPath = newPath + this.ImageBigThumbPath.substring(this.ImageBigThumbPath.indexOf('OrderImages'), this.ImageBigThumbPath.length);

          // var old = 'https://res.cloudinary.com/www-commdel-net/image/upload/' + this.ImageBigThumbPath.substring(this.ImageBigThumbPath.indexOf('OrderImages'), this.ImageBigThumbPath.length);

          // if (newPath.includes('EditedImage/')) {
          //   this.ImageBigThumbPath = old.replace('EditedImage/', '');
          //   newPath = newPath.replace('EditedImage/', '');
          // }
          var newPath = 'https://res.cloudinary.com/www-commdel-net/image/upload/w_' + w + ',h_' + h + ',c_scale/';
          // newPath = this.ImageBigThumbPath.replace('https://res.cloudinary.com/www-commdel-net/image/upload/',
          //   newPath);

          newPath = this.ImageBigThumbPath.substring(this.ImageBigThumbPath.indexOf('OrderImages'), this.ImageBigThumbPath.length);

          var old = this.ImageBigThumbPath.substring(this.ImageBigThumbPath.indexOf('OrderImages'), this.ImageBigThumbPath.length);

          if (newPath.includes('EditedImage/')) {
            this.ImageBigThumbPath = old.replace('EditedImage/', '');
            newPath = newPath.replace('EditedImage/', '');
          }


          // console.info("after restore=" + this.ImageBigThumbPath);
          // this.canvas.setBackgroundColor({ source: newPath, repeat: 'no-repeat', crossOrigin: "Anonymous" }, function () {
          //   self.canvas.renderAll();
          // });

          // fabric.Image.fromURL(newPath, (img) => {
          //   console.log('imgaes form url ', img);
          //   this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
          //     scaleX: this.canvas.width / img.width,
          //     scaleY: this.canvas.height / img.height
          //   });

          // });


          this.isDisableUndoGraphic = true;
          this.isDisableSaveGraphic = true;
          this.isDisableRemoveGraphic = true;

          this.isDisableSaveBorder = true;
          this.isDisableUndoBorder = true;

          if (this.ImageBigThumbPath.includes('EditedImage/')) {
            this.ImageBigThumbPath = this.ImageBigThumbPath.replace('EditedImage/', '');

          }


          while (this.saveGrapchisData.length > 0) {
            this.saveGrapchisData.pop();
          }

          while (this.saveDataBorder.length > 0) {
            this.saveDataBorder.pop();
          }

          this.service.getPartnerPhotos(localStorage.getItem('PartnerUserId'))
            .then((res) => {
              this.service.list = [];
              this.service.list = res as PartnerWebPhotos[]

              if (this.service.list && !this.service.list.length) {
                this._router.navigateByUrl('/nonclaimed-photos');
              }
              this.ImageBigThumbPath = this.service.list[this.selectedIndex].MinifiedFilePath;
              this.OriginalUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.downloadImageUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.clddownloadImageUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.ThumbnailPath = this.service.list[this.selectedIndex].ThumbnailFilePath;
              this.Iwidth=this.service.list[this.selectedIndex].Width;
              this.Iheight=this.service.list[this.selectedIndex].Height;
              this.IDimension=this.service.list[this.selectedIndex].ThumbnailDimension;
              this.CloudImagePath = this.service.list[this.selectedIndex].CloudImageUrl;
              this.IsVideo = this.service.list[this.selectedIndex].isVideo;
              // this.downloadImageUrl = this.service.list[this.selectedIndex].OriginalFilePath;
              this.WebPhotoID = this.service.list[this.selectedIndex].WebPhotoId;
              this.CreatedDate = this.service.list[this.selectedIndex].CreatedDate;
              this.ExpiryDate = this.service.list[this.selectedIndex].ExpiryDate;
              this.setCanvasImage();
              this._constant.loading(false);

            });
          this.modalRef.hide();
        },
        (err: HttpErrorResponse) => {

          this.toastr.error('error=border saving');
          this.modalRef.hide();
          console.log(err);
        }
      );

    }
    // this.modalRef.hide();
  }
  // onFacebook() {
  //   var cloudurl = this.ImageBigThumbPath;
  //   if (this.ImageBigThumbPath.includes("www-commdel-net/video")) {
  //     cloudurl = this.videoURL
  //   }
  //   else {
  //     cloudurl = this.ImageBigThumbPath
  //   }

  //   cloudurl = "javascript:void(window.open('https://www.facebook.com/sharer.php?u=" + cloudurl + "','mywindowtitle','width=500,height=150'))";
  //   let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(cloudurl);
  //   this.fbkurl = sanitizedUrl;
  // }

  onFacebook() {
    var cloudurl = this.ImageBigThumbPath;
    if (this.ImageBigThumbPath.includes("www-commdel-net/video")) {
      cloudurl = this.videoURL
    }
    else {
      cloudurl = this.ImageBigThumbPath
    }
    cloudurl = cloudurl.replace(/\%/g, "%25");
    cloudurl = cloudurl.replace(/\=/g, "%3D");
    cloudurl = cloudurl.replace(/\&/g, "%26");
    cloudurl = cloudurl.replace(/\?/g, "%3F");
    cloudurl = cloudurl.replace(/\//g, "%2F");
    cloudurl = cloudurl.replace(/\:/g, "%3A");
    cloudurl = cloudurl.concat("&amp;src=sdkpreparse");

    // cloudurl = "javascript:void(window.open('https://www.facebook.com/sharer.php?u=" + cloudurl + "','mywindowtitle','width=500,height=150'))";
    cloudurl = "https://www.facebook.com/sharer/sharer.php?u=" + cloudurl + "";
    // cloudurl = "https://stagdeidubai.blob.core.windows.net/mandai/media/BIRD%20PARADISE/20230410/EditedImage/m_1044580_6.jpg?sv=2020-08-04&se=2023-04-27T20%3A46%3A06Z&sr=b&sp=r&sig=tfq80tDKufnEOyPgLgkxIVoMHv6nabmVbYiVUTW1lGo%3D";
    let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(cloudurl);
    
    // this.fbkurl = "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstagdeidubai.blob.core.windows.net%2Fmandai%2Fmedia%2FBIRD%2520PARADISE%2F20230410%2FEditedImage%2Fm_1044580_6.jpg%3Fsv%3D2020-08-04%26se%3D2023-04-27T20%253A46%253A06Z%26sr%3Db%26sp%3Dr%26sig%3Dtfq80tDKufnEOyPgLgkxIVoMHv6nabmVbYiVUTW1lGo%253D&amp;src=sdkpreparse"
    this.fbkurl = sanitizedUrl
  }
  decline(): void {
    this.modalRef.hide();
  }
  Twitconfirm(): void {
    window.close();
    this.modalRef.hide();
  }

  ClosePhoto(): void {
    window.close();
    this.modalRef.hide();
  }

  Twitdecline(): void {
    this.modalRef.hide();
  }

  isCollapsed = false;



  /********* window scoll top *********/
  @HostListener('window:scroll')
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    //console.log('[scroll]', scrollPosition);
    // if (scrollPosition >= this.topPosToStartShowing) {
    //   this.isShow = true;
    // } else {
    //   this.isShow = false;
    // }
  }
  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  resizeCanvas_org(){
    // alert("box")
  //   var context = this.canvas.getContext("2d")
  //   var scale = 1;
  //   var originx = 0;
  //   var originy = 0;
  // //   context.translate(
  // //     originx,
  // //     originy
  // // );
  // context.scale(0.5,0.5);
  // var context = this.canvas.getContext()
  // context.scale(0.5,0.5);
  // var img = this.canvas.getActiveObject();
  // this.canvas.remove(img);
  // this.canvas.centerObject(img);
  // this.canvas.setActiveObject(img);
  // this.canvas.add(img);

  // this.canvas.renderAll();
  // this.canvas.setZoom(0.5)
  this._constant.loading(true);
  this.canvas.clear()
  var canvas = this.canvas
  fabric.Image.fromURL(this.ImageBigThumbPath, (img) => {
    img.set({
      opacity: 1,
      // scaleX: canvas.width/(img.width),
      // scaleY: canvas.height/(img.height*2),
      scaleX: canvas.width/(img.width),
      scaleY: canvas.height/(img.height),
    });
    canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas));
  });
  this._constant.loading(false);
  }


}

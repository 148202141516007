
import { HttpClient} from '@angular/common/http'
import { Injectable, Output,EventEmitter } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { WebPhotosCartModel } from './WebPhotos-Cart.model';
import { CartProductsModel } from './cart-products.model';



@Injectable({
    providedIn: 'root'
  })

export class CartService {
  $isCartCount = new EventEmitter();
  

    readonly rootURL;
     public list : WebPhotosCartModel;
    
     public cartCount: number;
     

     public userProductList: CartProductsModel;

    constructor(private http:HttpClient, private _constant: ConstantsService) {
      this.rootURL=_constant.ConstatntWebAPI;
      this.cartCount=0;
      
      this.list=new  WebPhotosCartModel();
      this.userProductList=new CartProductsModel();
      
     }
  
    postAddCart(patnerUserID,productId,quantity) {
      //this.CheckCartCount(patnerUserID);
      
      return this.http.post(this.rootURL+'/cart/'+patnerUserID+'/'+productId+'/'+quantity,null).toPromise();
      
    }

    postChangeQuantity(patnerUserID,productId,quantity,CartItemId) {
      //this.CheckCartCount(patnerUserID);
      
      return this.http.post(this.rootURL+'/cart/ChangeQuantity/'+patnerUserID+'/'+productId+'/'+quantity+'/'+CartItemId,null).toPromise();;
    }
 
    postChangeQuantityWithNewImg(patnerUserID,productId,quantity,CartItemId) {
      //this.CheckCartCount(patnerUserID);
      
      return this.http.post(this.rootURL+'/cart/ChangeQuantityWithNewImg/'+patnerUserID+'/'+productId+'/'+quantity+'/'+CartItemId,null).toPromise();;
    }

    CheckCartCount(patnerUserID)
    { 
      this.http.get(this.rootURL+'/cart/'+patnerUserID).toPromise()
      .then(res => this.cartCount = res as number);            
      this.$isCartCount.emit(this.cartCount); 
    }


     getPartnerPhotosforSelection(patnerUserID,CartItemId,productId) {
       
      return this.http.get(this.rootURL+'/cart/getCartPhotos/'+patnerUserID+'/'+CartItemId+'/'+productId).toPromise()
      .then(res => this.list = res as WebPhotosCartModel);;
     }
     
     getPartnerCartProducts(patnerUserID,productId) {
      
     return this.http.get(this.rootURL+'/cart/getCartProducts/'+patnerUserID+'/'+productId).toPromise();
     
    }

    getUserCartProducts(patnerUserID)
    {
      return this.http.get(this.rootURL+'/cart/getCartList/'+patnerUserID).toPromise()
      .then(res => this.userProductList = res as CartProductsModel);;
    }

    putWebPhotos(CartItemId,WebPhotoIds,productId,patnerUserID)
    {
      
      return this.http.put(this.rootURL+'/cart/putCartItem/'+patnerUserID+'/'+CartItemId+'/'+productId+'/'+WebPhotoIds,null);
    }

    putPackage(patnerUserID)
    {      
      return this.http.put(this.rootURL+'/cart/putCartPackageItem/'+patnerUserID,null);
    }

    deleteCartItem(CartItemId)
    {
      return this.http.delete(this.rootURL+'/cart/'+ CartItemId)
    }
	ClearCart(patnerUserID) {
    return this.http.delete(this.rootURL + '/cart/ClearCart/' 
    + patnerUserID)
  }
  }
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { OrderModel } from 'src/app/services/order/order.model';
import { Router } from '@angular/router';
import { ConstantsService } from '../../common/constants.service';

@Component({
  selector: 'app-orderhistory',
  templateUrl: './orderhistory.component.html',
  styleUrls: ['./orderhistory.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrderhistoryComponent implements OnInit {

  constructor(public service: OrderService, protected _router: Router
    , private _constant: ConstantsService) {
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
    }

  }

  ngOnInit() {
     ///Session expired///
     this._constant.setTimeout();
     this._constant.userInactive.subscribe(() => {
       this._router.navigateByUrl('/welcome');
     }); 
     /// End of Session expired///
    this._constant.loading(true);

    if ((sessionStorage.getItem('PartnerUserId') != null)) {
      
      this.service.getOrderHistoryDetails(sessionStorage.getItem('PartnerUserId')).then
        (
          (res) => {
            this._constant.loading(false);
          },
          (err) => {
            this._constant.loading(false);
          }
        );

    }
    else {
      this._router.navigateByUrl('/welcome');
    }


  }

  orderData(ordrs: OrderModel) {
    if ((sessionStorage.getItem('PartnerUserId') != null)) {
      
    this._router.navigateByUrl('/receipts/'+sessionStorage.getItem('PartnerUserId') +'/'+ordrs.OrderId);
    this.service.sendOrderData(ordrs);
    }
    else {
      this._router.navigateByUrl('/welcome');
    }

  }

}

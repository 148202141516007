import { PartnerUser } from './partner-user.model';
import { HttpClient} from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';


@Injectable({
    providedIn: 'root'
  })

export class PartnerUserService {
    formData: PartnerUser;
    chkemail:any;
    readonly rootURL;
    list : PartnerUser[];

    constructor(private http:HttpClient, private _constant: ConstantsService) {
      this.rootURL=_constant.ConstatntWebAPI;
     }
  
    postPartnerUser() {
      console.log("d")
     return this.http.post(this.rootURL+'/User',this.formData).toPromise()
     .then (res => this.formData = res as PartnerUser);
     //alert(this.formData)
    }

    CheckUser(UserName,Password)
    {
      console.log("d")
      return this.http.get(this.rootURL+'/User/'+UserName+'/'+Password).toPromise()
      .then(res => this.formData = res as PartnerUser);
    }
  
    putPartnerUser(PartnerUserId,formdata) {
      return this.http.put(this.rootURL + '/User/'+ PartnerUserId, formdata)
     }

    getPartnerUser(PartnerUserId) {
    return this.http.get(this.rootURL + '/User/' + PartnerUserId).toPromise()
     .then(res => this.formData = res as PartnerUser);;  
    }
    chkemailUser(Email) {
      console.log("d")
      return this.http.get(this.rootURL + '/User/' + 'chk/' + Email).toPromise()
      .then(res => this.formData = res as PartnerUser);  
      }
     
    deletePartnerUser(id) {
      return this.http.delete(this.rootURL+'/User/'+ id)
     }
  
     
    refreshList(){
      this.http.get(this.rootURL+'/User')
      .toPromise()
      .then(res => this.list = res as PartnerUser[]);
    }

    SetPassword(EmailId:string)
    {
      console.log(EmailId);
      return this.http.put(this.rootURL + '/User/Reset/'+ EmailId,this.formData).toPromise()
    }
    
  }
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CheckoutService } from '../services/Checkout/Checkout.service';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ShippingAddress } from '../services/Payment/shippingaddress.model';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from '../common/constants.service';
import { State } from '../services/Payment/Shipping.model';
import { debug } from 'util';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CheckoutComponent implements OnInit {

  isValidCountry: boolean;
  isDisbaleState: boolean;
  isValidState: boolean;
  isValidCountryBill: boolean;
  isValidStateBill: boolean;
  selectedCountry: string;
  selectedState: string;
  selectedCountryBill: string;
  selectedStateBill: string;
  tempstateList: State[] = [];

  tempStyle1: string;
  tempStyle2: string;

  onlyBilling: boolean;

  constructor(public service: CheckoutService, private _router: Router,
    private toastr: ToastrService, private _constant: ConstantsService) {
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
    }
  }



  ngOnInit() {


    ///Session expired///
    this._constant.setTimeout();
    this._constant.userInactive.subscribe(() => {
      this._router.navigateByUrl('/welcome');
    });
    /// End of Session expired///
    this._constant.loading(true);
    this.isValidCountry = true;
    this.isValidState = true;
    this.isValidCountryBill = true;
    this.isValidStateBill = true;
    this.selectedCountry = '';
    this.selectedState = '';
    this.selectedCountryBill = '';

    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
      this._constant.loading(false);
    }
    else {

      this.service.getData(sessionStorage.getItem('PartnerUserId')).then
        (
        (res) => {
          this.selectedCountry = res.shipping.ShippingCountry;
          this.selectedState = res.shipping.ShippingState;
          this.selectedCountryBill = res.billing.BillingCountry;
          this.selectedStateBill = res.billing.BillingState;

          this.tempstateList = res.stateList;

          if (res.onlyBiling) {
            document.getElementById('onlyBilling1').style.display = "";
            //this.onlyBilling=true;
          }
          else {
            document.getElementById('onlyBilling2').style.display = "";
            //this.onlyBilling=false;
          }

          if (res.billing.UseBillingAsShipping) {

            const el = document.getElementById('billingClassID').getElementsByClassName('form-control');
            for (let i = 0; i < el.length; i++) {
              (<HTMLInputElement>el[i]).disabled = true; // note the type assertion on the element
            }
            this.isDisbaleState = true;
          }
          else {
            const el = document.getElementById('billingClassID').getElementsByClassName('form-control');
            for (let i = 0; i < el.length; i++) {
              (<HTMLInputElement>el[i]).disabled = false; // note the type assertion on the element
            }
            this.isDisbaleState = false;
          }
          if (res.onlyBiling) {

            this.onlyBilling = true;
          }
          else {

            this.onlyBilling = false;
          }

          this._constant.loading(false);

        },
        (err) => {
          this._constant.loading(false);
        }
        );

    }
    this.service.formData.billing.UseBillingAsShipping = true;
  }

  changeCountryBill(country) {

    this.service.formData.stateList = this.tempstateList;
    this.selectedCountryBill = country;

    this.service.formData.billing.BillingState = "Select State";
    this.selectedStateBill = "Select State";
   //this.service.formData.billing.CountryPhoneCode="Select Country Code";
   //this.service.formData.billing.BillingPhone='';

    if (country == 'Select Country') {
      this.isValidCountryBill = false;
    }
    else {
      this.isValidCountryBill = true;
    }

    if (country == "Canada") {

      this.service.formData.stateList = this.service.formData.stateList.filter(x => x.CountryCode == 'CA');
    }
    else if (country == "United States") {
      this.service.formData.stateList = this.service.formData.stateList.filter(x => x.CountryCode == 'US');
    }

  }

  changeCountry(country) {
    this.service.formData.stateList = this.tempstateList;
    this.selectedCountry = country;

    this.service.formData.shipping.ShippingState = "Select State";
    this.selectedState = "Select State";
    //this.service.formData.shipping.CountryPhoneCode="Select Country Code";
    //this.service.formData.shipping.ShippingPhone='';

    if (country == 'Select Country') {
      this.isValidCountry = false;
    }
    else {
      this.isValidCountry = true;
    }

    if (country == "Canada") {
      this.service.formData.stateList = this.service.formData.stateList.filter(x => x.CountryCode == 'CA');
    }
    else if (country == "United States") {
      this.service.formData.stateList = this.service.formData.stateList.filter(x => x.CountryCode == 'US');
    }


  }

  changeState(state) {

    this.selectedState = state;
    if (state == 'Select State') {
      this.isValidState = false;
    }
    else {
      this.isValidState = true;
    }

  }

  changeStateBill(state) {

    this.selectedStateBill = state;
    if (state == 'Select State') {
      this.isValidStateBill = false;
    }
    else {
      this.isValidStateBill = true;
    }

  }

  isSame(sp: ShippingAddress) {

    this.selectedCountryBill = sp.ShippingCountry;
    this.selectedStateBill = sp.ShippingState;

    if (sp.ShippingCountry == 'Select Country') {
      this.isValidCountry = false;

      this.isValidCountryBill = false;
    }
    else {
      this.isValidCountry = true;

      this.isValidCountryBill = true;
    }
    if (sp.ShippingState == 'Select State') {

      this.isValidState = false;
      this.isValidStateBill = false;
    }
    else {
      this.isValidState = true;
      this.isValidStateBill = true;
    }
    if (this.service.formData.billing.UseBillingAsShipping) {

      this.service.formData.billing.BillingFirstName = sp.ShippingFirstName;
      this.service.formData.billing.BillingAddress1 = sp.ShippingAddress1;
      this.service.formData.billing.BillingAddress2 = sp.ShippingAddress2;

      this.service.formData.billing.BillingCountry = sp.ShippingCountry;
      this.service.formData.billing.BillingCity = sp.ShippingCity;
      this.service.formData.billing.BillingState = sp.ShippingState;
      this.service.formData.billing.BillingZip = sp.ShippingZip;

      this.service.formData.billing.CountryPhoneCode = sp.CountryPhoneCode;
      this.service.formData.billing.BillingPhone = sp.ShippingPhone;
      this.service.formData.billing.BillingEmail = sp.ShippingEmail;

      const el = document.getElementById('billingClassID').getElementsByClassName('form-control');
      for (let i = 0; i < el.length; i++) {
        (<HTMLInputElement>el[i]).disabled = true; // note the type assertion on the element
      }
      this.isDisbaleState = true;
    }
    else {
      this.service.formData.billing.BillingFirstName = '';
      this.service.formData.billing.BillingAddress1 = '';
      this.service.formData.billing.BillingAddress2 = '';
      this.service.formData.billing.BillingCountry = 'Select Country';
      this.service.formData.billing.BillingState = 'Select State';
      this.service.formData.billing.CountryPhoneCode = 'Select Country Code';

      this.service.formData.billing.BillingCity = '';
      this.service.formData.billing.BillingZip = '';

      this.service.formData.billing.BillingPhone = '';
      this.service.formData.billing.BillingEmail = '';
      const el = document.getElementById('billingClassID').getElementsByClassName('form-control');
      for (let i = 0; i < el.length; i++) {
        (<HTMLInputElement>el[i]).disabled = false; // note the type assertion on the element
      }
      this.isDisbaleState = false;
    }

  }
  onSubmit(form: NgForm) {
    ;
    this._constant.loading(true);
    this.service.formData.billing.PartnerUserId = parseInt(sessionStorage.getItem('PartnerUserId'));
    this.service.formData.shipping.PartnerUserId = parseInt(sessionStorage.getItem('PartnerUserId'));

    //to buypass
    if (this.onlyBilling) {
      this.selectedCountry = "Dummy";
      this.selectedState = "Dummy";
    }
    else {
      if (this.service.formData.shipping.CountryPhoneCode == '' || this.service.formData.shipping.CountryPhoneCode == 'Select Country Code') {
        this.toastr.error('Select valid shipping Phone Country Code');
        this._constant.loading(false);
        return;
      }
    }

    //phone
    if (this.service.formData.billing.CountryPhoneCode == '' || this.service.formData.billing.CountryPhoneCode == 'Select Country Code') {
      this.toastr.error('Select valid Billing Phone Country Code');
      this._constant.loading(false);
      return;
    }


    if (this.selectedCountry == '' || this.selectedCountry == 'Select Country') {
      this.isValidCountry = false;

      this.toastr.error('Select valid shipping Country');
      this._constant.loading(false);
      return;
    }
    else if (this.selectedCountry == 'Canada' || this.selectedCountry == 'United States') {
      if (this.selectedState == '' || this.selectedState == 'Select State') {
        this.isValidState = false;
        this.toastr.error('Select valid shipping State');
        this._constant.loading(false);
        return;
      }
    }
    else if (this.selectedCountryBill == 'Canada' || this.selectedCountryBill == 'United States') {
      if (this.selectedStateBill == null || this.selectedStateBill == '' || this.selectedStateBill == 'Select State') {
        this.isValidStateBill = false;
        this.toastr.error('Select valid Billing State');
        this._constant.loading(false);
        return;
      }
    }
    else {
      this.isValidCountry = true;
      this.isValidState = true;
    }
    if (this.selectedCountryBill == '' || this.selectedCountryBill == 'Select Country') {
      this.isValidCountryBill = false;
      this.toastr.error('Select valid billing Country');
      this._constant.loading(false);
      return;
    }
    else {
      this.isValidCountryBill = true;
    }

    if (this.service.formData.billing.UseBillingAsShipping) {
      var validData = this.validateBoth();

      if (validData) {
        this.insertRecord(form);
      }
    }
    else {
      this.insertRecord(form);
    }



  }

  validateBoth() {

    if ((this.service.formData.billing.BillingFirstName != this.service.formData.shipping.ShippingFirstName)
      || (this.service.formData.billing.BillingAddress1 != this.service.formData.shipping.ShippingAddress1)
      || (this.service.formData.billing.BillingAddress2 != this.service.formData.shipping.ShippingAddress2)
      || (this.service.formData.billing.BillingCountry != this.service.formData.shipping.ShippingCountry)
      //|| (this.service.formData.billing.BillingState!=this.service.formData.shipping.ShippingState)
      || (this.service.formData.billing.BillingCity != this.service.formData.shipping.ShippingCity)
      || (this.service.formData.billing.BillingZip != this.service.formData.shipping.ShippingZip)
      || (this.service.formData.billing.BillingPhone != this.service.formData.shipping.ShippingPhone)
      || (this.service.formData.billing.CountryPhoneCode != this.service.formData.shipping.CountryPhoneCode)
      || (this.service.formData.billing.BillingEmail != this.service.formData.shipping.ShippingEmail)
    ) {
      this.toastr.error('Billing and Shipping Address is not same');
      this._constant.loading(false);
      return false;
    }
    else {
      return true;
    }
  }

  insertRecord(form: NgForm) {

    this.service.postData().subscribe(
      (res) => {
        //this.toastr.success('Submitted successefully');               
        this._router.navigateByUrl('/payment');
        this._constant.loading(false);
        //this.service.refreshList();
      },
      (err: HttpErrorResponse) => {
        if (err.status == 1) {

          this.toastr.error('Error');
          this._constant.loading(false);

        }
        console.log(err);
      }
    )
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // alphanumericOnly(event): boolean {

  //   ;
  //   const charCode = (event.which) ? event.which : event.keyCode;    
  //   //alert(charCode);
  //   if ((charCode < 65 || charCode > 122) && (charCode < 48 || charCode > 57))
  //   { 
  //     return false;
  //   }
  //   if(charCode == 94)
  //   {
  //     return false;
  //   }
  //   return true;

  // }
  alphanumericOnly(event): boolean {
    if (event.charCode != 0) {
      var regex = new RegExp("^[a-zA-Z0-9\\-\\s]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }

  }
  phoneOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    // if(charCode==43)
    // {
    //   return true;
    // }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  lettersOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if ((charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32) {
      return true;
    }
    return false;
  }
}





import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DigiMasterLocation } from '../services/CustomerSupport/DigimasterLocation.Model';
import { CustomerSupportService } from '../services/CustomerSupport/CustomerSupport.service';
import { NgForm } from '@angular/forms';
import { CustomerSupport } from '../services/CustomerSupport/CustomerSupport.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from '../common/constants.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerSupportComponent implements OnInit {
  formData:DigiMasterLocation;
  formDataCS:CustomerSupport;  
  VenueList : Promise<DigiMasterLocation[]>;
  SiteList: Promise<DigiMasterLocation[]>;
  isSelected:boolean;

    constructor(public service:CustomerSupportService, 
      private _constant: ConstantsService,
      private toastr: ToastrService,protected _router: Router) { 
      this.getVenueList();
    }

    ngOnInit() {
      this.resetForm();
      this.isSelected=false;
      this.service.formDataCS.TypeOfProblem="I can't claim my Photo ID";
    }

    @ViewChild('labelImport', { static: true })
    labelImport: ElementRef;

    //formImport: FormGroup;
    fileToUpload: File = null;    

    //constructor() {
    //  this.formImport = new FormGroup({
    //    importFile: new FormControl('', Validators.required)
    //  });
    //}

    onFileChange(files: FileList) {
      this.labelImport.nativeElement.innerText = Array.from(files)
        .map(f => f.name)
        .join(', ');       
      this.fileToUpload = files.item(0);
      this.service.postFile(this.fileToUpload)
    }

    import(): void {
      console.log('import ' + this.fileToUpload.name);
    }

    lettersOnly(event): boolean{
      const charCode = (event.which) ? event.which : event.keyCode;
      
      if ((charCode > 64 && charCode < 91) || 
      (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32) {      
        return true;
      }
      return false;
    }      

    resetForm(form?:NgForm)
    {
      if (form != null)
        form.resetForm();
      this.service.formData={
        DigiMasterLocationId:0,
        Name:'',
        Code:''
      }      
      this.service.formDataCS={
        Name:'',
        Email:'',
        TypeOfProblem:'',
        PhotoId:'',
        Venue:'',
        CaptureDate:'',
        CaptureTime:'',
        CaptureLocaton:'',
        CharactersInPhoto:'',
        NoOfPeople:'',
        QRCode:'',
        OrderNo:'',
        MissingPhoto:'',
        MoreDetails:'',
        TicketId:'',
      }    
            
      document.getElementById("lableImportFile").innerHTML="";
       
    } 

    getVenueList()
    {
     this.VenueList=this.service.getVenueList();
    }
    onVenueChange(value:number)
    {
      this.SiteList=this.service.getSiteList(value);
    }
    onSubmit(form:NgForm)
    {      
      
      if(this.validateEmail(this.service.formDataCS.Email)==false)
      {
        this.toastr.warning('Invalid Email Id!','',{
        positionClass:'toast-top-center'
        }); 
        this._constant.loading(false);
      }
      else
      {
        this._constant.loading(true);
        if(this.fileToUpload!=null)
        this.service.formDataCS.MissingPhoto=this.fileToUpload.name;

        this.service.PostCustomerSupport(this.service.formDataCS).then(
          (res) => {
            document.getElementById('CS').style.display="none"
            document.getElementById('TicketDetails').style.display="block"
            this._constant.loading(false);
            console.log(this.service.formDataCS.TicketId);
          },
          (err: HttpErrorResponse) => {    
            this._constant.loading(false);        
            console.log(err);          
          }
        )    
      } 

      //this.service.postFile(this.fileToUpload)
    }

    validateEmail(email) {
      var re = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
      return re.test(email);
  }
  Reset()
  {
    this.resetForm();
  }
  Cancel()
  {
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
    }
    else {      
      this._constant.CheckUserImages(sessionStorage.getItem('PartnerUserId'));
    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from '../services/Payment/Payment.service';
import { NgForm, Form } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { FedExRateServiceResponse } from '../services/Payment/fedex.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { PaymentDetails } from '../services/Payment/PaymentDataModel';
import { ConstantsService } from '../common/constants.service';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {

  public fedexResponse: object;
  public isEnable: boolean;
  public localFedex: Promise<FedExRateServiceResponse>;
  public msg: string;
  isPayment: boolean;
  amount: number;
  merchant_defined_data1: string;
  onlyBilling: boolean;




  constructor(public service: PaymentService, protected _router: Router, private http: HttpClient
    , private _constant: ConstantsService) {
    this.msg = 'Fetching delivery details...'
    this.isEnable = true;
    this.isPayment = false;


    this.service.fedexData = new FedExRateServiceResponse();

  }

  ngOnInit() {
    ///Session expired///
    this._constant.setTimeout();
    this._constant.userInactive.subscribe(() => {
      this._router.navigateByUrl('/welcome');
    });
    /// End of Session expired///
    this._constant.loading(true);
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');

      this._constant.loading(false);
    }

    else {


      this.service.getFedexData(sessionStorage.getItem('PartnerUserId')).then(
        (res) => {
          this.service.getPaymentPageDetails(sessionStorage.getItem('PartnerUserId')).then(
            (res1) => {
              if (res1.onlyBiling) {
                document.getElementById('onlyBilling1').style.display = "";
                //this.onlyBilling=true;
              }
              else {
                document.getElementById('onlyBilling2').style.display = "";
                //this.onlyBilling=false;
              }

              if (res1.onlyBiling) {

                this.onlyBilling = true;
              }
              else {

                this.onlyBilling = false;
              }

              this.service.LoadPayment(sessionStorage.getItem('PartnerUserId')).then(
                (res2) => {
                  this.merchant_defined_data1 = '0';
                  this.msg = "";
                  this._constant.loading(false);
                });
            }
          );

        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this._constant.loading(false);
        }
      )
    }
  }

  ConvertToFloat(val) {
    return parseFloat(val);
  }


  ngOnDestroy() {
    this.msg = '';
    this.isEnable = true;
  }

  payClick(shipPrice) {
    this._constant.loading(true);
    this.service.PaymentRequest(sessionStorage.getItem('PartnerUserId')).subscribe(
      (res) => {


        console.info('in ts file');
        console.info(JSON.stringify(res));
        this.service.payButtonData = new PaymentDetails();
        this.service.payButtonData = res as PaymentDetails;
        this._constant.loading(false);
        this.newPayClick();

      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    )
  }



  newPayClick() {
    var formPay = document.getElementById("Paymentconfirm") as HTMLFormElement;
    console.info(this.merchant_defined_data1);
    formPay.submit();
  }

}

export class ShippingAddress{

  ShippingAddressId: number;
  PartnerUserId: number;
  ShippingFirstName: string;
  ShippingLastName: string;
  ShippingEmail: string;
  ShippingCompany: string;
  ShippingAddress1: string;
  ShippingAddress2: string;
  ShippingCity: string;
  ShippingState: string;
  ShippingZip: string;
  ShippingCountry: string;
  ShippingPhone: string;
  ShippingFax: string
  CountryPhoneCode: string;
}

import { PartnerWebPhotos } from '../ClaimPhotos/partner-webphotos.model';
import { PartnerProductModel } from '../ClaimPhotos/partner-products.model';

export class WebPhotosCartModel {

    isSaveSelection: boolean;
    isCartDisable:boolean;

    selectedWebPhotos:string;

    cartPhotos: PartnerWebPhotos[]=[];    

}


import { HttpClient } from '@angular/common/http'
import { Injectable, EventEmitter } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';

import { BillingAddress } from '../Payment/billingaddress.mode';
import { ShippingAddress } from '../Payment/shippingaddress.model';
import { OrderHistoryModel } from './Order-History.model';
import { OrderModel } from './order.model';
import { OrderSingleModel } from './Order-Single.model';

@Injectable({
  providedIn: 'root'
})



export class OrderService {

  formData: OrderHistoryModel;
  formDataSingle: OrderSingleModel;
  readonly rootURL;
  selectedOrder: OrderModel;

  $orderData = new EventEmitter();

  constructor(private http: HttpClient, private _constant: ConstantsService) {
    this.rootURL = _constant.ConstatntWebAPI;
    this.formData=new OrderHistoryModel();    
    
    this.formData.billing=new BillingAddress();
    this.formData.shipping=new ShippingAddress();

    this.formDataSingle=new OrderSingleModel();
    this.formDataSingle.orderItem=new OrderModel();
    this.formDataSingle.billing=new BillingAddress();
    this.formDataSingle.shipping=new ShippingAddress();
  }

  getOrderHistoryDetails(PartnerUserID) {    
    return this.http.get(this.rootURL + '/Order/' + PartnerUserID).toPromise()
      .then(res => this.formData = res as OrderHistoryModel);
  }


  getSingleOrderDetails(PartnerUserID,OrderId) {    
    return this.http.get(this.rootURL + '/Order/getOrder/' +PartnerUserID+'/'+ OrderId).toPromise();   
  }

  sendOrderData(ordrs:OrderModel)
  {        
    this.$orderData.emit(ordrs);
  }
}

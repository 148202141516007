import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { PartnerUserService } from 'src/app/services/PartnerUser/partner-user.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from 'src/app/common/constants.service';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditComponent implements OnInit {

  constructor(public service: PartnerUserService, 
    protected _router: Router,
    private toastr: ToastrService,
    private _constant: ConstantsService) {
    //this.getLoadPartnerUser(form: NgForm);
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');   
    }
    this.NewPassword='';
    this.NewPasswordConfirm='';
    this.currentDate=new Date().toLocaleString();
  }
  public NewPassword:string;
  public NewPasswordConfirm:string;
  readonly _constantPartnerID: number;

  public isValidPswd:boolean;
  public isValidLenS:boolean;
  public isValidLenF:boolean;
  public dynamicClass:string;
  public currentDate: string;

  ngOnInit() {

    //this._constant.setPageTheme();
    //this._constant.setPageThemeFromPhotos();

     ///Session expired///
     this._constant.setTimeout();
     this._constant.userInactive.subscribe(() => {
       this._router.navigateByUrl('/welcome');
     }); 
     /// End of Session expired///
    this._constant.loading(true);
    this.resetForm();
    this.NewPassword='';
    this.NewPasswordConfirm='';

    this.isValidPswd=true;
    this.isValidLenF=true;
    this.isValidLenS=true;
  
    if ((sessionStorage.getItem('PartnerUserId') == null)) {
      this._router.navigateByUrl('/welcome');
      this._constant.loading(false);
    }
    else {
     

      if ((sessionStorage.getItem('DefaultTheme') == null)) {
     
        this.dynamicClass="nav-link disabled"; 
      }
      else  if ((sessionStorage.getItem('DefaultTheme') == 'aquarium')) {
        
        this.dynamicClass="nav-link";
      }
      else  if ((sessionStorage.getItem('DefaultTheme') == 'kidzania')) {
        
        this.dynamicClass="nav-link";
      }
      else  if ((sessionStorage.getItem('DefaultTheme') == 'icerink')) {
        
        this.dynamicClass="nav-link";
      }
      else
      {      
        this.dynamicClass="nav-link disabled";    
      }

      this.getLoadPartnerUser();
    }

    

  }

  focusOutFunction() {
    if (this.NewPassword == this.NewPasswordConfirm) {
      
      this.isValidPswd = true;
    }
    else {
      
      this.isValidPswd = false;
    }
    if (this.NewPassword.length >= 8) {
      
      this.isValidLenF = true;
    }
    else {
      
      this.isValidLenF = false;
    }
    if (this.NewPasswordConfirm.length >= 8) {
      
      this.isValidLenS = true;
    }
    else {
      
      this.isValidLenS = false;
    }
  }

  lettersOnly(event): boolean{
    const charCode = (event.which) ? event.which : event.keyCode;
    
    if ((charCode > 64 && charCode < 91) || 
    (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32) {      
      return true;
    }
    return false;
  }
  
  resetForm(form?:NgForm)
  {
    if (form != null)
        form.resetForm();
      this.service.formData={
      PartnerUserId: 0,
    PartnerId : this._constantPartnerID,
    UserName : '', 
    Email : '', 
    FirstName : '', 
    LastName :'', 
    Password : '', 
    PasswordSalt : '', 
    UserType : 0,  
    Company : '', 
    Address1 :'', 
    Address2 :'', 
    City : '', 
    State : '', 
    Zip :'', 
    Country :'', 
    Phone : '', 
    Fax : '', 
    UseBillingAsShipping :false,
    Gender : '', 
    Nationality : '', 
    FacebookId : '', 
    Birthday :'', 
    GooglePlusId : '', 
    NewsletterSubscription :false
    }
    
  }

  getLoadPartnerUser() {
    this.service.getPartnerUser(sessionStorage.getItem('PartnerUserId')).then
    (
      (res) => {
        this._constant.loading(false);
      },
      (err) => {
        this._constant.loading(false);
      }
    );
    
  }

  onSave(form: NgForm) {
    this._constant.loading(true);
      // this.service.formData = {

      //   PartnerUserId: parseInt(sessionStorage.getItem('PartnerUserId')),
      //   PartnerId: this._constantPartnerID,
      //   UserName: this.service.formData.UserName,
      //   Email: this.service.formData.Email,
      //   FirstName: this.service.formData.FirstName,
      //   LastName: '',
      //   Password: this.NewPassword,
      //   PasswordSalt: '',
      //   UserType: 0,        
      //   Company: '',
      //   Address1: '',
      //   Address2: '',
      //   City: '',
      //   State: '',
      //   Zip: '',
      //   Country: '',
      //   Phone: '',
      //   Fax: '',
      //   UseBillingAsShipping: false,
      //   Gender: '',
      //   Nationality: this.service.formData.Nationality,
      //   FacebookId: '',
      //   Birthday: this.service.formData.Birthday,
      //   GooglePlusId: '',
      //   NewsletterSubscription: this.service.formData.NewsletterSubscription
      // }

      
      this.service.formData.PartnerUserId= parseInt(sessionStorage.getItem('PartnerUserId')),
      this.service.formData.PartnerId= this._constantPartnerID,
      this.service.formData.Password=this.NewPassword,
      this.getPartnerUserData(form);
    
    
  }

  getPartnerUserData(form: NgForm) {

    this.service.putPartnerUser(sessionStorage.getItem('PartnerUserId'), this.service.formData).subscribe(
      (res) => {
        
        // this.resetForm(form);  
        sessionStorage.setItem("FirstName",this.service.formData.FirstName);                   
        this.toastr.success('Profile updated successefully!');  
        //this.service.getPartnerUser(sessionStorage.getItem('PartnerUserId'));
        window.location.reload();
        this._constant.loading(false);
      },
      (err: HttpErrorResponse) => {     
        this.toastr.error('error');   
        this._constant.loading(false);      
        console.log(err);
      }
    )
  }

  OnCancel()
  {
    this._constant.CheckUserImages(sessionStorage.getItem('PartnerUserId'));
  }

  ngOnDestroy() {
    
  }
  
}

import { Country, State } from '../Payment/Shipping.model';
import { ShippingAddress } from '../Payment/shippingaddress.model';
import { BillingAddress } from '../Payment/billingaddress.mode';

export class CheckoutModel {  
        
    shipping :ShippingAddress;
    billing :BillingAddress;
    countryList: Country[]=[];    
    stateList: State[]=[];  
    countryPhoneList: string[]=[];
    isBtnDisable:boolean;
    onlyBiling: boolean;
}

